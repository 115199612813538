define('gizmoGrid',['jquery','lodash','moment', 'handlebars','js.cookie'], function ($,_, moment, Handlebars, Cookies){

    $.gizmoGrid = function (el, options){
        // Global Private Variables
        var windowHeight, windowWidth, base = this;
        base.layout = [];
        base.elements = [];
        base.base_url = window.__env.apiUrl;
        base.preventDefaultContext = true;
        base.logLevel = 0;
        base.$el = $(el);
        base.userAccessLevel = 1;
        base.sso_id = "";
        base.headerRow = {};
        base.headerRow.height = 30;
            base.data = [];
        base.data.newRow = [];
        base.data.settings = {};
        base.data.dataArr = [];
        base.data.safeCopyArr = [];
        base.data.displayArr = [];
        base.data.headerArray = [];
        base.data.headerArray = [];
        base.data.headerArrayDisplay = [];

        base.data.fixedHeaderArrayDisplay = [];
        base.data.fixedHeaderSafeCopyArr = [];
        base.vDragg = {};
        base.vDragg.draggerPositionY = 0;
        base.vDragg.scrollPositionY = 0;
        base.vDragg.startIndex = 0;
        base.vDragg.endIndex = 0;
        base.hDragg = {};
        base.hDragg.draggerPositionX = 0;
        base.hDragg.scrollPositionX = 0;
        base.hDragg.startIndex = 0;
        base.hDragg.endIndex = 0;
        base.hDragg.maxStartIndex = 0;
        base.horizontalWidthArray = [];
        base.actionTemplates = [];
        base.data.pivotGroup = {};
        base.data.isCollapsed = false;
        base.pivotChecked = "disabled";
        base.deleteCount = 0;

        if(window.is_export){
            angular.element('.tools-export').addClass('gg-export-to-excel');
            angular.element('.tools-export').append('<i class="fa fa-spinner fa-spin"></i>');
        }

        base.dateFormats = [
            {"name":"mm/dd/yyyy",value:"MM/DD/YYYY"},
            {"name":"Month Day, Year",value:"LL"}
        ]


        base.columnFormats = [
            {"display_name":"String", "data_type":"string","format":"","edit_type":"text","validation":"","col_class":""},
            {"display_name":"Number with Commas", "data_type":"number","format":"numberWithCommas","edit_type":"text","validation":"numeric","col_class":"right-align"},
            {"display_name":"Float with Commas ", "data_type":"number","format":"floatWithCommas","edit_type":"text","validation":"numeric","col_class":"right-align"},
            {"display_name":"Date", "data_type":"date","format":"","edit_type":"text","validation":"date","col_class":""}
        ];


        base.validationMessages = {
            numeric:"<b>Error:</b> Numeric values only",
            date:"<b>Error:</b> Please add a valid date in the following format: <b>" + base.dateFormats[0].name + "</b>",
            uppercase:"<b>Error:</b> Uppercase only",
            lowercase:"<b>Error:</b> Lowercase only",
        }


        base.tableState = {
            sort: {},
            search: {},
            colSearch: [],
            pagination: {
                start: 0
            }
        };

        base.selected = {
            color: "green",
            select_type:null,
            row_id:null,
            col_id:null,
            obj_id:null,
            data_type:null,
            edit_type:null,
            validation:null,
            action:null,
            format:null,
            value:null,
            oldValue:null,
            rowData:null,
            editable:"n",
            element:null
        };

        base.batchSelect = [];
        base.selectedGroup = [];
        base.batchActions = [];
        base.batchSelectMode = false;
        base.removeBatchSelection = false;

        base.tools = {
            color:null,
            align:null,
            fullScreen:null,
            format:null
        }

        base.cellData = {

        };


        base.tags = {

        }

        function paintColor(_color){
            if(_color != 'none') {
                var color = 'text-color-' + _color;
                var previousColor = 'text-color-' + base.tools.color;
                base.tools.color = _color;
                base.elements.paintBrush.removeClass(previousColor);
                base.elements.paintBrush.addClass(color);
            }
        }


////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// BASE INIT FUNCTION /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////


        base.init = function (){

            base.options = $.extend({},$.gizmoGrid.defaultOptions, options);
            base.elements.alertContainer = base.$el.find('.gg-alert-container');
            //////////////////////// ATTACH TEMPLATES ////////////////////////////////////
            base.$el.append(colSizeHandlerTemplate);
            base.$el.append(rowSizeHandlerTemplate);
            base.$el.append(colReorderHandlerTemplate);
            base.$el.append(colIndicatorTemplate);
            base.$el.append(colFixedHandlerTemplate);
            base.$el.append(editTemplate);
            base.$el.append(contextMenuTemplate);
            base.$el.append(messageItemTemplate);
            base.$el.append(errorItemTemplate); // error template
            base.$el.append(modalFilterTemplate); //TO BE DELETED WHEN modalTemplate IS functional
            base.$el.append(modalTemplate);
            base.$el.prepend(loadingTemplate);
            base.$el.prepend(selectFiltersTemplate);
            base.$el.prepend(hiddenTextAreaTemplate);
            base.$el.prepend(refreshIconTemplate);
            base.$el.prepend(largeTextDisplay);



            base.elements.tools = base.$el.find('.gg-tools');
            base.elements.dndDraggerHeader = base.$el.find('.gg-dragger-bg');
            base.elements.header = base.$el.find('.gg-header');
            base.elements.headerLeft = base.$el.find('.gg-header-left-col');
            base.elements.headerRight = base.$el.find('.gg-header-right-col');
            base.elements.footer = base.$el.find('.gg-footer');
            base.elements.hScroll = base.$el.find('.gg-hScroll');
            base.elements.body = base.$el.find('.gg-body');
            base.elements.ggleftcol = base.$el.find('.gg-left-col');
            base.elements.ggrightcol = base.$el.find('.gg-right-col');
            base.elements.vScroll = base.$el.find('.gg-vScroll');
            base.elements.vdragger = base.$el.find('.vScroll-dragger');
            base.elements.vbtns = base.$el.find('.v-scroll-btns');
            base.elements.vtrack = base.$el.find('.vScroll-track');
            base.elements.resizeColLine =  base.$el.find('.resize-col-line');
            base.elements.resizeRowLine =  base.$el.find('.resize-row-line');
            base.elements.resizeColBlock =  base.$el.find('.reorder-col-block');
            base.elements.colLine =  base.$el.find('.col-line');
            base.elements.fixColHandler =  base.$el.find('.fixed-col-handler');
            base.elements.hdragger = base.$el.find('.hScroll-dragger');
            base.elements.hdragger = base.$el.find('.hScroll-dragger');
            base.elements.hbtns = base.$el.find('.h-scroll-btns');
            base.elements.htrack = base.$el.find('.hScroll-track');
            base.elements.editValue = editTemplate;
            // not used  base.elements.formContainer = base.$el.find('.gg-form-container');
            base.elements.footerDelete = base.$el.find('.delete-count');
            base.elements.footerDetails = base.$el.find('.footer-details');
            base.elements.globalSearch = base.$el.find('.gg-global-search');
            base.elements.footerContainer = base.$el.find('.gg-filter-container');
            base.elements.paintBrush = base.$el.find('.fa-paint-brush');
            base.elements.contextMenu = contextMenuTemplate;
            base.elements.messageItemContainer = messageItemTemplate;
            base.elements.errorcontainer = errorItemTemplate; // error template
            base.elements.selection = base.$el.find('.gg-selection');
            base.elements.recordDetails = base.$el.find('#recordDetails');
            base.elements.FilterModalService = modalFilterTemplate;
            base.elements.modalContainer = modalTemplate;
            base.elements.refreshIcon = refreshIconTemplate;
            base.elements.dataLoader = loadingTemplate;
            base.elements.fliterOverlay = selectFiltersTemplate;
            base.elements.hiddenTA = base.$el.find('.gg-hidden-ta-cnt textarea');
            base.elements.dataLoader.css('display','none');
            base.elements.totalText = base.$el.find('.gg-total-text');

            base.elements.lgTxtCnt = base.$el.find('.gg-lg-text-cnt');
            base.elements.lgTxt = base.$el.find('.gg-lg-text');
            base.elements.lgTxtBtns = base.$el.find('.gg-lg-text-btns');
            base.elements.colTemplateSelect = base.$el.find('#gg-template-select');


            base.gridFiltersDivHeight = 32;// added this to fix VScroll on gridStandardScreens, as on initial load gridFiltersDiv is not loading.
            base.loader.setSize();
            base.focus = {
                gridBody:  base.elements.body,
                hiddenTA:  base.elements.hiddenTA,
                tools: base.elements.tools
            };
        }



        base.headerService = {
            templateArray: [],
            templateIndex: 0,
            expanded : false,
            dragArray:[],
            colToolsContainer: $("<div class='gg-col-order-cont' style='right:15%'> "+
            "	<div class='gg-col-header-cont'> "+
            "		<div class='pull-left' > "+
            "			<h3>Reorder / Hide Show Columns / Create Custom Column Templates</h3> "+
            "		</div> "+
            "		<div class='pull-right'> "+
            "			<i style='cursor: pointer; font-size: 18px; transition: transform .4s ease;' class='fa fa-times gg-col-close'></i> "+
            "		</div> "+
            "	</div> "+
            "   <div class='scrol-wrapper'>"+
            "   <div class='panel panel-default' style='margin: 15px;'> "+
            "       <div class='panel-heading'> " +
            "           <span><i class='fa fa-arrows'></i>&nbsp;<b>Ctrl click</b>&nbsp;to drag multiple columns, &nbsp;<i class='fa fa-check-square-o'></i>&nbsp;click to show/hide columns</span> "+
            "			<div style='float: right;'> "+
            "				<div action='showAllCols' class='btn btn-xs gg-col-show-all btn-default'><i class='fa fa-check-square-o'></i>- Show All</div> "+
            "				<div action='hideAllCols' class='btn btn-xs btn-default gg-col-hide-all'><i class='fa fa-square-o'></i>- Hide All</div> "+
            "			</div> "+
            "       </div> "+
            "       <div class='panel-body'> "+
            "	        <div class='gg-col-headers'></div> "+
            "       </div> "+
            "   </div> "+
            "   <div class='panel panel-default' style='margin: 15px;'> "+
            "       <div class='panel-heading'> "+
            "           Header Selection: "+
            "       </div>" +
            "       <div class='panel-body'>" +
            "		    <div> "+
            "			    <div class='gg-col-temp-cont'> <label>Select Column Template:</label>  <br /> "+
            "				    <select name='gg-col-select' class='form-control input-sm gg-col-select' id='colTempSelect'><option value='0'>Select Template</option></select> "+
            "			    </div> "+
            "			    <div class='gg-col-create-cont'> <label>Create New Template:</label> <i class='small-text'>*Save column order, column width and which columns are visible or hidden </i>  <br /> "+
            "				    <input class='form-control input-sm ' placeholder='enter template name' type='text' id='gg-col-temp-input'> "+
            "				    <div class='gg-col-default-temp'  ><input type='checkbox' id='colDefaultSaveTemplate'  > (Default) </div> "+
            "				    <div class='gg-col-default-temp gg-col-system-temp'  ><input type='checkbox' id='colSystemSaveTemplate'  > (system) </div> "+
            "			    </div> "+
            "			    <div  class='gg-col-update-cont'> <label>Update Template:</label> <i class='small-text'>*Adjust the columns above and then hit update to save the changes</i><br /> "+
            "				    <input class='form-control input-sm' placeholder='enter template name' type='text' id='gg-col-update-input'> "+
            "				    <div class='gg-col-default-temp'  ><input type='checkbox' id='colDefaultEditTemplate'  > (default) </div> "+
            "				    <div class='gg-col-default-temp gg-col-system-temp'  ><input type='checkbox' id='colSystemEditTemplate'  > (system) </div> "+
            "			    </div>  "+
            "		    </div> "+
            "       </div>"+
            "       <div class='panel-footer'> "+
            "           <div class='gg-col-temp-cont'> "+
            "			    <div class='btn btn-sm btn-success gg-col-action' action='edit' style='display:none'>Edit Template</div> "+
            "			    <div class='btn btn-sm btn-info gg-col-action' action='create'>Create New</div> "+
            "           </div> "+
            "           <div class='gg-col-create-cont'> "+
            "	            <div class='btn btn-sm btn-success gg-col-action' action='save'>Save</div> "+
            "	            <div class='btn btn-sm btn-warning gg-col-action' action='cancel'>Cancel</div> "+
            "           </div> "+
            "           <div class='gg-col-update-cont'>"+
            "				<div class='btn btn-sm btn-success gg-col-action' action='update'>Update</div> "+
            "				<div class='btn btn-sm btn-danger gg-col-action' action='delete'>Delete</div> "+
            "				<div class='btn btn-sm btn-warning gg-col-action' action='cancel'>Cancel</div> "+
            "           </div>"+
            "       </div>" +
            "   </div>" +
            "   </div>"+
            "   <hr>" +
            "	<div class='gg-col-tools-btns row'> "+
            "		<div class='btn btn-sm btn-warning gg-col-reset'>Reset to Default</div> "+
            "		<div class='btn btn-sm btn-default gg-col-close'>Close</div> "+
            "	</div> "+
            "</div> "),

            dragColToolCont: $("<div class='gg-col-order-drag-cont'><div>"),
            overlayElement: $("<div class='gg-col-order-overlay'><div>"),

            // Used to resize the modal content when left nav-menu Closed/Opened..(added by Omi)
            modalResize: function(data) {
                if(data == "closed") {
                    base.$el.find('.gg-col-order-cont').addClass('adjust-modal');
                } else {
                    base.$el.find('.gg-col-order-cont').removeClass('adjust-modal');
                }
            },

            colToolTemplateControls: function(_type){
                if(_type === 'select'){
                    base.$el.find('.gg-col-temp-cont').show();
                    base.$el.find('.gg-col-update-cont').hide();
                    base.$el.find('.gg-col-create-cont').hide();
                }else if(_type === 'create'){
                    base.$el.find('.gg-col-temp-cont').hide();
                    base.$el.find('.gg-col-update-cont').hide();
                    base.$el.find('.gg-col-create-cont').show();
                    base.$el.find('#colDefaultSaveTemplate').prop( "checked", false );
                }else if(_type === 'cancel'){
                    base.$el.find('.gg-col-temp-cont').show();
                    base.$el.find('.gg-col-update-cont').hide();
                    base.$el.find('.gg-col-create-cont').hide();
                    base.$el.find('#gg-col-temp-input').val("");
                }else if(_type === 'edit'){
                    var checked = this.templateArray[this.templateIndex].default_template === 1 ? true : false;
                    base.$el.find('#colDefaultEditTemplate').prop( "checked", checked );
                    var system = this.templateArray[this.templateIndex].system === 1 ? true : false;
                    base.$el.find('#colSystemEditTemplate').prop( "checked", system );
                    base.$el.find('.gg-col-temp-cont').hide();
                    base.$el.find('.gg-col-update-cont').show();
                    base.$el.find('.gg-col-create-cont').hide();
                    var tempname = base.$el.find('#gg-col-update-input').val( this.templateArray[this.templateIndex].name);
                }else if(_type === 'save'){
                    var tempname = base.$el.find('#gg-col-temp-input').val();
                    this.colToolsSave(tempname);
                }else if(_type === 'delete'){
                    this.colToolDeleteTemplates();
                }else if(_type === 'update'){
                    this.colToolUpdateTemplates();
                }


                if( base.userAccessLevel <= 2) {
                    base.$el.find('.gg-col-system-temp').hide();
                }

            },

            colToolTemplateReset: function(_index){
                this.templateIndex = _index;
                base.$el.find('.gg-col-temp-cont').show();
                base.$el.find('.gg-col-update-cont').hide();
                base.$el.find('.gg-col-create-cont').hide();
                base.$el.find('#gg-col-temp-input').val("");
                this.buildTemplateSelection(_index);
                if(_index === 0 ){
                    this.colToolsReset();
                }
            },

            colToolUpdateTemplates: function(){
                var template = this.templateArray[this.templateIndex];
                var search = _.find( this.templateArray, {name: template.name});
                if( typeof search !== 'undefined' && search != template ){
                    addAlert("alert-warn","<b>Error:</b> You already have a template with this name  ",'5000' );
                    return false;
                }

                var name = sanitize(base.$el.find('#gg-col-update-input').val());


                var templateArr = base.data.headerArraySafe.map(function(header) {
                    return { // return what new object will look like
                        width:  header.width,
                        display: header.display,
                        map:header.map
                    };
                });

                template.name = name;
                template.columns = templateArr;

                /* column template header & fixed columns 6/13/2019  */
                template.fixed_columns = base.data.fixedHeaderArrayDisplay.length;
                template.header_height = base.headerRow.height;
                /* ends here */

                template.default_template =  base.$el.find('#colDefaultEditTemplate').is(':checked') === true ? 1 : 0;
                template.system = ( base.$el.find('#colSystemEditTemplate').is(':checked') &&  base.userAccessLevel >= 3 ) === true ? 1 : 0;


                base.api.callBack( 'update',template,'columnTemplate');
                this.buildTemplateSelection(this.templateIndex);
                this.colToolTemplateReset(this.templateIndex);
            },

            colToolDeleteTemplates: function(){
                var template = this.templateArray[this.templateIndex];
                if(template.system === 1 &&  base.userAccessLevel < 3){
                    addAlert("alert-error"," You can not delete System Templates ",4000 );
                    return false;
                }
                this.templateArray.splice(this.templateIndex,1);
                this.colToolTemplateReset(0);
                base.api.callBack( 'delete',template,'columnTemplate');
            },


            colToolSetTemplates: function(){
                var defaultIndex = _.findIndex( base.headerService.templateArray, { 'default_template': 1 });
                if(defaultIndex > 0 && options.data.firstLoad && options.data.firstLoad === 1){
                    this.buildTemplateSelection(defaultIndex);
                    this.colToolsApply(defaultIndex);
                }else{
                    this.buildTemplateSelection("");

                    //EXPAND COLS ONLOAD
                    if(options.data.settings && options.data.settings.expand_cols && options.data.settings.expand_cols === 1){
                        base.headerService.expanded = false;
                        base.headerService.expandCols();
                    }

                }
            },

            buildTemplateSelection: function(_index){
                $('#colTempSelect').html("<option value=''>select template...</option>");
                if(this.templateArray.length){
                    _.forEach(this.templateArray , function(value, key) {
                        var name = (value.system === 1 ) ?  value.name +" (system)" :  value.name;
                        name = (value.default_template === 1 ) ?  name +" (default)" :  name;
                        var style = (value.default_template === 1 ) ? {style:"background-color:#4af352"} : {style:"background-color:#fff"};
                        base.$el.find('#colTempSelect').append($("<option/>", {
                            value: key,
                            text:  name,
                            attr: style
                        }));

                    });
                    base.$el.find('#colTempSelect').val(_index);
                }

                this.buildTemplateDropdown(this.templateIndex);
            },


            buildTemplateDropdown: function(_index){
                base.elements.colTemplateSelect.html("<option value=''>select template...</option>");
                if(this.templateArray.length){
                    var li = "";
                    _.forEach(this.templateArray , function(value, key) {
                        var name = (value.system === 1 ) ?  value.name +" (system)" :  value.name;
                        name = (value.default_template === 1 ) ?  name +" (default)" :  name;

                        var selected = (  base.headerService.templateIndex == key ) ? 'active' : '';
                        li += '<li action="apply-template" index="'+key+'"  class="tools-action '+selected+'"><a>'+sanitize(name)+'</a> <li>';
                        base.options.fixedColumn = (  base.headerService.templateIndex == key ) && (value && value.fixed_columns > 0)?value.fixed_columns:options.data!=undefined?options.data.settings.fixed_columns:base.options.fixedColumn;//this.selectedTemplate.fixed_columns;
                        base.headerRow.height = (  base.headerService.templateIndex == key) && (value && value.header_height>0) ?value.header_height:options.data!=undefined?parseInt(options.data.settings.header_height): base.options.defaultRowHeight;

                    });
                    li +=' <li role="separator" class="divider"></li><li class="dotted">Use the <i class="fa fa-eye"></i> icon to edit or create column templates</li>';

                    base.elements.colTemplateSelect.html(li);
                }
            },


            colToolsReset: function(){
                this.colToolsApply(0);
            },

            colToolsSave: function (_tempName) {
                let formatCheckerGrid = /[<>]/;
                var checkedFlagGrid = formatCheckerGrid.test(_tempName);
                if(!checkedFlagGrid) {
                    //checkIfNameExits
                    if(_tempName.length <= 3){
                        addAlert("alert-error"," Template name must have at least 3 characters ",4000 );
                        return false;
                    }
                    if( typeof  _.find( this.templateArray, {name: _tempName})  !== 'undefined'  ){
                        addAlert("alert-warn","<b>Error:</b> You already have a template with this name  ",'5000' );
                        return false;
                    }

                    var templateArr = base.data.headerArraySafe.map(function(header) {
                        return { // return what new object will look like
                            width:  header.width,
                            display: header.display,
                            map:header.map
                        };
                    });




                    var defaultTemplate =   base.$el.find('#colDefaultSaveTemplate').is(':checked') === true ? 1 : 0;
                    var system =   ( base.$el.find('#colSystemSaveTemplate').is(':checked') &&  base.userAccessLevel >= 3 ) === true ? 1 : 0;


                    var newTemp = {
                        name:_tempName,
                        template_key:randomString(),
                        system:system,
                        columns:templateArr,
                        default_template:defaultTemplate,
                        height: base.headerRow.height || base.options.vDraggerMinHeight
                    }

                    this.templateArray.push(newTemp);
                    this.templateIndex =  this.templateArray.length - 1;
                    base.api.callBack( 'create',newTemp,'columnTemplate');
                    if(newTemp.system === 1 ){
                        base.$el.find('.gg-col-action[action="edit"]').hide();
                    }else{
                        base.$el.find('.gg-col-action[action="edit"]').show();
                    }
                    this.buildTemplateSelection(this.templateIndex);
                    this.colToolTemplateControls('select');
                } else {
                    addAlert("alert-warn", "Do not use special characters like <, > for Create New Template field", 4000);
                }

            },




            colToolsApply: function(_template){
                    if(_template === ""){
                        return false;
                    }
                    this.templateIndex = _template;
                    var headerArray = base.data.headerArraySafe;
                    var templateArray = this.templateArray[_template].columns;
                  //  base.headerRow.height = this.templateArray[_template].height ;

                   /*  apply fixed heights & columns for column template  6/13/2019 */
                     base.headerRow.height = (this.templateArray[_template].header_height>0  ? this.templateArray[_template].header_height : parseInt(options.data.settings.header_height));
                     base.options.fixedColumn = (this.templateArray[_template].fixed_columns>0)?this.templateArray[_template].fixed_columns:options.data.settings.fixed_columns;//this.selectedTemplate.fixed_columns
                     /* ends Here */

                     _.forEach(templateArray, function (value, key) {
                        var object = _.find(base.data.headerArraySafe, {map: value.map});
                        if (object && object.map.toLowerCase() != 'object_id') {
                            object.width = value.width;
                            object.display = value.display;
                            //Sort Object;
                            var toMove = _.findIndex(base.data.headerArraySafe, object);
                            if (toMove >= 0) {
                                arraymove(base.data.headerArraySafe, toMove, key);
                            }
                        }

                    });



                var system = this.templateArray[_template].system;



                if( _template == 0 ||  ( this.templateArray[_template].system == 1 &&  base.userAccessLevel < 3)){
                    base.$el.find('.gg-col-action[action="edit"]').hide();
                }else{
                    base.$el.find('.gg-col-action[action="edit"]').show();
                }
                base.selected.row_id = null;
                setDefaultSelect();
                buildGrid();
                base.headerService.renderColTools();
                base.headerService.buildTemplateDropdown();
            },



            uncheckAll: function(){
                _.forEach(base.data.headerArraySafe , function(value, key) {
                    if(key != 0 && key != 1){
                        value.display = "n";
                    }else{
                        value.display = "y";
                    }

                });
                base.headerService.renderColTools();
                buildGrid();
            },


            checkAll: function(){
                _.forEach(base.data.headerArraySafe , function(value, key) {
                    value.display = "y";
                });
                base.headerService.renderColTools();
                buildGrid();
            },



            colToolsOpen: function(){
                base.headerService.colToolsContainer.show('fast');
                base.headerService.overlayElement.show('fast');
            },


            colToolsClose: function(){
                //hide();
                base.headerService.overlayElement.hide('fast');
                base.headerService.colToolsContainer.hide('fast');


            },

            containsElement : function(map){
                if( _.includes(this.dragArray, map)  ){
                    return true;
                }else{
                    return false;
                }
            },


            addDragElement: function(_element){
                var map = _element.attr('map');
                if(this.containsElement(map)){
                    return false;
                }
                base.headerService.dragColToolCont.append(_element);
                this.dragArray.push(map);
                var headerCont = base.headerService.colToolsContainer.find('.gg-col-headers');
                var btnCont = base.headerService.colToolsContainer.find('.gg-col-tools-btns');
                var top = 15;
                this.dragColToolCont.show();
                base.headerService.dragColToolCont.css('top', ( top + btnCont.position().top ) + 'px');
                base.headerService.dragColToolCont.css('left', 15 + 'px');
            },

            dragColTool: function(_left,_top){
                var offset = this.colToolsContainer.offset();
                base.headerService.dragColToolCont.css('left', (_left - offset.left + 5)   + 'px');
                base.headerService.dragColToolCont.css('top', (_top - offset.top + 5 )  + 'px');
            },


            dropColTool: function(map,display){
                var that = this;
                var  children = that.dragColToolCont.children();
                var dropArray = [];
                this.dragArray = [];

                var toWhere = _.findIndex(base.data.headerArraySafe, { 'map': map });

                if( map == "" || children.length <= 0 ){
                    that.dragColToolCont.html("");
                    base.headerService.renderColTools();
                    ////console.log("I WAS CALLED");
                    return false;
                }


                for (var i = 0; i < children.length; i++) {
                    var mapMove = $(children[i]).attr('map');
                    dropArray.push(map);
                    var toMove = _.findIndex(base.data.headerArraySafe, { 'map': mapMove });
                    ////console.log(" toMove : " , toMove);
                    arraymove(base.data.headerArraySafe,toMove, toWhere + i);
                }

                that.renderColTools();
                buildGrid();
            },


            hideShowCol: function(map,display){
                var object = _.find(base.data.headerArraySafe,{map:map});
                object.display = ( display  === 'y') ? 'n' : 'y';
                base.headerService.renderColTools();
                buildGrid();
            },

            renderColTools: function(){

                var that = this;
                that.dragColToolCont.html("");
                var headerCont = base.headerService.colToolsContainer.find('.gg-col-headers');
                headerCont.html("");
                this.dragColToolCont.hide();


                _.forEach(base.data.headerArraySafe, function(value, key){
                    if(key != 0) {
                        var header = $("<div class='gg-col-tools-header' map='"+value.map+"' display='"+value.display+"'><div>")
                        if(value.display === 'y'){
                            header.append('<i class="fa fa-check-square-o gg-check-show-hide "></i>')
                        }else{
                            header.addClass('gg-col-header-fade');
                            header.append('<i class="fa fa-square-o gg-check-show-hide "></i>')
                        }
                        header.append(value.label);
                        headerCont.append(header);
                    }
                });
                base.$el.append( that.colToolsContainer);
                base.$el.append( that.overlayElement);
                that.colToolsContainer.append(that.dragColToolCont);
                if(options.data.fromModal && options.data.fromModal == true) { //alignments adjustments for grid on modal..
                    that.colToolsContainer.css('top', 15 + 'px');
                    that.colToolsContainer.css('left', 15 + 'px');
                } else {
                    that.colToolsContainer.css('top',(base.elements.body.offset().top - base.elements.header.height()) + 'px');
                    that.colToolsContainer.css('left', 260 + 'px');
                }
            },



            //START HERE
            formatCol: function(_colObj,_type,_name ){
                if( _name && _name.length < 1 ){
                    addAlert("alert-error"," Please add a column name   ",4000 );
                    return false;
                }
                _colObj.data_type = base.columnFormats[_type].data_type;
                _colObj.edit_type = base.columnFormats[_type].edit_type;
                _colObj.format = base.columnFormats[_type].format;
                _colObj.validation = base.columnFormats[_type].validation;
                _colObj.label = sanitize(_name);
                buildGrid();
            },

            buildFormatSelection: function(){
                var colFormatTemplate  = '<lable>Column Type</lable><select name="select" class="input-sm form-control gg-modal-select">';
                for( var i = 0; i <  base.columnFormats.length ; i++){
                    colFormatTemplate += "<option value='"+i+"'>"+base.columnFormats[i].display_name+"</option>";
                }
                colFormatTemplate += "</select>"
                return colFormatTemplate;
            },




            removeCol: function(_index,colObj){
                //console.log("_index: " , _index)
                //console.log("colObj: " , colObj)
                //TODO:remove data from array also. and set selected cell to cell not in column being removed. That throws an error.
                base.data.headerArraySafe.splice(_index, 1);
                buildGrid();
            },

            addCol: function(_label,_data_type,_index){
                var map = "add_col_" + base.edit.createUniqueColId(3);
                var newCol = {
                    "label": _label,
                    "full-label": _label,
                    "removable":true,
                    "object_id":map,
                    "map": map,
                    "width": 100,
                    "header_class": "",
                    "col_class": "",
                    "display": "y",
                    "data_type": "string",
                    "edit_type": "text",
                    "format": "",
                    "validation": "",
                    "editable": "y",
                    "call_back": ""
                };

                base.data.headerArraySafe.splice(_index, 0, newCol);
                buildGrid();
                dropdownAction('formatCol','','', newCol, newCol.map);



            },


            fill: function(_fill_value,curDataCol){
                _.each(base.data.dataArr, function(item) {
                    item[base.selected.colData.map] = _fill_value;
                });
                buildGrid();
            },



            expandCols: function() {
                this.expanded = !this.expanded;

                if(this.expanded ){
                    base.data.headerArray.forEach(function(value) {
                        var buffer = 8;
                        var characterWidth = 8;
                        var characterWidth = 8;

                        if(value.label != null ){
                            var label = value.label.replace(/<(?:.|\n)*?>/gm, '');
                            var labelLength = (label.length * characterWidth) + buffer ;
                            if( labelLength >  value.width ){
                                value.width = labelLength;
                            }
                        }


                    });
                }else{
                    this.colToolsReset();
                }

                buildGrid();
            }



        }


        base.largeText = {
            showComponent:false,
            editBtn:'<div class="btn btn-info btn-xs gg-lg-text-edit" >Edit</div>',
            saveBtn:'<div class="btn btn-success btn-xs gg-lg-text-save" >Save</div>',
            cancelBtn:'<div class="btn btn-warning btn-xs gg-lg-text-cancel" >Cancel</div>',

            show:  function() {
                this.view();
                this.position();
            },


            view: function(){
                base.elements.lgTxt.css('overflow-y','auto');
                base.elements.lgTxtBtns.html(base.largeText.editBtn);
                base.elements.lgTxtCnt.css('display','block');
                this.showComponent = true;

                base.elements.lgTxt.html(sanitize(base.selected.value));
            },


            hide:  function() {
                base.elements.lgTxtCnt.css('display','none');
                base.elements.lgTxt.html("");
                this.showComponent = false;
            },

            position: function(){

                if(base.selected.element && base.selected.element.offset()){
                    var positionX = base.selected.element.offset().left  +  base.selected.element.width();
                    var positionY = base.selected.element.offset().top  +  base.selected.element.height();
                    if( (positionX +  base.elements.lgTxtCnt.width()) > windowWidth  ){
                        positionX = (base.selected.element.offset().left - base.elements.lgTxtCnt.width());
                    }
                    if( (positionY +  base.elements.lgTxtCnt.height()) > windowHeight  ) {
                        positionY = ( windowHeight - 10)  -  base.elements.lgTxtCnt.height();
                    }
                    base.elements.lgTxtCnt.css('left', positionX +'px');
                    base.elements.lgTxtCnt.css('top', positionY +'px');
                }

            },

            edit: function(event){
                var value = (typeof base.selected.value === "undefined") ? "" : base.selected.value;
                base.elements.lgTxtBtns.html(base.largeText.saveBtn + " " + base.largeText.cancelBtn);
                base.elements.lgTxt.html('<textarea style="width:'+base.elements.lgTxt.width()+'px; height:'+base.elements.lgTxt.height()+'px"  >'+sanitize(value)+'</textarea>');
                base.elements.lgTxt.css('overflow','hidden');
            },


            save: function(){
                var val = base.elements.lgTxt.children(0).val();
                var callName = base.selected.colData.call_back;
                var data = {
                    colData:base.selected.colData,
                    rowData:base.selected.rowData,
                    newVal:val
                }
                base.selected.value = val;
                base.edit.setValue(val);
                base.largeText.view();
                buildGrid();
                base.focus.gridBody.focus();

            },

            cancel: function(){
                base.elements.lgTxt.html("");
                base.elements.lgTxtBtns.html(base.largeText.editBtn);
                base.largeText.view();
            }




        }


        base.renderSelected = {
            renderType: "",
            renderPosition: {},
            defaultCell:{},

            setDefaultCell: function (defaultCell) {
                base.renderSelected.defaultCell = defaultCell;
            },


            selectedAll: function () {
                base.renderSelected.clearAll();
                base.renderSelected.renderType = "rowGroup";
                base.renderSelected.renderType = "all";
                base.selectedGroup = 'all';
                var offsetWidth = base.$el.find('.div-col-prime').width();
                var selection = $('<div class="gg-selection"><div>');
                selection.height(base.elements.body.height());
                selection.width(base.elements.body.width() - ( base.elements.vtrack.width() + offsetWidth) );
                selection.css('top',base.elements.body.offset().top+'px');
                selection.css('left',(base.elements.body.offset().left + offsetWidth) +'px');
                base.$el.prepend(selection);
                base.focus.gridBody.focus();
            },


            clearSelection: function(){
                $( "div" ).remove( ".gg-selection" );
                $( "div" ).remove( ".gg-cell-selection" );
            },


            clearAll:function(){
                this.renderType = "";
                this.renderPosition = {};
                this.clearSelection();
            },


            selectCellGroup: function (fromCellRow,fromCellCol,toCellRow,toCellCol) {
                base.renderSelected.clearAll();
                base.renderSelected.renderType = "cellGroup";
                var fromRow = _.findIndex(base.data.dataArr, { object_id: fromCellRow})
                var toRow = _.findIndex(base.data.dataArr, { object_id: toCellRow})
                var fromCol = _.findIndex(base.data.headerArray, { map: fromCellCol})
                var toCol = _.findIndex(base.data.headerArray, { map: toCellCol})

                var hDirection = "Right",
                    direction = "down";

                if( Math.sign(toRow - fromRow ) === -1){
                    var newFromRow = toRow
                    var newToRow = fromRow
                    fromRow = newFromRow;
                    toRow = newToRow;
                    direction = "up";
                }

                if( Math.sign(toCol - fromCol ) === -1){
                    var newFromCol = toCol
                    var newToCol = fromCol
                    fromCol = newFromCol;
                    toCol = newToCol;
                    hDirection = "Left";
                }


                base.renderSelected.renderPosition = {y1:fromRow,y2:toRow,x1:fromCol,x2:toCol};
                if( fromCellRow == toCellRow && fromCellCol == toCellCol ){
                    base.renderSelected.renderType = "";
                    base.renderSelected.renderPosition = {}
                }
                base.renderSelected.renderSelected();
            },


            setCellGroup: function(){
                var fromRow = base.renderSelected.renderPosition.y1;
                var toRow =  base.renderSelected.renderPosition.y2;
                var fromCol =  base.renderSelected.renderPosition.x1;
                var toCol =  base.renderSelected.renderPosition.x2;

                if(base.renderSelected.renderType === "cellGroup" ) {
                    base.selectedGroup = [];

                    for (var i = parseInt(fromRow); i <= parseInt(toRow); i++) {
                        for (var p = parseInt(fromCol); p <= parseInt(toCol); p++) {
                            var row_id = base.data.dataArr[i].object_id;
                            var col_id = base.data.headerArray[p].map;
                            setSelected(col_id, row_id, 'cell', true);
                        }

                    }
                }

                base.mathFormulas.selectedSum();
            },


            renderCellGroup : function () {
                base.renderSelected.clearSelection();
                var fromRow = base.renderSelected.renderPosition.y1;
                var toRow =  base.renderSelected.renderPosition.y2;
                var fromCol =  base.renderSelected.renderPosition.x1;
                var toCol =  base.renderSelected.renderPosition.x2;

                if( ! _.inRange(fromRow, base.vDragg.startIndex, base.vDragg.endIndex) ){
                    fromRow = base.vDragg.startIndex;
                    if(  fromRow  > toRow || ! _.inRange(toRow , base.vDragg.startIndex, base.vDragg.endIndex)  ){
                        return false;
                    }
                }

                if( ! _.inRange(toRow , base.vDragg.startIndex, base.vDragg.endIndex) ){
                    toRow = base.vDragg.endIndex - 1;
                    if(  fromRow  > toRow  ){
                        return false;
                    }
                }


                //TODO: MAKE SELECTION STAY WHEN OUT OF ONE BOUND ELEMENT LIKE WE DO WITH ROW ^



                if( typeof base.data.dataArr[fromRow] !== undefined  && typeof base.data.dataArr[toRow] !== undefined && base.data.dataArr.length >= 0 ) {

                    var fromRowObj = base.data.dataArr[fromRow].object_id;
                    var toRowObj = base.data.dataArr[toRow].object_id;
                    var fromColObj = base.data.headerArray[fromCol].map;
                    var toColObj = base.data.headerArray[toCol].map;
                    var left = base.elements.body.offset().left;
                    var elementFrom = $(".div-col[obj_id=" + fromRowObj + "-" + fromColObj + "]");
                    var elementTo = $(".div-col[obj_id=" + toRowObj + "-" + toColObj + "]");

                    if (typeof elementFrom.offset() !== "undefined" && typeof elementTo.offset() !== "undefined") {
                        var width = elementTo.offset().left - elementFrom.offset().left + elementTo.width();
                        var height = Math.abs(elementFrom.offset().top - (elementTo.offset().top + elementTo.height()));
                        var selection = $('<div class="gg-selection"><div>');
                        selection.width(width);
                        selection.css('left', (elementFrom.offset().left - left  ) + 'px');
                        selection.css('top', elementFrom.position().top + 'px');
                        selection.height(height);
                        base.elements.body.prepend(selection);
                    }

                }



            },


            selectRowGroup: function (from,to) {
                base.renderSelected.clearAll();
                base.renderSelected.renderType = "rowGroup";
                var fromRow = _.findIndex(base.data.dataArr, { object_id: from})
                var toRow = _.findIndex(base.data.dataArr, { object_id: to})
                var direction = "down";

                if( Math.sign(toRow - fromRow ) === -1){
                    var newFromRow = toRow
                    var newToRow = fromRow
                    fromRow = newFromRow;
                    toRow = newToRow;
                    direction = "up";
                }

                base.renderSelected.renderPosition = {y1:fromRow,y2:toRow};
                base.renderSelected. renderSelected();

            },


            setSelectedGroup: function(){
                var fromRow = base.renderSelected.renderPosition.y1;
                var toRow =  base.renderSelected.renderPosition.y2;
                base.selectedGroup = [];
                for (var i = parseInt(fromRow); i <= parseInt(toRow); i++){
                    var rowObj = base.data.dataArr[i];
                    selectRowGroup(rowObj.object_id);
                }
            },

            setColGroup: function(map,multi){


                base.renderSelected.clearAll();
                this.renderType = "colGroup";
                selectCol(map,multi);
                base.renderSelected.renderSelected();
                base.mathFormulas.selectedSum();
            },

            renderColGroup: function () {
                base.renderSelected.clearSelection();
                _.forEach(base.selectedGroup, function(value, key) {
                    if( value.select_type === "header"){
                        base.renderSelected.renderCol(value);
                    }
                });
            },

            renderCol: function (_object) {
                var element = $(".headerCol[map=" + _object.col_id + "]", base.$el);
                var left = base.elements.body.offset().left;

                if (typeof element.offset() !== "undefined") {
                    var selection = $('<div class="gg-selection"><div>');
                    selection.height(base.elements.body.height());
                    selection.width(element.width());
                    selection.css('top', '0px');
                    selection.css('left', (element.offset().left - left) + 'px');
                    base.elements.body.prepend(selection);
                }
            },


            renderRowGroup: function () {
                var fromRow = base.renderSelected.renderPosition.y1;
                var toRow =  base.renderSelected.renderPosition.y2;
                if( ! _.inRange(fromRow, base.vDragg.startIndex, base.vDragg.endIndex) ){
                    fromRow = base.vDragg.startIndex;
                    if(  fromRow  > toRow || ! _.inRange(toRow , base.vDragg.startIndex, base.vDragg.endIndex)  ){
                        return false;
                    }
                }

                if( ! _.inRange(toRow , base.vDragg.startIndex, base.vDragg.endIndex) ){
                    toRow = base.vDragg.endIndex - 1;
                    if(  fromRow  > toRow  ){
                        return false;
                    }
                }




                //console.log("base.data.dataArr[fromRow] " ,  base.data.dataArr[fromRow]);
                //console.log("base.data.dataArr[toRow] " ,  base.data.dataArr[toRow]);


                if( typeof base.data.dataArr[fromRow] !== undefined  &&  typeof base.data.dataArr[toRow] !== undefined ) {

                    var fromObj = base.data.dataArr[fromRow].object_id;
                    var toObj = base.data.dataArr[toRow].object_id;

                    var left = base.elements.body.offset().left;
                    var elementFrom = $(".div-col-prime[rowobj=" + fromObj + "]");


                    if (typeof elementFrom.offset() !== "undefined") {

                        var elementTo = $(".div-col-prime[rowobj=" + toObj + "]");
                        var height = Math.abs(elementFrom.offset().top - (elementTo.offset().top + elementTo.height()));
                        var selection = $('<div class="gg-selection"><div>');
                        selection.width(base.elements.body.width() - (base.elements.vtrack.width() + elementFrom.width()));
                        selection.css('left', (elementFrom.position().left + elementFrom.width() ) + 'px');
                        selection.css('top', elementFrom.position().top + 'px');
                        selection.height(height);
                        base.elements.body.prepend(selection);

                    }


                }

            },

            renderCell : function () {
                $( "div" ).remove( ".gg-cell-selection" );
                var left = base.elements.body.offset().left;
                var obj_id = base.renderSelected.defaultCell.row_id + "-" + base.renderSelected.defaultCell.col_id
                var element = $("[obj_id=" + obj_id + "]", base.$el);

                if ( typeof element.offset() !== "undefined" ) {
                    var selection = $('<div class="gg-selection gg-cell-selection"><div>');
                    selection.height(element.parent().height());
                    if(base.renderSelected.defaultCell.col_id && base.renderSelected.defaultCell.col_id == "group") {
                        selection.width(element[0].clientWidth);
                    } else {
                        selection.width(element.width());
                    }                    
                    selection.css('top', (element.position().top) + 'px');
                    selection.css('left', (element.offset().left - left) + 'px');
                    base.elements.body.prepend(selection);


                    this.setHeaderRowIndicators();

                }
            },


            renderSelected: function(){
                base.renderSelected.clearSelection();
                if(base.renderSelected.renderType === "rowGroup"){
                    base.renderSelected.renderRowGroup()
                }else if (base.renderSelected.renderType === "colGroup"){
                    base.renderSelected.renderColGroup();
                }else if(base.renderSelected.renderType === "cellGroup" ){
                    base.renderSelected.renderCellGroup();
                }

                base.renderSelected.renderCell();
            },

            setHeaderRowIndicators: function(){
                $(".div-col-prime[rowobj="+base.selected.row_id+"]",base.$el).addClass('selected-prime-row');
                $(".headerCol[map="+base.selected.col_id+"]",base.$el).addClass('selected-col-header');
            }

        }



        base.loadGrid = function (){
            //base.batchSelect = [];


            if(  (options.data.settings.grid_type !== 'import') && (options.data === undefined || options.data.dataArr.length < 1)   ){
                addAlert("alert-warning","<b>Data Error:</b> No Data found with this filter criteria");
                return false;
            }


            //DB GRID SETTING OVERRIDESorting does not work as expected in Dashboard, screen and grid on sort by name / string values. Lower case and upper case words are out of order. Below screenshot has action name sorted descending as Z- A. The sorting is not correct as highlighted in the screenshot.	Open	Low	 	Yet to Analyze	Dharmesh

            if( options.data.settings ) {



                if (options.data.settings.default_row_height && options.data.settings.default_row_height.length) {
                    base.options.defaultRowHeight = options.data.settings.default_row_height;
                }


                //Heath S Added 2/11/2019 - Sets default header height from gris settings
                base.headerRow.height = parseInt(options.data.settings.header_height) ||   base.options.vDraggerMinHeight


                if (options.data.settings.fixed_columns && options.data.settings.fixed_columns > 0) {
                    base.options.fixedColumn = options.data.settings.fixed_columns;
                }

                if (options.data.settings.grid_type && options.data.settings.grid_type.length) {
                    base.options.gridType = options.data.settings.grid_type;

                   // base.$el.find('.gt-col-temp-drop').css('display','none');

                    if( base.options.gridType === 'import' && options.data.dataArr.length < 1){
                        options.data.dataArr.push({object_id:"1"});
                        //options.data.dataArr.push({object_id:"2"});
                       // options.data.dataArr.push({object_id:"3"});
                    }


                    if( base.options.gridType === 'batch-save' ){
                        base.$el.find('.gt-col-temp-drop').css('display','none');
                    }

                    /*TODO: Remove this
                    if( base.options.gridType === 'import'){
                        base.$el.find('.gg-paste-save-btn').css('display','inline-block');
                    }else{
                        base.$el.find('.gg-paste-save-btn').css('display','none');
                    }
                    */

                }


                if(!options.data.settings || options.data.settings.comments !== 1){
                    base.$el.find('.tools-comments').css('display','none');
                }
                if(!options.data.settings || options.data.settings.attachments !== 1){
                    base.$el.find('.tools-attachments').css('display','none');
                }
                /************************ dvalaboju001 11/14/2019 *************************/
                if(!options.data.settings){
                    base.$el.find('.tools-export').css('display','none');
                }


                if (options.data.settings.max_xls_rows && options.data.settings.max_xls_rows.length) {
                    base.options.maxXlsRows = options.data.settings.max_xls_rows;
                }

            }

            base.loader.hideLoader();
            base.elements.fliterOverlay.css('display', 'none');

            //////////////////////// SETUP DATA  ////////////////////////////////////
            base.data.dataArr = options.data.dataArr;
            base.data.headerArraySafe = options.data.headerArray;
            base.data.messageObject = options.data.messageObject;
            base.data.attachmentObject = options.data.attachmentObject;
            base.data.errorObject = options.data.errorObject;
            base.data.headerArray = _.filter(base.data.headerArraySafe, { display: 'y' });
            base.data.safeCopyArr = _.clone( base.data.dataArr);
            base.headerService.templateArray = options.data.columnTemplates;
            base.data.newRow = [];
            base.data.pivotGroup = options.data.pivotGroup;
            base.data.isCollapsed = false;
            base.data.auditCellsObject = options.data.auditCellsObject;


            if (typeof options.data.vDragg === "undefined" || _.isEmpty(options.data.vDragg)){
                options.data.vDragg =  base.vDragg;
            }

            if (typeof options.data.hDragg === "undefined" || _.isEmpty(options.data.hDragg)){
                options.data.hDragg =  base.hDragg;
            }


            //:TODO [batch_select] move this block of code in to a method in base.headerService Obj
            if(typeof options.data.settings !== "undefined" && typeof options.data.settings.batch_select !== "undefined" && options.data.settings.batch_select === 1 ) {
                base.batchSelectMode = true;
                if(  typeof _.find(base.data.headerArraySafe, {map:"batchSelect"}) == "undefined" ) {
                    var batchSelectObj = {
                        map: "batchSelect",
                        display: "y",
                        col_class: "batchSelect",
                        call_back: "",
                        edit_type: "batchSelect",
                        header_class: "batchSelect ",
                        editable: "y",
                        label: "<i class='fa fa-check '></i>",
                        width: 40,
                        format: "batchSelect",
                        object_id: "batchSelect"
                    }

                    base.data.headerArraySafe.splice(1, 0, batchSelectObj);

                    //push the new batchSelect Col type into templateArray
                    if( base.headerService.templateArray && base.headerService.templateArray.length ){
                        base.headerService.templateArray[0].columns.splice(1, 0, batchSelectObj);
                    }



                }
            }


            base.cellData = options.data.cellData;
            base.tableState = options.data.tableState;
            base.userAccessLevel = options.data.userAccessLevel;
            base.sso_id = options.data.sso_id;
            base.headerService.templateIndex = 0;
            base.batch.setBatchSelected();
            base.headerService.renderColTools();
            base.selected = options.data.selected;
            base.vDragg = options.data.vDragg;
            base.hDragg = options.data.hDragg;
            base.headerService.colToolSetTemplates();
            base.batchSelect = options.data.batchSelect;
            base.actionTemplates = options.data.actionTemplates;

            base.buildDom.batchRowSelectable(base.data.dataArr);


            base.actionsService.buildMenu();
            paintColor(base.selected.color);
            pipe();
            buildGrid();
            base.focus.gridBody.focus();

        };


        var validateData = function(){
            addAlert("alert-danger","<b>Error:</b> Your data did not contain a header  ",'5000' );
        }


        base.loader = {
            visible: true,
            setSize: function () {
                base.elements.dataLoader.width(base.$el.width() + 30);
                base.elements.dataLoader.height($(window).height() - base.$el.offset().top);
                //filterOverlay
                base.elements.fliterOverlay.width(base.$el.width() + 30);
                base.elements.fliterOverlay.height($(window).height() - (base.$el.offset().top + base.gridFiltersDivHeight));

            },

            showLoader: function() {
                base.largeText.hide();
                base.gridFiltersDivHeight = 0;// need to make this to 0,otherwise white space gets added to grid bottom on window resize/showLoader
                this.setSize();
                base.elements.dataLoader.css('display','block');
                this.visible = true
                //filterOverlay
                base.elements.fliterOverlay.css('display','none');
            },

            hideLoader: function() {
                base.elements.dataLoader.fadeOut( "slow", function() {
                    // Animation complete.
                });
                this.visible = false;
            }

        }





        //TODO: REMOVE THIS - This has been moved to importController
        base.pasteXls =  {
            extensionId: 'npkadhmnkkhdbojjjmenafcnjadkcfme', //GOOD
            // extensionId: '123123123123', //FAKE
            allowed: true,
            hasExtension:false,
            requiredVersion: 0.1,
            checkForExtension: function() {
                /*var that = this;
                if( window.chrome ){
                    chrome.runtime.sendMessage(
                        this.extensionId,
                        {method: 'getVersion'},
                        function (response) {
                            if (response) {
                                if (response >= that.requiredVersion) {
                                    that.hasExtension = true;
                                }else{
                                }
                            }else{
                                //////////console.log("the required version of the chrome extension " + that.extensionId + " is not installed" ) ;
                            }
                        }
                    )
                }*/
                return false;
            },



            paste: function () {
                //IF WE ARE IN CHROME
                if( this.hasExtension ){
                    this.readClipboard();
                }else{
                    this.openPasteWindow();
                }
            },


            openPasteWindow: function(){
                //////////console.log("openpaste window message")
            },


            readClipboard: function() {
                var  that = base.pasteXls;
                chrome.runtime.sendMessage(
                    this.extensionId,
                    { method: 'getClipboard' },
                    function(response) {
                        that.processXls(response);
                    }
                )
            },



            validate: function(value,validation){
                var returnVal = "";
                if(validation == 'numeric' ){
                    if( !isNaN(value)){
                        returnVal =  Number(value);
                    }
                    return returnVal;
                }else if(validation == 'date' ){
                    return returnVal;
                }
                return value;
            },



            processXls: function(_data){

                if(base.options.gridType != 'import' ) {
                    addAlert("alert-warn"," The structure of this Grid is not editable ",4000 );
                    return false;
                }

                //TODO: add check for tabdlim
                base.cellData = {};
                var rows = _data.split("\n");
                var headerArray = base.data.headerArray;
                var dataArray = [];



                //TODO - CHECK IF WE HAVE
                // 1. COLUMN SELECTION
                // 2. ROW SELECTION
                // 3. GROUP OF CELLS





                console.log("base.selected " , base.selected);

                console.log(" base.selectedGroup " ,  base.selectedGroup);





                // options.data.dataArr = [];
                //Clear the array but do not break the
                while (options.data.dataArr.length > 0) {
                    options.data.dataArr.pop();
                }

                for (var i = 0; i < rows.length;  i++) {
                    var newRow = {};
                    var cells = rows[i].split("\t");
                    newRow[headerArray[0].map] = (i+1).toString();

                    for (var c = 0; c < cells.length;  c++) {
                        var hI = c+1;
                        if(headerArray[hI] !== undefined ) {
                            if (headerArray[hI].validation) {
                                var rawValue = cells[c];
                                var value = this.validate(cells[c], headerArray[hI].validation);
                                newRow[headerArray[hI].map] = value;
                            } else {
                                newRow[headerArray[hI].map] = cells[c];
                            }
                        }
                    }
                    options.data.dataArr.push(newRow);
                }


                base.data.dataArr =  options.data.dataArr;
                base.data.safeCopyArr = base.data.dataArr;
                pipe();


            },

            save: function(){

                /*
                base.api.callBack( 'ftReclassSave',base.data.dataArr,'batch-import');
                setTimeout(function(){
                    addAlert("alert-success","Fast-Track Import was successfully saved",4000 );
                }, 1000);
                */

            }

        }

        base.pasteXls.checkForExtension();

////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// MAP   /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

        // base.tags['2-MARS'] = {title:"first tags", comment:"some text" , type:"action", row_obj:"2"};
        // base.tags['3-ACCOUNT'] = {title:"first tags", comment:"some text" , type:"action", row_obj:"2"};
        // base.tags['6-ACCT_DESC'] = {title:"first tags", comment:"some text" , type:"action" , row_obj:"7"};

        function renderTagMap(_object){
            for(var propt in base.tags){
                buildMap(base.tags[propt])
            }
            function buildMap(obj){
            }
            base.$el.find('.gg-vScroll-container').append("<div class='map'></div>");
        }


        renderTagMap();

////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// PRIVATE FUNCTIONS /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////   BUILD GRID   //////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////


        function buildGrid(){

            base.layout.offset = base.$el.offset();
            windowHeight = $(window).height() - 1;
            windowWidth = $(window).width();

            //Set Scroll Height //////////////////////////////////////
            var headerHeight = base.headerRow.height ||  base.elements.header.height();

            var copyRightFooterHeight = document.getElementsByClassName('copyright')[0] ? document.getElementsByClassName('copyright')[0].clientHeight 
                                        :document.getElementsByClassName('cookieAcceptance')[0]? document.getElementsByClassName('cookieAcceptance')[0].clientHeight 
                                        : 0;
           
            //base.layout.scrollWindow = windowHeight - (base.layout.offset.top + base.elements.tools.outerHeight() + headerHeight + base.elements.footer.outerHeight() + base.elements.hScroll.height());
            base.layout.scrollWindow = windowHeight - (base.layout.offset.top + base.elements.tools.outerHeight() + headerHeight + base.elements.footer.outerHeight() + base.elements.hScroll.height() + 
                                        copyRightFooterHeight);
            //Added check to adjust the grid height when appearing on modal..
            if(options.data.fromModal !=undefined && options.data.fromModal){ //these styles will apply only when call the grid on Modal 8/19/2021
                base.elements.body.height('calc(100vh - 310px)');
            }else{
               base.elements.body.height(base.layout.scrollWindow );
            }
            base.layout.rowsPerPage = Math.round((base.layout.scrollWindow / base.options.defaultRowHeight) + base.options.rowBuffer);
            base.layout.startIndex = 0;
            base.layout.endIndex = base.layout.startIndex +  base.layout.rowsPerPage;

            if( base.vDragg.endIndex === 0 || base.data.pivotGroup && base.data.pivotGroup.groupRows && base.data.pivotGroup.groupRows.length>0){
                base.vDragg.endIndex = base.layout.rowsPerPage;
            }
            if(!_.isEmpty(base.data.pivotGroup)){
                if(base.data.pivotGroup != undefined && (base.data.pivotGroup.groupRows.length >0 || base.data.pivotGroup.columnToRows.length >0) && (base.selVal && base.selVal == true)) {
                    base.pivotChecked = "enabled";
                } else if(base.data.pivotGroup != undefined && (base.data.pivotGroup.groupRows.length == 0 || base.data.pivotGroup.columnToRows.length == 0) && (base.selVal && base.selVal == false)){
                    base.pivotChecked = "disabled";
                } else if(base.data.pivotGroup != undefined && (base.data.pivotGroup.groupRows.length > 0 || base.data.pivotGroup.columnToRows.length > 0) && (base.selVal == undefined)) {
                    base.pivotChecked = "enabled";
                }
            }
           
            // displayIssueCols();
            setHeader();
            setColWidthArr();
            base.layout.gridHeight = setRowHeightArr();
            setGridLayout();
            buildVScrollBar();
            buildHScrollBar();
            buildHeaderDom();
            setDefaultSelect();//This was commented out; Heath uncommented 2/1/2019
            buildBodyDom();
            positionColFreezeHandle();
            base.menuService.buildBatchActionsMenu();
        }








        function chunk(arr, start, end) {
            var returnArray = [];
            for(var i =  start; i < end; i++){
                returnArray.push(arr[i]);
            }
            return returnArray;
        }

        function setHeader(){
            var start = 0;
            if(base.data.headerArraySafe){
                let obj = {};
                obj = _.find(base.data.headerArraySafe,{map:"group"});
                const index = _.findIndex(base.data.headerArraySafe,{map:"group"})
                if(!_.isEmpty(obj) && index > 1){
                    _.remove(base.data.headerArraySafe,{map:"group"});
                    base.data.headerArraySafe.splice(1, 0, obj);
                }
            }
            base.data.headerArray = _.filter(base.data.headerArraySafe, { 'display': 'y' });
            var end =  base.data.headerArray.length;
            var fixedColumns = ( base.options.fixedColumn > base.data.headerArray.length )? base.data.headerArray.length : base.options.fixedColumn;
            base.data.fixedHeaderArrayDisplay = chunk(base.data.headerArray,start,fixedColumns);
            base.data.headerArrayDisplay = chunk(base.data.headerArray,fixedColumns,end);
            base.data.fixedHeaderSafeCopyArr = _.clone(base.data.headerArrayDisplay);
        }

/////////////////////////////////////   BUILD HEADER DOM  ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

        function setGridLayout(){
            //HEADER ELEMENTS///////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////
            base.elements.headerLeft.width(getGridWidth(base.data.fixedHeaderArrayDisplay));
            base.elements.headerRight.width(getGridWidth(base.data.headerArrayDisplay));
            base.elements.headerLeft.css('left','0px');
            base.elements.headerRight.css('left',getGridWidth(base.data.fixedHeaderArrayDisplay)+'px');

            //DATA ELEMENTS///////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////
            base.elements.ggleftcol.width(getGridWidth(base.data.fixedHeaderArrayDisplay));
            base.elements.ggrightcol.width(getGridWidth(base.data.headerArrayDisplay));
            base.elements.ggleftcol.css('left','0px');
            base.elements.ggrightcol.css('left',getGridWidth(base.data.fixedHeaderArrayDisplay)+'px');
        }


        function getGridWidth(_array){
            var sum = 0;
            for(var i = 0;  i < _array.length; i++ ){
                sum += (_array[i].width);
            }
            return sum;
        }

        function getGridHeight(){
            var sum = 0;
            for(var i = 0;  i < base.data.dataArr.length; i++ ){
                sum += (base.data.dataArr[i].height);
            }
            return sum;
        }

        function removeProperty(_array,_value){
            //dataArr
            for(var i = 0;  i < _array.length; i++ ){
                _.forOwn(_array[i], function(n, key) {
                    if( _.includes(n,_value) ){
                        ////////////////////////////consolee.log(key);
                        ////////////////////////////consolee.log(n);
                        _array[i][key] = _array[i][key].replace(_value,'')
                    }
                });
            }

        }



        base.dataProvider = {

            getData: function(_url,_type,_data){

                var returnData = "";
                if(_data == undefined ){
                    _data = {}
                }


                //START HERE GET TOOKEN FROM COOKIE
                var promise =   $.ajax({
                    url: _url,
                    type: _type,
                    data: _data,
                    headers:{'X-XSRF-TOKEN':"d3288042-a98c-4db9-a392-dd01b4bf850c"}
                });

                return promise;
            }
        }




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// messageService /////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //   base.messageService.hideMessages();




        base.actionsService = {

            selectedTemplate:null,


            buildMenu: function(){
                if( base.userAccessLevel < 1){
                    base.$el.find('#actionsCreate').hide();
                }

                //New li element created to display selected grid actions item from the dropdown menu - (Added by OMI).
                var menuContainerSelectedElement = base.$el.find('#selectedActMenuItem');
                menuContainerSelectedElement.html("");
                menuContainerSelectedElement.hide();



                if(base.actionTemplates && !_.isEmpty(base.actionTemplates) ){
                    var selectedItem;
                    base.$el.find('#actionsMenu').show();


//                    if(this.selectedTemplate !== null){
                    if(this.selectedTemplate !== null){
                        base.$el.find('#actionsMenu').addClass('gg-drop-down-on')
                    } else {
                        base.$el.find('#actionsMenu').removeClass('gg-drop-down-on');
                    }

                    var menuContainerElement = base.$el.find('#actionsDropMenu');
                    menuContainerElement.html("");

                    _.forEach(base.actionTemplates, function(value, key) {
                        var checked = ( base.actionsService.selectedTemplate === value) ? "<i class=\"fa fa-check\"></i>" : "";
                       // var system = ( value.system === 1) ? "<i class=\"fa fa-circle \" style='color:red; margin-left: 10px'></i>" :  "<i class=\"fa fa-circle \" style='color:red; margin-left: 10px'></i>" ;
                       var pivot = ( value.action_type === 'pivot') ? "<i class=\"fa fa-ravelry\" style=\"font-size: 8px;\"></i>" : "";
                        console.log("value.system " , value.system   );


                        var listElement = '<li class="tools-action selected" action="actionTemplate" action_template_key="'+value.action_template_key+'"><a>'+checked+' '+value.name+'<span style=\"float:right\">'+pivot+'</span>'+'</a>'+'</li> '
                        menuContainerElement.append(listElement);

                        if(base.actionsService.selectedTemplate) {
                            //Stores the selected grid actions name value.
                            selectedItem = base.actionsService.selectedTemplate.name;
                        }
                    });

                    // Binding up the stored value to li element..
                    var selectedElement = '<b> - ( '+selectedItem+' ) </b>';
                    if(base.actionsService.selectedTemplate && base.actionsService.selectedTemplate != null) {
                        menuContainerSelectedElement.show();
                        menuContainerSelectedElement.append(selectedElement);
                    }

                    var clear = '<li class="divider"></li><li class="tools-action" action="clearActionTemplate"><a><i style="color:red" class="fa fa-times"></i> <i class="fa fa-times" style="display:none"></i> Clear Action</a></li>';

                    if(this.selectedTemplate){
                        menuContainerElement.append(clear);
                    }

                }
                else{

                    //base.$el.find('#actionsMenu').hide();
                    var clear = '<li class="divider"></li><li class="tools-action" action="clearActionTemplate"><a><i style="color:red" class="fa fa-times"></i> <i class="fa fa-times" style="display:none"></i> Clear Action</a></li>';

                    if(this.selectedTemplate){
                        menuContainerElement.append(clear);
                    }

                }


            },


            applyActionTemplate: function(_action_template_key){


                console.log("I AM CALLED  ");


                console.log("_action_template_key " , _action_template_key);
                console.log("base.actionTemplates " , base.actionTemplates);
                console.log("action_template " , action_template);



                var action_template  = _.find( base.actionTemplates , {'action_template_key' : parseInt(_action_template_key)});
                
                /* 3/2/2020 */


                this.selectedTemplate = action_template;
                /* pivot templates 2021 dvalaboju */
                if(action_template.action_type && action_template.action_type == 'pivot'){
                    base.tableState.colSearch = [];

                    var pivotGroupObject ={};
                    if(action_template && action_template.grid_data){
                        pivotGroupObject =   JSON.parse(action_template.grid_data);
    
                        _.each(base.actionTemplates, function(item){
                            if(item.action_template_key == action_template.action_template_key){
                                item.selectedAction = true;
                            }else{
                                item.selectedAction = false;
                                item.map = '';
                            }
                        })
                            base.hasActionSelected = true;
                            options.data.hasActionSelected = true;
                            this.buildMenu();
        
                    }
                    options.data.actionTemplates = base.actionTemplates;
      
                  
                    base.pivotChecked = "enabled";
                    //sending clearActionTemplate true value as a parameter to let apply pivotpanel temp about clear action dropdown menu selection.
                    base.api.callBack('callName',{pivotGroupObject:pivotGroupObject, clearActionTemplate: false},'applyPivotTemplate');
                }else{
                    base.isPreviousPivotTemplate = false
                        var gridData =   JSON.parse(action_template.grid_data);

                        _.each(base.actionTemplates, function(item){
                            if(item.action_template_key == action_template.action_template_key){
                                item.selectedAction = true;
                                item.map = gridData.tableState.colSearch[0].predicate;
                            }else{
                                if(item.selectedAction && item.action_type =='pivot'){
                                    base.isPreviousPivotTemplate = true;
                                }
                                item.selectedAction = false;
                                item.map = '';
                            }
                        })
                        if(base.isPreviousPivotTemplate){
                            var emptyPivotObject = {
                                agg:{ // aggregated fields
            
                                },
                                collapsed: [ false, false],
                                groupRows: [],
                                columnToRows: []
                                }  
                            base.api.callBack('callName',{pivotGroupObject:emptyPivotObject, clearActionTemplate: true},'applyPivotTemplate');
                        }
        
        
        
        
                        //: START - stringify null hack :===========================================================================
                        // - this is a temp hack to change back tableState.colSearch with values of null to undefined.
                        // JSON.stringify() automatically does this because JSON doesn't have a concept of undefined
        
              //  var x = base.tableState.colSearch;
                if( gridData.tableState.colSearch.length >= 1 ){
                    base.tableState.colSearch = [];
                    _.forEach(gridData.tableState.colSearch, function (value, key) {
                        if(value.type == "array" && value.value.length){
                            _.forEach(value.value, function (valueA, keyB) {
                                /* if( valueA === null){  old
                                    value.value[keyB] = undefined;
                                } */
                                /* NOTE: some grids have passing the null values so its causing the above commented code.
                                        we need to apply the undefined values only when the column have undefined value and ationTemplate 
                                        (tableState value should be null) */
                                var hasUndefined = _.uniq(_.map(base.data.dataArr,value["predicate"]));
                                if((hasUndefined &&  hasUndefined.indexOf(undefined)>-1) && valueA === null){
                                    value.value[keyB] = undefined;
                                }
                            });
                            base.tableState = gridData.tableState;
                            base.hasActionSelected = true;
                            options.data.hasActionSelected = true;
                            gridData.hasActionSelected = true;
                        }
        
        
                       
                     })
                 pipe();
                 this.applyColTemplate(gridData.templateArr);
                 this.buildMenu();

                } 
            }
        },



            applyColTemplate: function(_headerTemplateArray){

                if(!_headerTemplateArray){
                    return false;
                }

                _.forEach(_headerTemplateArray, function (value, key) {
                    var object = _.find(base.data.headerArraySafe, {map: value.map});
                    if (object && object.map.toLowerCase() != 'object_id') {
                        object.width = value.width;
                        object.display = value.display;
                        //Sort Object;
                        var toMove = _.findIndex(base.data.headerArraySafe, object);
                        if (toMove >= 0) {
                            arraymove(base.data.headerArraySafe, toMove, key);
                        }
                    }
                });


                base.selected.row_id = null;
                setDefaultSelect();

                /*  apply fixed heights & columns for grid action 6/13/2019  parseInt(options.data.settings.header_height)*/
                if(base && base.options){
                    base.options.fixedColumn = (this.selectedTemplate!=null && this.selectedTemplate.fixed_columns>0)?this.selectedTemplate.fixed_columns: options.data.settings.fixed_columns;//this.selectedTemplate.fixed_columns;
                    base.headerRow.height = ( this.selectedTemplate!=null && this.selectedTemplate.header_height>0)?this.selectedTemplate.header_height : parseInt(options.data.settings.header_height);
                }

                /* ends Here */
                buildGrid();

            },

            clearActionTemplate: function () {
                /* if we clear the gridaction make template selection false */
                _.each(base.actionTemplates, function(item){
                    if(item.selectedAction){
                        item.selectedAction = false;
                        item.map='';
                    }
                })
                   options.data.actionTemplates = base.actionTemplates;
                /* pivot template check for clear */
                if(this.selectedTemplate && this.selectedTemplate.action_type == 'pivot')
                {
                    this.selectedTemplate = null;
                    var emptyPivotObject = {
                        agg:{ // aggregated fields
    
                        },
                        collapsed: [ false, false],
                        groupRows: [],
                        columnToRows: []
                        }   
                        this.buildMenu();
                        base.pivotChecked = "disabled";
                        //sending clearActionTemplate true value as a parameter to let apply pivotpanel temp about clear action dropdown menu selection.
                        base.api.callBack('callName',{pivotGroupObject:emptyPivotObject, clearActionTemplate: true},'applyPivotTemplate');
                }else{  /* existing flow-- grid actions */
                    this.selectedTemplate = null;
                    clearAll()
                    base.headerService.colToolsReset();
                    this.buildMenu();
                    base.$el.find('#actionsMenu').removeClass('gg-drop-down-on');
    
                   /*  _.each(base.pivotActionTemplates, function(item){
                        if(item.selectedAction){
                            item.selectedAction = false;
                            item.map='';
                        }
                    }) */
                }
               
                
                
            },
            /* is calling when first time load and to clear the previous selected template */
            clearSelectedTemplate: function(){
                _.each(base.actionTemplates, function(item){
                    if(item.selectedAction){
                        item.selectedAction = false;
                        item.map='';
                    }
                })
                this.selectedTemplate = null;
               base.$el.find('#actionsMenu').removeClass('gg-drop-down-on');
            },

            clearSelectedTemp:function(){
                base.actionsService.clearActionTemplate()
                this.selectedTemplate = null;
                this.buildMenu();
                base.$el.find('#actionsMenu').removeClass('gg-drop-down-on');
            },


        }
        base.messageService = {
            messageURL: base.base_url + "/commentByKey",
            replyURL: base.base_url + "/commentReply",
            reolveURL: base.base_url + "/resolveComment",
            position: {},
            context:{},
            commentRowsOnly:false,
            attachmentURL:base.base_url +"/loadFileAttachments",
            removeDocURL: base.base_url +"/dltGridDocuments",
            downloadAttachURL : base.base_url+'/downloadCommentAttachement',



            toggleCommentRows: function(){
                base.tableState.comments = !base.tableState.comments;
                this.toggleCommentFilterIcon();
                pipe();
            },


            showCommentRows: function(){
                base.tableState.comments = true;
                this.toggleCommentFilterIcon();
                pipe();
            },

             /* added by Divya toggle the attachments 9/22/2019*/
            toggleAttachmentRows: function(){
                base.tableState.attachments = !base.tableState.attachments;
                this.toggleAttachmentFilterIcon();
                pipe();
            },

            showAttachmentRows: function(){
                base.tableState.attachments = true;
                this.toggleAttachmentFilterIcon();
                pipe();
            },

             /* added by dvalaboju001 toggle the audi cells 05/2021*/
             toggleOverrideCellstRows: function(){
                base.tableState.auditCells = !base.tableState.auditCells;
                this.toggleOverrideCellstFilterIcon();
                pipe();
            },

            showOverrideCellRows: function(){
                base.tableState.auditCells = true;
               this.toggleOverrideCellstFilterIcon();
                pipe();
            },

             /* added by Divya toggle the attachments 9/22/2019*/
            hideCommentRows: function(){
                base.tableState.comments = false;
                this.toggleCommentFilterIcon();
                pipe();
            },

            hideAttachmentRows: function(){
                base.tableState.attachments = false;
                this.toggleAttachmentFilterIcon();
                pipe();
            },



            toggleCommentFilterIcon: function(){
                if(!base.tableState.comments){
                    base.$el.find('.gg-comment-filter i.fa-filter').show();
                    base.$el.find('.gg-comment-filter i.fa-times').hide();
                }else{
                    base.$el.find('.gg-comment-filter i.fa-filter').hide();
                    base.$el.find('.gg-comment-filter i.fa-times').show();
                }
            },

            toggleAttachmentFilterIcon: function(){
                if(!base.tableState.attachments){
                    base.$el.find('.gg-attachment-filter i.fa-filter').show();
                    base.$el.find('.gg-attachment-filter i.fa-times').hide();
                }else{
                    base.$el.find('.gg-attachment-filter i.fa-filter').hide();
                    base.$el.find('.gg-attachment-filter i.fa-times').show();
                }
            },

            toggleOverrideCellstFilterIcon: function(){
                if(!base.tableState.auditCells){
                    base.$el.find('.gg-override-filter i.fa-filter').show();
                    base.$el.find('.gg-override-filter i.fa-times').hide();
                }else{
                    base.$el.find('.gg-override-filter i.fa-filter').hide();
                    base.$el.find('.gg-override-filter i.fa-times').show();
                }
            },

            hideMessages: function(){
                base.data.messageObject = [];
                buildGrid();
            },

            hideAttachments: function(){
                base.data.attachmentObject = [];
                buildGrid();
            },

            setMessageLevel: function(_action){
                    options.data.settings.commentType = _action
                    base.api.callBack('callName',{type:_action},'reloadComment');
                    this.setMessageDisplay(_action);
                    if(_action === 'none'){
                        this.hideCommentRows();
                    }
            },


            setMessageDisplay: function(_action){
                var element = $('.gg-comment-display[action="'+_action+'"]');
                $(".gg-comment-display i" ).hide();
                element.find("i").show();
            },

            setAttachmentDisplay: function(_action){
                var element = $('.gg-attachment-display[action="'+_action+'"]');
                $(".gg-attachment-display i" ).hide();
                element.find("i").show();
            },

            setExportDisplay: function(_action){
                var element = $('.gg-export-action[action="'+_action+'"]');
                $(".gg-export-action i" ).hide();
                element.find("i").show();
            },

            /* setAttachmentLevel: function(_action){
                base.api.callBack('callName',{type:_action},'reloadAttachment');
                this.setAttachmentDisplay(_action);
                if(_action === 'none'){
                    this.hideCommentRows();
                }
            }, */

            showMyMessages: function(){

            },


            showAssignedMessages: function(){

            },


            showAllMessages: function(){
                base.data.messageObject = [];
                base.dataProvider.getData(this.messageURL,'POST',{comment_key:_comment_key}).then(function (_data){
                });
            },

            showAllAttachmets: function(){
                base.data.attachmentObject = [];
                base.dataProvider.getData(this.attachmentURL,'POST',{parent_key:_parentKey,parent_type:_parentKey}).then(function (_data){
                    console.log("_data of attachment",_data);
                });
            },

            goToComment: function(_data){


                console.log("//////////////////////////////////////////////////////////////////////////////////////////////");
                console.log("_data " , _data);
                console.log("//////////////////////////////////////////////////////////////////////////////////////////////");


                if( _.isEmpty(_data) ){
                    addAlert("alert-warn"," Opps! it appears the context is broken ",4000 );
                    return false;
                }

                if(_data.tableState && !_.isEmpty(_data.tableState) ){

                    //: START - stringify null hack :===========================================================================
                    // - this is a temp hack to change back tableState.colSearch with values of null to undefined.
                    // JSON.stringify() automatically does this because JSON doesn't have a concept of undefined

                    if(_data.tableState.colSearch){
                        _.forEach(_data.tableState.colSearch, function (value, key) {
                            if(value.type == "array" && value.value.length){
                                _.forEach(value.value, function (valueA, keyB) {
                                    console.log("value.value  : " , value.value)
                                    if( valueA === null){
                                        value.value[keyB] = undefined;
                                    }
                                })
                            }
                        });
                    }
                    //: END - stringify null hack :===========================================================================

                    base.tableState = _data.tableState;
                    pipe();
                }

                if(_data.type === "link"){
                    return true;
                }


                var vIndex = _.findIndex(base.data.dataArr, { object_id: _data.row_id });
                var hIndex = _.findIndex(base.data.headerArray, { map: _data.col_id }) ;

                if(  vIndex >= 0 && hIndex >= 0 ){
                    vIndex += - Math.round(base.layout.rowsPerPage / 2) + 2
                    hIndex += - ( base.options.fixedColumn + 3)

                    if( vIndex < 0){
                        vIndex = 0;
                    }

                    if( hIndex < 0){
                        hIndex = 0;
                    }
                    setHScroll(hIndex);
                    setVScroll(vIndex);

                    setSelected( _data.col_id,_data.row_id,'cell');

                    highlight(base.selected.element,'pink');

                    setTimeout(function(){
                        base.selected.element.addClass('gg-cell-blink');
                    }, 700);

                }else{
                    addAlert("alert-warn"," Opps! it appears the context is broken ",4000 );
                }
            },


            renderAddMessageTemplate: function(){
                this.toggleOpen();
                this.setPosition();
                this.position.$messageDetails.html("");
                //console.log(base.elements.messageItemContainer)

                this.context = {
                    tableState: _.cloneDeep(base.tableState),
                    selected: _.cloneDeep(base.selected)
                }

                var callName = 'addComment';
                base.api.callBack(callName,this.context,'comment' );
            },


            addAttachment: function(){
                this.toggleOpen();
                this.setPosition();
                this.position.$messageDetails.html("");

                this.data = {
                        colData:base.selected.colData,
                        rowData:base.selected.rowData,
                        tableState: _.cloneDeep(base.tableState),
                        selected: _.cloneDeep(base.selected),
                        attachmentValue: options.data.settings.attachments,
                        callName: 'addAttachment'
                }

                var callName = 'addAttachment';
                base.api.callBack(callName,this.data,'attachment' );
            },
	      editAttachments:function(parent_key,parent_type){
                var callName = 'editAttachment';
                base.api.callBack(callName,{parent_key:parent_key ,parent_type:parent_type},'attachment');
                this.destroyMessages();
            },

            previewAttachments:function(parent_key,file_name){
                var callName ='previewAttachment';
                base.api.callBack(callName,{parent_key:parent_key,file_name:file_name},'preview');
                this.destroyMessages();
            },

            auditCellsModal: function(){
                this.context = {
                    tableState: _.cloneDeep(base.tableState),
                    selected: _.cloneDeep(base.selected)
                }

                var callName = 'auditCells';
                base.api.callBack(callName, this.context,'auditcell' );
            },

            setPosition : function(){
                this.position = {
                    "$container": base.elements.messageItemContainer,
                    "$messageList": base.elements.messageItemContainer.find('.gg-list-container'),
                    "$messageDetails": base.elements.messageItemContainer.find('.gg-detail-container'),
                    "$backdrop": base.elements.messageItemContainer.find('.gg-message-detail-backdrop'),
                    "positionX": null,
                    "positionY": null,
                    "cellWidth": null,
                    "obj_id": null,
                    "message_id":null,
                    "open":false,
                    "messageArr":[]
                }
                // Prevents the background scroll when message template is open.
                $(base.elements.messageItemContainer).bind("mousewheel", function() {
                    return false;
                });
            },

            loadMessage: function(_comment_key){
                this.position.comment_key = _comment_key;
                this.getMessageData(_comment_key);
            },


            getMessageData: function(_comment_key){
                var _this = this
                //console.log(_comment_key  )

                /* */
                base.dataProvider.getData(this.messageURL,'POST',{comment_key:_comment_key}).then(function (_data){
                    //console.log("_data " , _data);
                    if(_data.comment && _data.comment.comment_key && _data.comment.comment_key > 0){
                        _data.comment.userAccessLevel = base.userAccessLevel;
                        _data.comment.curUser = base.sso_id;
                        _this.renderMessage(_data.comment);
                    }else{
                        addAlert("alert-warn"," The selected comment could not be found ",4000 );
                    }

                })
            },

            loadAttachment: function(_parent_key,_parent_type){
                this.position.parent_key = _parent_key;
                this.position.parent_type = _parent_type
                this.getAttachmentData(_parent_key,_parent_type);
            },

            getAttachmentData: function(_parent_key,_parent_type){
                var _this = this;
                base.dataProvider.getData(this.attachmentURL,'POST',{parent_key:_parent_key,parent_type:_parent_type}).then(function (_data){
                     console.log("_data -----------" , _data);
                     //_data.attachment.userAccessLevel = base.userAccessLevel;
                    // _data.attachment.curUser = base.sso_id;
                     _this.renderAttachment(_data.comment.attachmentList);
                    //  if(_data.attachment && _data.attachment.parent_key && _data.attachment.parent_key > 0){

                    //  }else{
                    //      addAlert("alert-warn"," The selected attachment could not be found ",4000 );
                    //  }
                 })
            },


            toggleOpen: function(){
                this.position.open = !this.position.open;
            },

            renderMessage:function(_data){

                console.log("_data" , _data)

                var theTemplate = Handlebars.compile(detailMessageHBTemplate);
                var theCompiledHtml = theTemplate(_data);
                this.position.$messageDetails.html(theCompiledHtml);
                this.position.$messageList.css('left','-'+this.position.$container.width()+'px');
                this.position.$backdrop.show();
                this.position.$messageDetails.css('left','0px');
                this.positionMenu();
            },


          /*   renderMessages:function(_obj_id,_x,_y,_width,_height){
                this.setPosition();
                this.position.$messageDetails.html("");
                this.position.positionX = _x;
                this.position.positionY = _y;
                this.position.cellWidth = _width;
                this.position.obj_id = _obj_id;
                this.position.$messageDetails.html("");
                this.position.messageArr = base.data.messageObject[_obj_id],
                this.position.$container.css('display', 'block');
                this.position.$messageList.css('left', '0px');
                this.position.$messageDetails.css('left', this.position.$container.width() + 'px');
                var theTemplate = Handlebars.compile(listMessageHBTemplate);
                var theCompiledHtml = theTemplate(this.position.messageArr);
                this.position.$messageList.html(theCompiledHtml);
                this.positionMenu();
            }, */
            renderMessages:function(_obj_id,_x,_y,_width,_height){
                this.setPosition();
                this.position.$messageDetails.html("");
                this.position.positionX = _x;
                this.position.positionY = _y;
                this.position.cellWidth = _width;
                this.position.obj_id = _obj_id;
                this.position.$messageDetails.html("");
                this.position.messageArr = {};
                this.position.isAttachment=false;
                this.position.hasMessages=false;
                this.position.hasError=false;
                var theCompiledHtml = null;
                if(base.data.errorObject && base.data.errorObject[_obj_id] && !this.position.hasError){
                    // if(this.position.hasError){
                   // this.position.$errorContainer.css('display', 'block');
                    this.position.hasError = true;
                    this.position["$container"]= base.elements.errorcontainer;
                    theCompiledHtml = base.data.errorObject[_obj_id];
                    this.position.$backdrop= base.elements.errorcontainer.find('.gg-message-detail-backdrop');
                    this.position.$messageList= base.elements.errorcontainer.find('.gg-list-container');
                    this.position.$messageList.html(theCompiledHtml);
                }
                if(base.data.messageObject[_obj_id]){
                    this.position.messageArr = base.data.messageObject[_obj_id];
                    this.position.hasMessages=true;
                }
                if(base.data && base.data.attachmentObject && base.data.attachmentObject[_obj_id]){
                    this.position.messageArr = _.extend({}, this.position.messageArr, base.data.attachmentObject[_obj_id]);
                    this.position.isAttachment=true;
                }

                this.position.$container.css('display', 'block');
                this.position.$messageList.css('left', '0px');
                this.position.$backdrop.hide();
                this.position.$messageDetails.css('left', this.position.$container.width() + 'px');

                if(this.position.hasMessages){
                    var theTemplate = Handlebars.compile(listMessageHBTemplate);
                    theCompiledHtml = theTemplate(this.position.messageArr);
                    this.position.$messageList.html(theCompiledHtml);
                }
                if(this.position.isAttachment){
                    var theTemplate = Handlebars.compile(listAttachmentHBTemplate);
                    theCompiledHtml = theTemplate(this.position.messageArr);
                    this.position.$messageList.append(theCompiledHtml);
                }
                if(theCompiledHtml){
                    this.positionMenu();
                }
            },



            back:function(){
                var theTemplate = Handlebars.compile(listMessageHBTemplate);
                var theCompiledHtml = theTemplate(this.position.messageArr);
                this.position.$messageList.html(theCompiledHtml);
                this.positionMenu();
                this.position.$messageList.css('left', '0px');
                this.position.$backdrop.hide();
                this.position.$messageDetails.css('left', this.position.$container.width() + 'px');
            },


            hoverShow:function(_obj_id,_x,_y,_width,_height){
                if(!this.position.open) {
                    this.renderMessages(_obj_id, _x, _y, _width, _height);
                }
            },

            hoverHide:function(_obj_id,_x,_y,_width,_height){
                if(!this.position.open) {
                    this.destroyMessages();
                }
            },


            destroyMessages:function(){
                this.setPosition();
                this.position.$container.css('display', 'none');
                $('.gg-error-container').css('display', 'none');
         //       this.position.$errorContainer.css('dispaly','none');
                this.position.$messageList.html("");
                this.position.$messageDetails.html("");
            },


            editMessage:function(comment_key){
               //console.log("comment_key open modal:" , comment_key);
                var callName = 'editComment';
                base.api.callBack(callName,{comment_key:comment_key},'comment');
                this.destroyMessages();
            },

            resolve:function(comment_key,task_status,task_action_item){
               console.log("Resolve just send to DB:" , comment_key);
                var dataObj = {"comment_key": comment_key,"task_status":task_status,"task_action_item":task_action_item}
                base.dataProvider.getData(this.reolveURL,'post',dataObj).then(function (_data){
                    if( _data.callSuccess != 1  ){
                        addAlert("alert-error"," Comment could not be resolved",4000 );
                    }else{
                        var action = (options.data.settings.commentType) ? options.data.settings.commentType : 'all';
                        base.api.callBack('callName',{type:action},'reloadComment');
                        addAlert("alert-success","Comment was successfully resolved ",4000 );
                    }
                })

                this.destroyMessages();
            },


            deleteMessage:function(comment_key){
                //////////console.log("deleteMessage");
            },


            editReply:function(_comment_key,_message){

              // console.log("_comment_key  " , _comment_key);
              // console.log("_message  " , _message);

                var MS = this;
                if(_message == '' ){
                    addAlert("alert-warn"," Please add a reply message ",4000 );
                    return;
                }

                var comment_key =  this.position.comment_key
                var dataObj = {"comment_key":_comment_key,"message":_message,'type':'update'}
                base.dataProvider.getData(this.replyURL,'post',dataObj).then(function (_data){
                    if( _data.callSuccess != 1  ){
                        addAlert("alert-error"," Reply message was not saved ",4000 );
                    }else{
                        addAlert("alert-success","Reply message was successfully saved ",4000 );
                        MS.loadMessage(comment_key)
                    }
                })


            },

            deleteReply:function(_comment_key){
                var MS = this;
                var comment_key =  this.position.comment_key
                var dataObj = {"comment_key": _comment_key,'type':'delete'}
                base.dataProvider.getData(this.replyURL,'post',dataObj).then(function (_data){
                    if( _data.callSuccess != 1  ){
                        addAlert("alert-error"," Reply message could not be deleted",4000 );
                    }else{
                        addAlert("alert-success","Reply message was successfully deleted ",4000 );
                        MS.loadMessage(comment_key)
                    }
                })
            },


            addReply:function(_replyMessage){
                var MS = this;
                if(_replyMessage == '' ){
                    addAlert("alert-warn"," Please add a reply message ",4000 );
                    return;
                }
                var comment_key =  this.position.comment_key
                var dataObj = {"comment_key": comment_key, "message":_replyMessage, 'type':'add' }
                base.dataProvider.getData(this.replyURL,'post',dataObj).then(function (_data){
                    if( _data.callSuccess != 1  ){
                        addAlert("alert-error"," Reply message was not saved ",4000 );
                    }else{
                        addAlert("alert-success","Reply message was successfully saved ",4000 );
                        MS.loadMessage(comment_key)
                    }
                })

            },
            deleteAttachmentByname:function(_file_name,_parent_key,_parent_type) {
                var Att = this;
                var dataObj= {"file_name":_file_name,"parent_key":_parent_key,"parent_type":_parent_type}
               console.log("+++delte json +++++",_file_name,_parent_key,_parent_type);
               base.dataProvider.getData(this.removeDocURL,'post',dataObj).then(function(_data){
                    if(_data.success !=1){
                        addAlert("alert-error","Document could not be deleted",4000);
                    }else{
                        addAlert("alert-success","Document was successfully deleted",4000);
                        base.api.callBack('callName',{},'reloadAttachment');
                        Att.loadAttachment(_parent_key,_parent_type)
                    }
                })

            },
            downLoadAttachment:function(file_name,parent_key){
                var secondaryWindow = window.open(this.downloadAttachURL + '?fileName=' +file_name+"&commentKey="+parent_key+"&client_key="+sessionStorage.getItem('client_key'));
                if (secondaryWindow == null) {
                    return;
                }
                // Check if there was a problem downloading the attachment.
                secondaryWindow.onload = function () {
                    if (secondaryWindow.document.body.innerHTML.length > 0) {
                            $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                            secondaryWindow.close();
                    }
                };

            },
            downloadAllDoc:function(id){

                console.log("rowdIDDDFD"+id);
                var secondaryWindow = window.open(base.base_url+'/downloadAllDocuments' + '?commentKey='+id+"&client_key="+sessionStorage.getItem('client_key'));
                
                if (secondaryWindow == null) {
                    return;
                }
                // Check if there was a problem downloading the attachment.
                secondaryWindow.onload = function () {
                    if (secondaryWindow.document.body.innerHTML.length > 0) {
                            $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                            secondaryWindow.close();
                    }
                };
            },

            downloadDoc:function(fileName){
                var secondaryWindow = window.open(base.base_url+'/download'+'?'+'file_name=' + fileName + "&client_key=" + sessionStorage.getItem('client_key'));
                if (secondaryWindow == null) {
                    return;
                }
                // Check if there was a problem downloading the attachment.
                secondaryWindow.onload = function () {
                    if (secondaryWindow.document.body.innerHTML.length > 0) {
                            $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                            secondaryWindow.close();
                    }
                };
            },



            positionMenu: function (){

                var positionX  = this.position.positionX + this.position.cellWidth,
                    positionY  = this.position.positionY;

                //POSITION LEFT OF CELL IF CONTAINER GOES OUTSIDE THE GRID
                if( positionX + this.position.$container.width() > windowWidth){
                    positionX  = this.position.positionX - this.position.$container.width();
                }


                var $messageDetailsScroll = base.elements.messageItemContainer.find('.gg-message-scroll'),
                    $messageDetailsTop= base.elements.messageItemContainer.find('.gg-message-top'),
                    $messageDetailsForm = base.elements.messageItemContainer.find('.gg-reply-form'),
                    $messagesScroll = base.elements.messageItemContainer.find('.gg-messages-scroll');


                //SET OUTER CONTAINER HEIGHT
                if( this.position.$messageDetails.height() >  this.position.$messageList.height() ){
                    if(this.position.$messageDetails.height() > base.elements.body.height() ){
                        var  innerHeight = base.elements.body.height() - ( $messageDetailsTop.height()  + $messageDetailsForm.height() )
                        $messageDetailsScroll.height(innerHeight);
                    }
                    this.position.$container.height(this.position.$messageDetails.height());
                }else{
                    if(this.position.$messageList.height() > base.elements.body.height() ){
                        var  innerHeight = base.elements.body.height();
                        $messagesScroll.height(innerHeight);
                    }
                    this.position.$container.height(this.position.$messageList.height());
                }


                if( positionY +  this.position.$container.height() >  windowHeight){
                    var overFlow = (positionY +  this.position.$container.height()) - windowHeight;
                    positionY-=overFlow;
                }


                this.position.$container.css('top',positionY+'px');
                this.position.$container.css('left',positionX+'px');

            }



        }

        /*****************************dvalaboju001 export execl moved to dropdown  11/14/2019******************************************* */

        base.exportToExcel = {
            exportExcel : function(_url, isFilteredGrid){
            angular.element('.tools-export').addClass('gg-export-to-excel');
            angular.element('.tools-export').append('<i class="fa fa-spinner fa-spin"></i>');
            window.is_export = true;
            var action_id = angular.element('#action_id')[0].innerHTML;
            var grid_id = angular.element('#grid_id')[0].innerHTML;
            var screen_key = angular.element('#screen_key')[0].innerHTML;
            var sso_id = angular.element('#sso_id')[0].innerHTML;
            var all_filter_data = (angular.element('#all_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            var display_filter_data = (angular.element('#display_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            var display_all_me_code = (angular.element('#display_all_me_code')[0].innerHTML).replace(/&amp;/g, '&');
            var display_scenario = (angular.element('#display_scenario')[0].innerHTML).replace(/&amp;/g, '&');
            var display_filingGrp = (angular.element('#display_filingGrp')[0].innerHTML).replace(/&amp;/g, '&');

            var filterArrayAll = all_filter_data.split('&');
            var filterArrayDisplay = display_filter_data.split('&');
            var _params = {};
            var gridData = []
            /*dvalaboju001 2/1/2020*/
            var screen_name = angular.element('#screen_name')[0].innerHTML;
            var client_name = angular.element("#client_name")[0].innerHTML;
            var screenObj = angular.element('#screenObj')[0].innerHTML;
            console.log("screen_name",screen_name);
            console.log("client_name",client_name);
            console.log("screenObj",screenObj);
            console.log("filterArrayDisplay",filterArrayDisplay);
            var colFilters = [];
            const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fd = new FormData();
            const api_base_url = base.base_url;

            //Export to excel API call
            var exportApiCall = function(export_post_data){
                _params = _.extend({
                    jsonObj: jsonObj,
                    headerArray : JSON.stringify(headArra),
                    gridSettings : (base.data.settings)
                }, _params);

                if(export_post_data){
                    fd.append("jsonObj", jsonObj);
                    fd.append("headerArray", JSON.stringify(headArra));
                    fd.append("gridSettings", (base.data.settings));
                }

                var promise= $.ajax({
                    method: 'post',
                    url: base.base_url+_url,
                    data: export_post_data ? fd : _params,
                    processData: export_post_data ? false : undefined,
                    contentType: export_post_data ? false : undefined,
                    cache: export_post_data ? false : undefined,
                    enctype: export_post_data ? 'multipart/form-data' : 'application/json'
                })
                .done(function( data ) {
                    if( data.callSuccess != 1 ){
                        angular.element('.tools-export').removeClass('gg-export-to-excel');
                        angular.element('.tools-export').find('i').remove();
                        window.is_export = false;
                        addAlert("alert-error"," Data can not be exported, please contact System administrator", 6000);
                    }else{
                        
                        //window.open(downloadURL,'blank');
                        let apiTokenUrl = api_base_url + '/getAPIAuthToken';
                        base.dataProvider.getData(apiTokenUrl,'GET',{}).then(function (_data){
                            console.log("_data of apiTokenUrl apiTokenUrl",_data);
                            let downloadURL = api_base_url + '/download' + '?' + 'file_name=' + data.file_name + "&client_key=" + sessionStorage.getItem('client_key') +
                                '&apiToken=' + encodeURIComponent(_data.apiToken)+'&georaclehrid=' +sso_id ;
                            window.open(downloadURL,'blank');
                        });
                        // fetch(downloadURL, {
                            // method: 'GET',
                            // headers: {
                                // 'Authorization': sessionStorage.getItem('Authorization')//`Bearer`
                            // }
                        // })
                        // .then(response => response.blob())
                        // .then(blob => {
                            // const blobUrl = URL.createObjectURL(blob);
                            // const link = document.createElement('a');
                            // link.href = blobUrl;
                            // link.download = data.file_name;//'filename.ext';  // provide the file name here
                            // link.style.display = 'none';
                            // document.body.appendChild(link);
                            // link.click();
                            // document.body.removeChild(link);
                        // })
                        
                        angular.element('.tools-export').removeClass('gg-export-to-excel');
                        angular.element('.tools-export').find('i').remove();
                        window.is_export = false;
                    }
                });
            }

            const convertBytes = function(bytes) {
                const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
                if (bytes == 0) {
                    return "n/a";
                }
                const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                if (i == 0) {
                    return bytes + " " + sizes[i];
                }
                return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
            }

            const replacer = (key, value) => typeof value === 'undefined' ? null : value;

            //Added by Vijay to included the filtered column details
            if(isFilteredGrid){
                if(base.tableState && base.tableState.colSearch.length){//Check null, undefined and predicate
                    for(var i = 0; i < base.tableState.colSearch.length; i++){
                        filterArrayDisplay[filterArrayDisplay.length] = "colFilter_"+base.tableState.colSearch[i].predicate+"="+base.tableState.colSearch[i].value.toString();
                        let key =base.tableState.colSearch[i].predicate;
                        let value  = base.tableState.colSearch[i].value.toString();
                        let obj ={};
                        obj[key] = value;
                        colFilters.push(obj);
                        //gridData = JSON.stringify(base.data.dataArr);
                    }
                }
            }else{
                //gridData = JSON.stringify(base.data.safeCopyArr);
            }

            if(display_all_me_code){
            	filterArrayDisplay[filterArrayDisplay.length] = "display_mE_code="+display_all_me_code;
            }
            if(display_scenario){
            	filterArrayDisplay[filterArrayDisplay.length] = "display_scenario_description="+display_scenario;
            }
            if(display_filingGrp){
            	filterArrayDisplay[filterArrayDisplay.length] = "display_filing_group_description="+display_filingGrp;
            }

            //---------------------------------------------------------
            var jsonData = {};
            for(var i = 0; i < filterArrayAll.length; i++){
                jsonData[filterArrayAll[i].split('=')[0]] = filterArrayAll[i].split('=')[1];
            }
            for(var i = 0; i < filterArrayDisplay.length; i++){
                jsonData[filterArrayDisplay[i].split('=')[0]] = filterArrayDisplay[i].split('=')[1];
            }
            /* 2/3/2020 */
          //  var merDisplayArray = _.concat({}, base.data.fixedHeaderArrayDisplay, base.data.headerArrayDisplay);
          var merDisplayArray = _.concat({}, base.data.fixedHeaderArrayDisplay,base.data.fixedHeaderSafeCopyArr);
            var headerDisplArr= [];
            var displayGridData = [];
            _.each(merDisplayArray,function(item){
                if(item.label != null){
                    headerDisplArr.push({
                        object_id : item.object_id,
                        label:item.label,
                        data_type : item.data_type,
                        format : item.format,
                        map : item.map,
                        width : item.width
                    })
                }
            })
           // displayGridData = gridData;

            /* ends here  */
            var _params = {};
            var jsonObj = JSON.stringify(jsonData);
            var headArra = [];
            var filteredMap = [];
            var filteredData = [];
            var tempArr = [];
            var gridObject = {header:[], data:[]};
            var is_import_screen = !action_id && (jsonData.screen_type === "grid-import" || jsonData.screen_type === "grid-import-pivot" || jsonData.screen_type === "grid-import-td");
            
            // Fix for exporting the data in dual screen load implementation.
            if(jsonData.screen_key === '11169' || jsonData.screen_key === '11471'){
                is_import_screen = true;
            }

            /* dvalaboju001 11/15/2019   to pass required grid headers from the UI */
            _.each(base.data.headerArray,function(item){
                headArra.push({
                    object_id : item.object_id,
                    label:item.label,
                    data_type : item.data_type,
                    format : item.format,
                    map : item.map,
                    width : item.width
                });
                filteredMap.push(item.map);
            })
            gridObject.header.push(filteredMap);
            _url = _url + '?' + 'action_id=' + action_id + '&' + 'grid_id=' + grid_id + '&' + 'sso_id=' + sso_id + '&screen_key=' + screen_key /*+ all_filter_data + display_filter_data*/;

            if(isFilteredGrid || is_import_screen){
                addAlert("alert-info","Please wait until the file downloads", 6000);
                //if((JSON.stringify(base.data.dataArr).length > 25000000) && !is_import_screen){
                if(base.data.dataArr.length > 50000 && !is_import_screen){
                    //console.log('Filtered Data Size : '+ convertBytes(JSON.stringify(base.data.dataArr).length));
                    console.log('File Sent : NO, Data Compression : NO');
                    _url = _url + '&is_compressed=' + 'false' + '&filtered_export=' + 'false'
                    exportApiCall(false);
                }else{
                    //console.log('Filtered Data Size: '+ convertBytes(JSON.stringify(base.data.dataArr).length));
                    console.log('File Sent : YES, Data Compression : YES');
                    _.each(isFilteredGrid ? base.data.dataArr : base.data.safeCopyArr, function(obj){
                        tempArr = [];
                        _.each(filteredMap, function(map){
                            tempArr.push(obj[map]);
                        })
                        filteredData.push(tempArr);
                    })
                    gridObject.data = filteredData;
                    var compressedData = JSON.stringify(gridObject, replacer);
                    console.log('Compressed Data Size : '+ convertBytes(compressedData.length));
                    var blob = new Blob( [ compressedData ], {
                        type: EXCEL_TYPE
                    });

                    fd.append("file", blob);

                    _url = _url + '&is_compressed=' + 'true' + '&filtered_export=' + 'true'
                    exportApiCall(true);
                }
            }else{
                addAlert("alert-info","Please wait until the file downloads",6000);
                exportApiCall(false);
            }
            //-----------------------------------------------------

            /**
             * This is using webworker and exceljs.min.js and file saver. START
             */

            /*const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            //if (base.data.dataArr.length <=1000 && window.Worker) {
            if (!window.Worker) {
                var tableData = JSON.parse(gridData);
                screenObj = JSON.parse(screenObj);
                screenObj['export_date'] = moment().format('MM/DD/YYYY LT');
                var strFilename = screenObj['report_name'].replace(/[^a-zA-Z0-9]/g,'-') + "-" +moment().format('YYYY-MM-DD-hh-mm')+".xlsx";

                var myWorker = new Worker('./lib/webworker/exportToExcel.js');
                if(isFilteredGrid){
                    screenObj.filters = Object.assign({},screenObj['filters'],...colFilters);
                }
                myWorker.postMessage({tableData,screenObj,headerDisplArr});
                myWorker.onmessage = function(e) {
                    console.log('messgae recived');
                    if(e.data.error){
                        addAlert("alert-error"," Data can not be exported, please contact System administrator",4000 );
                    }
                    const blob = new Blob([e.data],{type: EXCEL_TYPE});
                    try {
                        saveAs(blob, strFilename);
                        myWorker.terminate();
                    }catch(error){
                        addAlert("alert-error"," Data can not be exported, please contact System administrator",4000 );
                    }

                }
            } else {
                console.log('Your browser doesn\'t support web workers.');*/

            }
        }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// menuService //////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        base.menuService = {

            buildBatchActionsMenu: function() {

                base.batchActions = (options.data.settings && options.data.settings.grid_action ) ? options.data.settings.grid_action : [];
                if(base.batchActions.length === 0 ){
                    base.$el.find('#batch-actions').hide();
                    return false;
                }else{
                    base.$el.find('#batch-actions').show();
                }

                var menuContainerElement = base.$el.find('#gg-modal-actions');
                var menuString = "";

                menuContainerElement.html(menuString);

                _.forEach(base.batchActions, function(value, key) {
                    var icon = (value.icon && value.icon != "" ) ? value.icon : "fa fa-dot-circle-o ";
                    var listElement = '<li class="tools-action" action="batch-action" value="'+key+'"><i class="'+icon+'"></i>'+sanitize(value.name)+'</li>'
                    menuContainerElement.append(listElement);
                })

            }


        }



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// ModalService //////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        base.ModalService = {

            openModalService: function(_title,_map,_action,_template){

               //console.log("_title : " , _title);
               //console.log("_map : " , _map);
               //console.log("_action : " , _action);
               //console.log("base.data.headerArray : " , base.data.headerArray);

                base.elements.modalContainer.show();
                this.setActions(_action,_map);
                var headerObj = _.find(base.data.headerArray,{ 'map': _map });


                var title =  base.elements.modalContainer.find('.gg-title');
                base.elements.modalContainer.attr('map',headerObj.map);
                title.html(_title);
                base.elements.modalContainer.find('.gg-modal-ggBody').css('display','block');
                base.elements.modalContainer.find('.gg-modal-ggBody').html(_template);
                this.positionModal();
            },


            setActions: function(_action,_map){
               //console.log(   base.elements.modalContainer.find('.gg-modal-footer') );
                if(_action == "formatCol"){
                    var footer = '<div action="formatCol" map="'+_map+'" class="gg-modal-action btn btn-xs btn-info">Ok</div><div action="close" style="margin-left:5px" class="gg-modal-action btn btn-xs btn-danger">Cancel</div>';
                    base.elements.modalContainer.find('.gg-modal-footer').html(footer);
                }
            },


            positionModal: function() {
                var left = (base.elements.body.width() / 2 - base.elements.modalContainer.width() / 2) ;
                var top = (base.$el.height() / 2) - base.elements.modalContainer.height() / 2;
                base.elements.modalContainer.css('left',left)
                base.elements.modalContainer.css('top',top)
            },


            dragModal : function(_top,_left) {
                base.elements.modalContainer.css('left',_left  + 'px');
                base.elements.modalContainer.css('top',_top  + 'px');
            },


            closeModalService: function(_map,_action){
                base.elements.modalContainer.hide();
                base.elements.modalContainer.find('.gg-modal-ggBody').html("");
                base.focus.gridBody.focus();
            }


        }



//TODO REMOVE ALL MODAL FUNCTIONS: WE WILL USE MODALSERVICE FOR THIS. THIS WILL JUST CONTAIN TO TEXT AND NUMBER FILTERS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// FilterModalService //////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        base.FilterModalService = {

            openFilterModalService: function(_map,_action){
                base.elements.FilterModalService.show();
                var headerObj = _.find(base.data.headerArray,{ 'map': _map });
                var title = base.elements.FilterModalService.find('.gg-title');
                base.elements.FilterModalService.attr('map',headerObj.map);
                title.html("Show Results Where : <span class='gg-orange'>" + headerObj.label +"</span>");
                this.buildMenu(headerObj,_action);
                this.positionModal();
            },

            filter: function(_map,_action){
                var input = base.elements.FilterModalService.find('.gg-modal-input').val();
                var select = base.elements.FilterModalService.find('.gg-modal-select').val();
                var headerObj = _.find(base.data.headerArray,{ 'map': _map });

                if( select != 'blank' &&  select != '!blank'  ){
                    if( !this.validate(select,input,headerObj.data_type)){
                        return false;
                    }
                }

                colSearchText(_map,input,select,headerObj.data_type);
                this.closeFilterModalService(_map,'close');
            },


            validate: function(_select,_data,_type){
                if( _data.length === 0  && ( _select !==  'blank' &&  _select !==  '!blank'   )){
                    addAlert("alert-danger","<b>Error:</b> please enter search value ");
                    return false;
                }

                if( _type === 'number' && !IsNumeric(_data) ){
                    addAlert("alert-danger","<b>Error:</b> please enter a numeric value ");
                    return false;
                }
                return true;
            },

            positionModal: function() {
                var left = (base.elements.body.width() / 2) ;
                var top = (base.$el.height() / 2) - base.elements.FilterModalService.height() / 2;
                base.elements.FilterModalService.css('left',left)
                base.elements.FilterModalService.css('top',top)
            },


            dragModal : function(_top,_left) {
                base.elements.FilterModalService.css('left',_left  + 'px');
                base.elements.FilterModalService.css('top',_top  + 'px');
            },


            closeFilterModalService: function(_map,_action){
                base.elements.FilterModalService.hide();
                base.focus.gridBody.focus();
            },


            buildMenu: function(_headerObj,_action){
                if( _headerObj.data_type === 'string' ){
                    base.elements.FilterModalService.find('#gg-modal-select').html(modalFilterString);
                }else if( _headerObj.data_type === 'number' ){
                    base.elements.FilterModalService.find('#gg-modal-select').html(modalFilterNumber);
                }else if( _headerObj.data_type === 'date' ){
                    base.elements.FilterModalService.find('#gg-modal-select').html(modalFilterDate);
                }

                var select = base.elements.FilterModalService.find('.gg-modal-select');
                select.val(_action);
                base.elements.FilterModalService.find('.gg-modal-input').val("");

            }
        }




        base.gridActions = {

            launchGridActionModal: function(){

                var templateArr = base.data.headerArraySafe.map(function(header) {
                    return { // return what new object will look like
                        width:  header.width,
                        display: header.display,
                        map:header.map
                    };
                });


                var data = {};
                data.templateArr = templateArr;
                data.tableState =  base.tableState;
                data.cellData=base.cellData;

                /* save the grid Action tempalte fixed columns & header height 6/13/2019*/

                if( base.actionsService &&  base.actionsService.selectedTemplate){
                    base.actionsService.selectedTemplate.fixed_columns = base.data.fixedHeaderArrayDisplay.length;
                    base.actionsService.selectedTemplate.header_height = base.headerRow.height;
                }
                /* ends here */

                base.api.callBack("gridActionModal",data,"openGridModal");

            },

            renderDropDown: function(){


            },


            displayCreateAction: function(){


            }

        }
        base.pivotActions = {

            launchPivotActionModal: function(){

                var templateArr = base.data.headerArraySafe.map(function(header) {
                    return { // return what new object will look like
                        width:  header.width,
                        display: header.display,
                        map:header.map
                    };
                });


                var data = {};
                data.templateArr = templateArr;
                data.tableState =  base.tableState;
                data.cellData=base.cellData;

                /* save the grid Action tempalte fixed columns & header height 6/13/2019*/

                if( base.actionsService &&  base.actionsService.selectedTemplate){
                    base.actionsService.selectedTemplate.fixed_columns = base.data.fixedHeaderArrayDisplay.length;
                    base.actionsService.selectedTemplate.header_height = base.headerRow.height;
                }
                /* ends here */

                base.api.callBack("gridPivotActionModal",data,"openGridModal");

            },

            renderDropDown: function(){


            },


            displayCreateAction: function(){


            }

        }


        base.api = {

            loadData: function(data){
                options.data = data.data;

                //To clear the delete count display block
                base.deleteCount = 0;
                base.elements.footerDelete.html("")
                base.elements.footerDelete.removeClass('gg-delete-count')

                console.log("options.data ===========================" , options.data);


                base.loadGrid();
                },

                showLoader: function(fromModal){
                    base.api.dataRefresh(false);
                    if(fromModal!=undefined && fromModal){ /* only for grid on Modal */
                        base.$el.find(hiddenTextAreaTemplate).css('display','none');
                    }
                    base.loader.showLoader();
                },
            /* 5/12/2020  is called when the first time grid loads*/
            clearSlectedActionTemplateFirst: function () {
                base.actionsService.clearSelectedTemplate();

            },
            /* 5/13/2020 is called when we have any tablestate change from the databuoy */
            clearSelectedTemplateFromData : function(){
                base.actionsService.clearActionTemplate();
            },




            goToActionTemplate: function(_action_template_key) {
                base.actionsService.applyActionTemplate(_action_template_key);
            },


            goToComment: function(_data){
                base.messageService.goToComment(_data);
            },



            hideLoader: function(fromModal){
                base.api.dataRefresh(false);
                if(fromModal!=undefined && fromModal){
                    delete base.focus.hiddenTA;
                    delete base.elements.hiddenTA;
                }
                base.loader.hideLoader();
            },

            removeNewRow: function(_object){


                console.log("WAS THIS TRIGGERED " , _object);

                var indexD = _.findIndex(base.data.newRow, _object);
                if (indexD > -1) {
                    base.data.newRow.splice(indexD, 1)
                }
            },

            gridRefreshPipe: function(data){

                base.data.dataArr =  options.data.dataArr;
                base.data.safeCopyArr =  base.data.dataArr;


                base.actionsService.buildMenu();
                pipe();
            },

            gridRefresh: function(data){
                buildGrid();
            },

            // Will get called from gridDirective - used for header modal..
            adjustModal: function(data) {
                base.headerService.modalResize(data);
            },


            loadActonTemplates: function(templates){

                base.actionTemplates = templates;
                base.actionsService.buildMenu();

            },


            loadComments: function(comments,type){
                if(base && base.data ){
                    base.messageService.setMessageDisplay(type);
                    base.data.messageObject = comments;
                    buildGrid();
                    if(base.tableState.comments && !_.isEmpty(comments)){
                        base.messageService.showCommentRows()
                    }
                }
            },
            /* load attachments added by Divya 9/22/2019 */

            loadAttachments: function(attachments){
                if(base && base.data ){
                    base.messageService.setAttachmentDisplay();
                    base.data.attachmentObject = attachments;
                    buildGrid();
                    if(base.tableState.attachments && !_.isEmpty(attachments)){
                        base.messageService.showAttachmentRows()
                    }
                }
            },

            /* load audit cells 5/31/2021 */
            loadAuditCells:function(auditcellObj,type){
                if(base && base.data ){
                    base.data.auditCellsObject = auditcellObj;
                    buildGrid();
                    if(base.tableState.auditCells && !_.isEmpty(auditcellObj)){
                        base.messageService.showOverrideCellRows()
                    }
                }
            },


            hideOverLay: function() {
                base.elements.fliterOverlay.css('display', 'none');
            },

            dataRefresh: function(show) {
                if(show){
                    base.elements.refreshIcon.css('display', 'block');
                }else{
                    base.elements.refreshIcon.css('display', 'none');
                }

                base.elements.refreshIcon.css('left', (base.$el.width() + base.$el.offset().left) / 2 + 'px');
                base.elements.refreshIcon.css('top', (base.$el.height() + base.$el.offset().top) / 2 + 'px');
            },



            callBack: function(callName,data,type){
                $.isFunction( options.callBack ) && options.callBack.call( this, {callName:callName,data:data,type:type} );
            },



            batchAction: function(_index){


                var dataRequired =  base.batchActions[_index].row_data_required;

                var returnObj =  _.find(base.batchSelect, { 'row_id': base.selected.row_id});

                if( dataRequired == 1 && typeof returnObj == "undefined" ){
                    base.batch.selectRow();
                }

                var data = [];
                var callback = (base.batchActions[_index]) ? base.batchActions[_index].call_back : "";

                if( dataRequired == 1 && (!base.batchSelect || base.batchSelect.length <= 0) ){
                    addAlert("alert-warn","Please select one or more rows ",4000 );
                    return false;
                }

                //We will add any batch action of the batch-save type grid here
                if(callback === "batchSaveDelete" || callback === "batchSaveUnDelete" ) {
                    this.callBack(callback, base.batchSelect,"batchSave");
                    base.batch.unselectAll();
                }else{
                    this.callBack(callback, base.batchSelect,"form");
                }


            },


            //////////////////////////////////////////////// base.api ///////////////////////////////////////////////////////////






            deleteRows: function(deleteArray){


                if(deleteArray.length) {
                    ////////console.log("=============WE ARE IN THE DELETE")
                    for (var i = 0; i < deleteArray.length; i++) {

                        //console.log("options.data.dataArr " , options.data.dataArr);
                        //console.log("deleteArray[i] " , deleteArray[i]);

                        var indexD = _.findIndex(options.data.dataArr, deleteArray[i]);



                        //console.log("indexD " , indexD);
                        //console.log("options.data.dataArr " , options.data.dataArr);



                        if (indexD > -1) {
                            //console.log("WE DELETED :" ,  options.data.dataArr[indexD]);
                            options.data.dataArr.splice(indexD, 1)
                        }
                    }


                    base.data.dataArr =  options.data.dataArr;
                    base.batch.setSelectedFromBatch();
                    base.data.safeCopyArr =  _.clone( base.data.dataArr);

                    pipe();
                    buildGrid();
                }

            },



            updateData: function(_update) {
                ////////console.log("_update" , _update);


                var data =   _update.data,
                    filter = _update.filter,
                    prvIndex = 0,
                    itemsToCheck = [];
                    base.data.errorObject = _update.errorObject; // error

                 /* after grid refresh -need to apply the batch select rules again-- added on 01/04/2019 */
                if(options.data.settings.batch_select == 1){
                    base.buildDom.batchRowSelectable(data)
                }

                if(typeof filter !== 'undefined' &&  filter !== null ){
                    ////////console.log(" WE NEED TO CHECK: " , filter)
                        ////////console.log(" WE NEED TO CHECK: " , filter)
                        if(_.isArray(filter)){
                             _.filter(options.data.dataArr,function(item){
                                _.each(filter,function(value){
                                    var objeKey = Object.keys(value);
                                    if(item[objeKey] == value[objeKey]){
                                        itemsToCheck.push(item);
                                    }
                                })
                            })
                        }
                        else if(_.isObject(filter)){
                            itemsToCheck =  _.filter(options.data.dataArr, filter);
                        }
                        
                        ////////console.log(" itemsToCheck: " , itemsToCheck)
                    }
                    /* else{
                        itemsToCheck = _update.data;
                    } */

                var diffrence = _.differenceBy(itemsToCheck, data, 'object_id');
                //var diffrenceAdd = _.differenceBy(data, itemsToCheck, 'object_id');




                //TODO: impliment a binary search or at least just loop over the diffrence
                // https://medium.com/@jeffrey.allen.lewis/javascript-algorithms-explained-binary-search-25064b896470

                if(data.length > 0 ){
                    /////////////////////// UPDATE - INSERT ////////////////////////////////////////////
                    for(var i = 0 ; i < data.length ; i++) {
                        var index = _.findIndex(options.data.dataArr, { object_id: data[i].object_id});
                        if(index > -1 ){
                            ////////////// UPDATE ELEMENTS
                            ////////console.log("SHOULD BE UPDATING : " ,data[i] )
                            prvIndex = index;
                            options.data.dataArr[index] = data[i];
                            //////////console.log('UPDATE : ' ,options.data.dataArr);
                        }else{
                            ////////////// ADD ELEMENTS
                            ////////console.log('INSERT : ' , data[i]);
                            options.data.dataArr.splice(prvIndex+1, 0,  data[i]);
                        }
                    }
                    /////////////////////// DELETE  ////////////////////////////////////////////
                  //  if( itemsToCheck.length && data.length ){
                    if( itemsToCheck.length>0 && data.length>0 ){
                        ////////console.log("=============WE ARE IN THE DELETE")
                        var toRemove = _.differenceBy(itemsToCheck, data, 'object_id');
                        for(var i = 0 ; i < toRemove.length ; i++) {
                            var indexD = _.findIndex(options.data.dataArr, { object_id: toRemove[i].object_id});
                            if(indexD > -1 ){
                                ////////console.log("WE DELETED :" ,  options.data.dataArr[indexD]);
                                options.data.dataArr.splice(indexD,1)
                            }
                        }
                    }

                    base.data.dataArr =  options.data.dataArr;
                    /////////////////////// BATCH OPERATIONS ////////////////////////////////////
                    base.removeBatchSelection = true;
                    base.batch.setSelectedFromBatch();
                    /////////////////////// BATCH OPERATIONS ////////////////////////////////////
                    base.data.safeCopyArr =  _.clone( base.data.dataArr);
                    pipe();
                }
                /* added by Divya 8/7/2019 if gridUpdate retuns the empty data objet  for DELETE */
                else {
                    if(_update && _update.filter &&  _update.filter.object_id){
                        var findRemoveObject =   _update.filter.object_id;
                        var indexD = _.findIndex(options.data.dataArr, { object_id: findRemoveObject});
                            if(indexD > -1 ){
                                ////////console.log("WE DELETED :" ,  options.data.dataArr[indexD]);
                                options.data.dataArr.splice(indexD,1)
                            }

                        base.data.dataArr =  options.data.dataArr;
                        base.data.dataArr.map(i => i.SCENARIO_TYPE_CODE = options.data.settings.display_issue_columns === 1 ? 'EXAM' : '');
                        /////////////////////// BATCH OPERATIONS ////////////////////////////////////
                        base.batch.setSelectedFromBatch();
                        /////////////////////// BATCH OPERATIONS ////////////////////////////////////
                        base.data.safeCopyArr =  _.clone( base.data.dataArr);
                        pipe();
                    }

                }
            },


            destroy: function() {
                options.data = null;
                base.$el.html("");
                base.data = [];
                base = null;
                $(window).off("base.resize");

            },

            releaseData: function() {
                base.data = [];
            }

        }


        ///////////////////////////////////////////////////////////////////////////////////
        ////////////////////////  buildUniqueFilterMenu  /////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////
        ///ON CLICK we will build this menu. We will cache the unigue col values.

        base.uniqueFilters = {
            selected:{},
            all_selected: true,
            filterArr:[],


            getFilterObj: function(_map,_arr,_searchString) {
                var returnObj =  _.find(this.filterArr, { 'map': _map});

                // creating the grid filter dropdown values, this will fix the dropdown values being cleared switching betwwen two screens.
                var x = _.find(base.tableState.colSearch, { 'predicate': _map });

                if(x && (!_.isEmpty(x) || x.length>0) && ((base.tableState && base.tableState.colSearch && base.tableState.colSearch.length>0  && options.data.firstLoad != 1))){

                    var searchObj = {};
                    searchObj.map = _map;
                    searchObj.data_type = _.result(_.find(base.data.headerArray,{ 'map': _map }), 'data_type');
                    searchObj.selected = [];
                    searchObj.allSelected = true;
                    searchObj.searchString = _searchString;
                    //if(_.find(base.tableState.colSearch, { 'predicate': _map })){
                        searchObj.selected = _.filter(base.tableState.colSearch,{predicate:_map})[0].value;
                        var tableStateIndex = _.findIndex(base.tableState.colSearch, { 'predicate': _map });
                        if(tableStateIndex == 0){
                            searchObj.uniqueColData =  this.getUniqueFilters(_map,base.data.safeCopyArr,searchObj.data_type);
                        }else{
                            searchObj.uniqueColData =  this.getUniqueFilters(_map,_arr,searchObj.data_type);
                        }
                    //}
                    this.filterArr.push(searchObj);
                    return searchObj;
                }// end of check(creating the grid filter dropdown values, this will fix the dropdown values being cleared switching betwwen two screens.)
                if(returnObj === undefined) {
                    var searchObj = {};
                    searchObj.map = _map;
                    searchObj.data_type = _.result(_.find(base.data.headerArray,{ 'map': _map }), 'data_type');
                    // searchObj.uniqueColData = this.getUniqueFilters(_map,_arr,searchObj.data_type);
                    searchObj.selected = [];
                    searchObj.allSelected = true;
                    searchObj.searchString = _searchString;
                    this.filterArr.push(searchObj);
                    return searchObj;
                }
                else{
                    return returnObj;
                }
            },

            applyFilter: function(_map) {

                var returnObj =  _.find(this.filterArr, { 'map': _map});

                //if WE ARE EQUALE DO NOT APPLY FILTER




                if(returnObj.selected.length == returnObj.uniqueColData.length ){
                    //remove filter
                    clearFilter('search',_map);
                    return false;
                }




                colSearchMultiFilter(_map,returnObj.selected,"[]",'array',false);

            },

            searchUniqueFilters: function(filterObj,_searchString){
                var rows = _searchString.split("\n");

                //WE HAVE TAB DELIMITED DATA FROM
                if(rows.length > 1 ){
                    return this.selectFromArray( filterObj.map,filterObj.uniqueColData,rows );
                }

                if( _searchString.length > 0){
                    return _.findArrayByPartialValue(filterObj.uniqueColData, _searchString);
                }
                return (filterObj.uniqueColData) ? filterObj.uniqueColData : [] ;
            },

            getUniqueFilters: function(_map,_arr,_data_type){
                var uniqueVals = _.uniqueColVal(_arr,_map,_data_type);
                
                function sortNumber(a,b) {
                    return a - b;
                }
                if( _data_type === 'string' ){
                   uniqueVals.sort(); 
                }else{
                    uniqueVals.sort(sortNumber);
                    
                }
                /* moving the null/undefined values to first postition 3/19/2021 */
                if (uniqueVals.indexOf(null) > 0) {
                    uniqueVals.splice(uniqueVals.indexOf(null), 1);
                    uniqueVals.unshift(null);
                }
                if (uniqueVals.indexOf(undefined) > 0) {
                    uniqueVals.splice(uniqueVals.indexOf(undefined), 1);
                    uniqueVals.unshift(undefined);
                }
                return uniqueVals;
            },

            addSelected: function(_map,_value){
                var returnObj =  _.find(this.filterArr, { 'map': _map});
                returnObj.data_type = _.result(_.find(base.data.headerArray,{ 'map': _map }), 'data_type');
                if( returnObj.data_type === 'number' && (_value !== 'undefined' && _value !== 'null')){ // added null check on 3/17/2021( we do not need to do the parseFloat when the value is null)
                    //HS- changed to allow floating-point numbers
                        _value = parseFloat(_value);                             
                }
               
                returnObj.selected.push(_value);
                if(returnObj.selected.length == returnObj.uniqueColData.length ){
                    $('input.gg-multi-checkbox_all' ,base.$el).prop('checked', true);
                    returnObj.allSelected == true;
                }else{
                    returnObj.allSelected == false;
                }

                return true;
            },

            removeSelected: function(_map,_value){
                var returnObj =  _.find(this.filterArr, { 'map': _map});
                if( returnObj.data_type === 'number'  && (_value !== 'undefined' && _value !== 'null') ){
                    //HS- changed to allow floating-point numbers
                    // _value = parseInt(_value);
                    _value = parseFloat(_value);
                }
                returnObj.selected =  _.without(returnObj.selected,_value);
                //BLANKS: FOR ANY UNDEFINED VALUES IN A COLUMN - WE NEED TO REMOVE undefined  with the function below because  _.without will not remove it
                if (_value === 'undefined') {
                    var indexArray = returnObj.selected.indexOf(undefined);
                    if (indexArray > -1) {
                        returnObj.selected.splice(indexArray, 1);
                    }
                }
                // nulls: for any null values in a column- we need to remove null with the function below _.without will not remove it -3/17/2021
                if(_value === 'null'){
                    var indeNullArr = returnObj.selected.indexOf(null);
                    if (indeNullArr > -1) {
                        returnObj.selected.splice(indeNullArr, 1);
                    }
                }
                return true;
            },

            selectAll: function(_map,_array,_selectAll){
                var returnObj =  _.find(this.filterArr, { 'map': _map});
                returnObj.selected = [];
                if( _selectAll === true){
                    _array.forEach(function (arrayElem){
                        returnObj.selected.push(arrayElem)
                    });
                }
                return true;
            },


            selectFromArray: function(_map,_array,_selectArray){
               //console.log("=================================selectFromArray===========================");
               //console.log("_map " , _map);
               //console.log("_array " , _array);
               //console.log("_selectArray " , _selectArray);


                var returnObj =  _.find(this.filterArr, { 'map': _map});

               //console.log("returnObj " , returnObj);


                returnObj.selected = [];


                if(_selectArray.length ){
                    _selectArray.forEach(function (arrayElem){
                            if(  _.includes(returnObj.uniqueColData,arrayElem ) ){
                               //console.log("========= " , arrayElem);
                               returnObj.selected.push(arrayElem)
                            }
                    });
                }


                return  returnObj.selected

            },




            buildMenu: function(_map,_arr,_searchString,_selectAll,_firstLoad){
                //todo: THIS IS HAPPNING ON OK CLICK - AND SHHOULD NOT

                var filterObj = this.getFilterObj(_map,_arr,_searchString);

                var index = _.findIndex(base.tableState.colSearch, { predicate: _map});
                if( index < 0  ){
                    filterObj.uniqueColData = this.getUniqueFilters(_map,_arr,filterObj.data_type);
                }

                var array = this.searchUniqueFilters(filterObj,_searchString);
                var checkBoxHtml = ""
                var isSelectedAll = '';
                var colSearch = _.find(base.tableState.colSearch, { 'predicate': _map });

                if( colSearch === undefined &&  _firstLoad){
                    isSelectedAll = 'checked';
                    this.selectAll(_map,array,true);
                }

                if( _selectAll === true || _selectAll === false ){
                    this.selectAll(_map,array,_selectAll);
                    if(_selectAll === true ){
                        isSelectedAll = 'checked';
                    }
                }

                checkBoxHtml = "<div> <input tab-index='0' type='checkbox' name='all ' " +isSelectedAll+ " class='gg-multi-checkbox_all' value='Select All'> Select All</div>";



               // var test = "";
                if( array.length < base.options.maxUniqueColFilters ){
                    var loopindex = 1;
                    array.forEach(function (arrayElem){

                        //TODO CREATE VIRTUAL SCROLLER FOR THIS
                        // if( loopindex++ < 100 ) {
                        var isSelected = '';
                        if (filterObj.selected.indexOf(arrayElem) != -1) {
                            isSelected = 'checked';
                        }
                        var displayText = arrayElem;
                        if (displayText === undefined || displayText === '' || displayText === null) {
                            displayText = '(Blanks)'
                        }

                        // checkBoxHtml += " <div> <input type='checkbox' class='gg-multi-checkbox' " + isSelected + " name='" + arrayElem + "' value='" + arrayElem + "'> " + displayText + " </div>";

                        //This will allow the strings with all the expressions (Added By Omprakash - 06/17/2019)
                        checkBoxHtml += `<div> <input type="checkbox" class="gg-multi-checkbox" name="${arrayElem}" value="${arrayElem}" ${isSelected}>${displayText}</div>`;

                       // test+='{"label":"'+arrayElem+'","value":"'+arrayElem+'"},';

                    })
                       // console.log(test);
                }else{
                    checkBoxHtml = "<b>begin typing to select filters </b> <br />  <i style='font-size: 10px'>right click empty cell to filter out blanks</i>";
                }


                if(array && array.length <= 0 ){
                    checkBoxHtml = "no items matched search";
                }

                base.$el.find('.gg-multi-select').html(checkBoxHtml);
            }
        }


        function buildHeaderDom(){
            base.elements.headerLeft.empty();
            base.elements.headerRight.empty();
            var colHeaderHeight = 0;
            base.data.headerArrayDisplay  = base.data.fixedHeaderSafeCopyArr.slice( base.hDragg.startIndex, base.hDragg.endIndex);
            var height = colHeaderHeight > 0 ? colHeaderHeight:( base.headerRow.height || base.options.vDraggerMinHeight);
            base.$el.find('.overflow').css('height', height);

            $.each(base.data.fixedHeaderArrayDisplay, function( index, value ){
                options.data.settings.fixed_columns = base.data.fixedHeaderArrayDisplay.length /* freeze panes 3/2/2020 */
                var header_class = value.header_class;
                if( base.selected.col_id == value.map ){
                    header_class += 'selected-col-header'
                }

                if( value.editable === 'y'){
                    header_class += ' edit-col-header'
                }

                var col = $("<div colObj="+value.object_id+" map="+value.map+" class='headerCol endCap'></div>");
                if( index !== 0 ){
                    var col = $("<div colObj="+value.object_id+" map="+value.map+" class='headerCol dragOrder " +header_class+"'>"+sanitize(value.label)+"</div>");
                    var colWidthDom = $("<div class='col-resize-handle'></div>");
                    var sort = buildSortIcon(value);
                    var menu = buildDropDownMenu(value);
                    col.append(colWidthDom);
                    col.prepend(menu);
                    col.prepend(sort);
                    colWidthDom.css('height', height);
                } else {
                    var rowHeightDom = "<div class='row-resize-handle'></div>"
                    col.append(rowHeightDom);
                }
                // $('.dropdown-toggle').dropdown();


                col.css('width',value.width);
                col.css('height', height);
                base.elements.headerLeft.append(col)
                base.elements.headerLeft.attr('rowobj', '-1') // to enable header drag drop
            })


            $.each(base.data.headerArrayDisplay, function( index, value ) {
                var header_class = ( value.header_class !== undefined)  ? value.header_class : "";
                if( base.selected.col_id == value.map ){
                    header_class += ' selected-col-header'
                }

                if( value.editable === 'y'){
                    header_class += ' edit-col-header'
                }


                var sort = buildSortIcon(value),
                    menu = buildDropDownMenu(value),
                    col = $("<div colObj="+value.object_id+" map="+value.map+"  class='headerCol dragOrder " +header_class+"'>"+sanitize(value.label)+"</div>"),
                    colWidthDom = $("<div class='col-resize-handle'></div>");
                col.append(colWidthDom);
                col.prepend(menu);
                col.prepend(sort);
                //$('.dropdown-toggle').dropdown();
                col.css('width',value.width);
                col.css('height', height);
                colWidthDom.css('height', height);
                base.elements.headerRight.append(col)
                // base.elements.headerRight.attr('rowobj', '-1')
            });

            /////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////START SCRIPT FOR 2nd LEVEL DROPMENU/////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////////

            base.$el.find('a.trigger').on('click', function(event){
                var current = $(this).next();
                var grandparent=$(this).parent().parent();
                if( $(this).hasClass('left-caret') ){
                    current.hide();
                    $(this).removeClass('left-caret');
                    $(this).addClass('right-caret');
                }else{
                    current.show();
                    $(this).addClass('left-caret');
                    $(this).removeClass('right-caret');
                }
                grandparent.find('.left-caret').not(this).toggleClass('right-caret left-caret');
                grandparent.find(".sub-menu:visible").not(current).hide();
                event.stopPropagation();
            });


            $(".dropdown-menu > li > a:not(.trigger)" , base.$el ).on("click",function(){
                var root=$(this).closest('.dropdown');
                root.find('.left-caret').toggleClass('right-caret left-caret');
                root.find('.sub-menu:visible').hide();
            });



            /*

             // ADD TOOLTIP

             data-toggle='tooltip' data-placement='top' title='"+value.label+"'

             $('body').tooltip({
             selector: '.headerCol'
             });
             */

            ////////////////////////////END SCRIPT FOR 2nd LEVEL DROPMENU/////////////////////////////

        }


        function buildSortIcon(value){
            var sort = "<div class='pull-left sortHeader stopEvent' style='margin-left:5px'><i class='fa fa-sort '></i></div>"
            if( base.tableState.sort.predicate && base.tableState.sort.predicate ===  value.map ){
                if( base.tableState.sort.reverse == true ){
                    var sort = "<div class='sortHeader stopEvent' style='margin-left:5px'><i class='fa fa-sort-alpha-desc'></i></div>"
                }else{
                    var sort = "<div class='sortHeader stopEvent' style='margin-left:5px'><i class='fa fa-sort-alpha-asc'></i></div>"
                }
            }
            return sort;
        }

        function buildDropDownMenu(value){
            var element = $(dropDownTemplate);
            element.attr("colObj",value.object_id);
            // IF NOT data_type === number then hide

            if( value.data_type !== 'number' ){
                element.find('.gg-menu-greater').hide();
                element.find('.gg-menu-less').hide();
            }

            if(!value.removable){
                element.find('.gg-menu-action[action="removeCol"]').hide();
                element.find('.gg-menu-action[action="formatCol"]').hide();
            }


            if(value.edit_type !== "batchSelect"){
                element.find('.gg-menu-action[action="select-all"]').hide();
                element.find('.gg-menu-action[action="un-select-all"]').hide();
            }

            var filters;
            if( value.data_type === 'number'  ){

                filters =   '<a class="trigger right-caret">Number Filters</a> '+
                    '<ul class="dropdown-menu sub-menu">'+
                    '<li class="open-input" action=":"><a >Contains</a></li> '+
                    '<li class="open-input" action="!:" ><a >Does NOT Contain</a></li> '+
                    '<li role="presentation" class="divider"></li> '+
                    '<li class="open-input" action="=" ><a>Equals...</a></li> '+
                    '<li class="open-input" action="!="><a>Does NOT Equal...</a></li> '+
                    '<li role="presentation" class="divider"></li> '+
                    '<li class="open-input" action=">=" ><a>Greater Than...</a></li> '+
                    '<li class="open-input" action="<="><a>Less Than...</a></li> '+
                    '<li role="presentation" class="divider"></li> '+
                    '<li class="open-input" action="blank" ><a>Blank</a></li> '+
                    '<li class="open-input" action="!blank"><a>Not Blank</a></li> '+
                    '</ul>';


            }else if( value.data_type === 'string' ) {

                filters = '<a class="trigger right-caret">Text Filters</a> '+
                    '<ul class="dropdown-menu sub-menu">'+
                    '<li class="open-input" action=":"><a>Contains </a></li> '+
                    '<li class="open-input" action="!:" ><a>Does NOT Contain</a></li> '+
                    '<li role="presentation" class="divider"></li> '+
                    '<li class="open-input" action="=" ><a>Equals...</a></li> '+
                    '<li class="open-input" action="!="><a>Does NOT Equal...</a></li> '+
                    '<li role="presentation" class="divider"></li> '+
                    '<li class="open-input" action="blank" ><a>Blank</a></li> '+
                    '<li class="open-input" action="!blank"><a>Not Blank</a></li> '+
                    '</ul>';


            }else if( value.data_type === 'date' ) {

                //////////console.log("I AM A DATE! WE NEED TO MAKE DATA FILTERS")

            }

            element.find('#dropDownFilters').html(filters);

            if(base.tableState.sort.predicate == value.map){
                element.find('.gg-remove-sort a').html(' <i class="fa fa-times"></i> Remove Sort');
            }else{
                element.find('.gg-remove-sort a').hide();
            }


            var model =  _.filter(base.tableState.colSearch, { predicate: value.map })


            if( model != undefined && !_.isEmpty(model) ){
                var parentElement = element.find('.gg-remove-filter a')
                parentElement.html(' <i class="fa fa-times"></i> Remove Filter');
                parentElement.parent().attr('map',model.predicate);
                var thisElement = element.find('.caret');
                element.find('.btn-default').addClass('btn-filterOn')
                thisElement.removeClass('caret');
                thisElement.addClass('fa fa-filter');
                thisElement.parent().addClass('selected-filter');

                if( model.operator === ':'){
                    element.find('.gg-column-search').val(model.value)
                }

                if( model.operator === '>='){
                    element.find('.gg-search-greater').val(model.value);
                }

                if( model.operator === '<='){
                    element.find('.gg-search-less').val(model.value);
                }


            }







            return element;
        }


///////////////////////////////////////////////////////////////////////////////////////////////////
//// ++ END ++ ///////////////////   BUILD HEADER DOM  ////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////// FORMATS /////////////////////////

        function numberWithCommas(x) {
            //TODO: Combind into one reg X - didnt have time

            if( x === 0 || typeof x === 'undefined' || x === '' || isNaN(x)){
                return x;
            }
            var c = parseFloat(x).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            c = c.replace("-", "");

            if( x < 0 ){
                c = "<span class='neg-num-color'>("+c+")</span>";
            }
            return c
        }



      /*  function floatWithCommas(x) {
            //TODO: Combind into one reg X - didnt have time
            if( x === 0 || typeof x === 'undefined' || x === '' || isNaN(x) ){
                return x;
            }
            var c = parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            c = c.replace("-", "");
            if( x < 0 ){
                c = "<span class='neg-num-color'>("+c+")</span>";
            }
            return c
        }
    */
    /* added on 1/3/2019  */
        function floatWithCommas(x) {
            //TODO: Combind into one reg X - didnt have time
            if( x === 0 || typeof x === 'undefined' || x === '' || isNaN(x) ){
                return x;
            }
            var parts = x.toString().split(".");
            var c;
            if(parts[1]){
                parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                parts[1] = parts[1] && parts[1].length>=10 ? parts[1].slice(0,10) : parts[1];
                c = parts.join(".");
                c = c.replace("-", "");
            }else{
                c = parseFloat(parts).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                c = c.replace("-", "");
            }

             if( x < 0 ){
                c = "<span class='neg-num-color'>("+c+")</span>";
             }
             return c;
        }


        function dateFormat(x) {
            if( isBlank(x) ){
                return "";
            }


            if(_.isObject(x) ){
                return x;
            }
            
            var format = base.dateFormats[0].value;

            if(x.includes("T")){
                return  moment.parseZone(x).format(format);
            }else{
                return moment(x, 'MM/DD/YYYY').format(format);
            }
        }



        function formatter(_data,_format,_rows,value){
            var formatString = _data;

            if(_format == 'numberWithCommas'){
                formatString = numberWithCommas(_data);
            }else if(_format == 'floatWithCommas') {
                formatString = floatWithCommas(_data);
            }else if(_format == 'date') {
                formatString = dateFormat(_data);
            }else if(_format == 'percentage') {
                formatString =  ( _data ) ?  _data + '%' : "" ;
            }else if(_format == 'batchSelect' &&  typeof _rows != 'undefined' ) {


                if(!_rows.ggNotEditable ){
                    formatString = (_data == "true") ? '<i class="gg-cell-check fa fa-check-square-o "><i/>' : '<i class="fa fa-square-o"><i/>';
                }else{
                    formatString =  '<i style="opacity:.2" class="gg-cell-check fa fa-square-o "><i/>';
                }

            }else if(_format == 'locked') {
                formatString = (_data == 1) ? '<i class="fa fa fa-lock fa-lg"><i/>' : '<i class=" fa fa-unlock fa-lg"><i/>';
            }else if(_format == 'checkbox') {
                /* 4/13/2020 checking the conditional checkbox */
                var isEdit=true;
                if(value.edit_rules!=null){
                    isEdit = base.buildDom.isRowColEditable(_rows,value.edit_rules)
                }

                if(isEdit && (_data!=null || _data != '')){
                    formatString = (_data == 1) ? '<i class="gg-cell-check gg-check-click fa fa-check-square-o fa-lg"><i/>' : '<i class="gg-check-click fa fa-square-o fa-lg"><i/>';
                }

            }else if(_format == 'color-error') {

              // formatString = (_data == 1) ? '<i class="fa fa-check" style="color:#1ca5cf; font-size:16px" aria-hidden="true"></i>' : '<i class="fa fa-circle-o" style="color:#f20b7f; font-size:16px" aria-hidden="true"></i>';

              if(_data == ""){
                // formatString = '<span style="background-color: #c52a1a;color: white;padding: 2px 12px;border-radius: 10px;"></span>';
              formatString = '<span ></span>';
   
            }else if(_data == "1"){
                    formatString = '<span style="background-color: #22992e;color: white;padding: 2px 12px;border-radius: 10px;">NO ERROR</span>';
                }else if(_data == "0" ){
                    formatString = '<span style="background-color: #c52a1a;color: white;padding: 2px 12px;border-radius: 10px;">ERROR</span>';

                }



            }else if(_format == 'color-dot') {

                // formatString = (_data == 1) ? '<i class="fa fa-check" style="color:#1ca5cf; font-size:16px" aria-hidden="true"></i>' : '<i class="fa fa-circle-o" style="color:#f20b7f; font-size:16px" aria-hidden="true"></i>';
  
                 if(_data == ""){
                    formatString = '<i class="fa fa-circle-o" style="color:#f20b7f; font-size:16px" aria-hidden="true"></i>';
                }else if(_data == "1"){
                    formatString = ' <i class="fa fa-check" style="color:#32cb33; font-size:16px" aria-hidden="true"></i>';
                }else if(_data == "0" ){
                    formatString = '<i class="fa fa-times-circle" style="color:#f20b7f; font-size:16px" aria-hidden="true"></i>';
 
                }
  
  
  
              }

            return formatString;
        }




///////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////   BUILD DOM    ///////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////// WE WILL MOVE ALL GLOBAL BUILD FUNCTION INTO base.buildDom


        base.buildDom = {
            visible: true,




            batchRowSelectable: function (_dataArray) {
                if( options.data.settings.batch_select === 1 &&  options.data.settings.batch_select_rules ){
                    _.forEach(_dataArray, function (_value, _key) {
                        _value.ggNotEditable = base.buildDom.isEditable(options.data.settings.batch_select_rules, _value);
                    })
                }
            },


            batchSelectable: function(_object) {
                if( options.data.settings.batch_select === 1 &&  options.data.settings.batch_select_rules ) {
                    _object.ggNotEditable = base.buildDom.isEditable(options.data.settings.batch_select_rules,_object)
                }
            },



            isRowColEditable: function(_object,_rule_object){
                return !this.isEditable(_rule_object,_object);
            },

            isEditable: function(_rule_object,_object){
                if(!_rule_object) return false;
                var andList = _rule_object.AND;
                var orList = _rule_object.OR;
                var andResultList = [];
                var orResultList=[];
                var andResult=true;
                var orResult=true;
                var checkCondition = function(value) {
                    var notEditable = false;
                    if(value.operator === "=" &&  value.value !==  _object[value.map]){
                        notEditable = true;
                    }else if(value.operator === "!=" && value.value ==  _object[value.map]){
                        notEditable = true;
                    }else if(value.operator === "<=" && value.value >=  _object[value.map]){
                        notEditable = true;
                    }else if(value.operator === ">=" && value.value <=  _object[value.map]){
                        notEditable = true;
                    }else if(value.operator === ">" && value.value <  _object[value.map]){
                        notEditable = true;
                    }else if(value.operator === "<" && value.value >  _object[value.map]){
                        notEditable = true;
                    }
                    return notEditable;
                }
                if(_.isArray(_rule_object)){
                    _.each(_rule_object,function(item){
                        andResultList.push(checkCondition(item));
                    });
                   return _.filter(andResultList).length > 0;;
                }
                if(andList && andList.length===0 && orList && orList.length===0){
                    return true;
                }
                if(andList){
                    _.each(andList,function(item){
                        andResultList.push(checkCondition(item));
                    });
                    andResult = _.filter(andResultList).length===andList.length;
                    if(andList.length && !andResult){
                        return false;
                    }
                }
                if(orList && orList.length){
                    let isEditable = false;
                    _.each(orList,function(item){
                        if(!isEditable){
                            isEditable = checkCondition(item);
                        }
                    });
                    if(isEditable){ //checkCondition() return notEditable value, if any one of OR condition is passed, return true---
                        return false;
                    }
                }
                if(andResult && orResult){
                    return true;
                }
            }
        },

        function debug(e) {
            //////////////////////////////////////////////console.log(e);
        }



        function iconFormater(_icon){
            var icon = "";

            if(_icon === "edit" ){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-pencil"></i></div>');
            }else if(_icon === "info"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-info-circle"></i></div>');
            }else if(_icon === "remove"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-times"></i></div>');
            }else if(_icon === "question"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-question-circle"></i></div>');
            }else if(_icon === "eye"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-eye"></i></div>');
            }else if(_icon === "folder"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-folder-open"></i></div>');
            }else if(_icon === "download"){
               icon = $('<div class="grid-download-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-cloud-download"></i></div>');
            }else if(_icon === "downloadFile"){
                icon = $('<div class="grid-download-file-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-cloud-download"></i></div>');
             }else if(_icon === "file"){
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-file"></i></div>');
             }
            else{
                icon = $('<div class="grid-action-btn grid-form-btn" action="" pramaters="" ><i class="fa fa-pencil"></i></div>');
            }
            return icon;

        }



        function buildBodyDom(){
            base.elements.ggleftcol.html("");
            base.elements.ggrightcol.html("");


            if(base.data.isCollapsed){
                base.data.displayArr = base.data.dataArr.slice(base.vDragg.startIndex, base.vDragg.endIndex);
            }
             else if(!_.isEmpty(base.data.pivotGroup) && (base.data && base.data.pivotGroup && (base.data.pivotGroup.groupRows.length>0 || base.data.pivotGroup.columnToRows.length>0))){

                base.data.displayArr = base.data.dataArr.slice(base.vDragg.startIndex, base.vDragg.endIndex);
            }else{
                base.data.displayArr  = base.data.dataArr.slice( base.vDragg.startIndex, base.vDragg.endIndex);
            }
            var rownum = 1*base.vDragg.startIndex;


            //TODO: REBUILD RENDER ENGINE: fix firefox scroll speed. Line below is major contributor to the slow speed
            // var order = _.findIndex(base.data.dataArr, { object_id: value.object_id }) + 1;
            // next is the $.each loops and ifs

            $.each(base.data.displayArr, function( index, value ) {
                // var order = _.findIndex(base.data.dataArr, { object_id: value.object_id }) + 1;
                rownum++;
                buildRow(value,rownum);

            });

            base.renderSelected.renderSelected();
        }



        function buildRow(_object,_rownum){


            var formBtn = $('<div class="grid-action-btn grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-pencil "></i></div>');
            var infoBtn = $('<div class="grid-action-btn grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-info-circle "></i></div>');
            var commentsBtn =  $('<div class="grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-comment-o"></i></div>');
            var attachmentsBtn =  $('<div class="grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-paperclip"></i></div>');
            //var drillDownBtn =  $('<div class="grid-drilldown-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
            var row_class = "";
            var col_class= "";


            if( _object.row_class && _object.row_class.length){
                row_class = _object.row_class;
            }

            if(_object.col_class && _object.col_class.length){
                col_class = _object.col_class;
            }

            /*
             if( _.find(base.selectedGroup,{'row_id':_object.object_id, 'select_type':'row' }) ){
             row_class += row_class + ' selected-row';
             }
             */


            if( _.find(base.batchSelect,{'row_id':_object.object_id }) ){
                row_class += row_class + ' batch-row';
            }


            var row = $('<div  rowObj="'+_object.object_id+'" class="div-row  '+row_class+'"></div>'),
                rowFixed = $('<div  rowObj="'+_object.object_id+'" class="div-row '+row_class+'"></div>')
                ,index = 1;

            if(!_.isEmpty(base.data.pivotGroup)){
                row = $('<div  rowObj="'+_object.object_id+'" class="div-row" groupId="'+_object.group_id+'"></div>'),
                rowFixed = $('<div  rowObj="'+_object.object_id+'" class="div-row"  groupId="'+_object.group_id+'"></div>')
                ,index = 1;

            } 

            ///// BUILD FIXED COLS ROWS ///////////////////////////////////////////////////////////////////
            $.each( base.data.fixedHeaderArrayDisplay , function( key, value ) {
                var drillDownBtn =  $('<div class="grid-drilldown-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
                var linkBtn =  $('<div class="grid-link-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
                var obj_id = _object.object_id+'-'+value.map;

                var isExpand = _object.isCollapsed ? "fa-chevron-down" : "fa-chevron-right";
                //DATA FORMATTER ////////////////////////////////////////////////////////////////////////////////////
                var dataValue = ( _object[value.map] == undefined ? "" : sanitize(_object[value.map]));


                if(value.format && value.format.length){
                    dataValue = formatter(dataValue,value.format,_object,value);
                }


                //BUILD DOM ////////////////////////////////////////////////////////////////////////
                if(value.map && value.map == "group" && dataValue != ""){
                    if(_object.children.length>0){
                        var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'" parent="'+_object.parent_id+'"class="div-col" style="padding-left: '+_object.padLeft+'px"><div class="div-col-data">'+
                            '<i class="custom-fa-class fa '+isExpand+'" style="cursor:pointer; padding-right: 3px; font-size: 13px;"></i>'
                              +_object.label+' ('+_object.children.length+')'+'</div></div>')
                    }
                    else{
                        var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'" parent="'+_object.parent_id+'"class="div-col" style="padding-left: '+_object.padLeft+'px"><div class="div-col-data">'+
                            dataValue+'</div></div>')
                    }
                }else{
                    
                }
                if( index == 1){
                    var rowHeightDom = "<div class='row-resize-handle'  ></div>"
                    var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'" colObj="'+value.map+'"class="div-col-prime"><div class="div-col-data">'+_rownum+'</div></div>')
                    col.append(rowHeightDom);
                }else{
                    var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'"class="div-col"><div class="div-col-data">'+dataValue+'</div></div>')
                }

                if(value.map && value.map == "group" && dataValue != ""){
                    var rowHeightDom = "<div class='row-resize-handle'  ></div>"

                    if(_object.children.length>0){
                        var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'" parent="'+_object.parent_id+'"class="div-col" style="padding-left: '+_object.padLeft+'px"><div class="div-col-data">'+
                            '<i class="custom-fa-class fa '+isExpand+'" style="cursor:pointer; padding-right: 3px; font-size: 13px;"></i>'
                              +_object.label+' ('+_object.children.length+')'+'</div></div>');
                              col.append(rowHeightDom);

                    }
                    else{
                        var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'" parent="'+_object.parent_id+'"class="div-col" style="padding-left: '+_object.padLeft+'px"><div class="div-col-data">'+
                            dataValue+'</div></div>')
                    }
                }

                //buildBodyDom
                // CELL CLASS/////////////////////////////////////////////
                if( base.cellData[obj_id]) {
                    col.addClass(base.cellData[obj_id].cssClass);
                }else if( value.col_class){
                    col.addClass(value.col_class);
                }


                // tagsS ///////////////////////////////////////////////////

                // //////////console.log("base.data.messageObject[obj_id]: " , base.data.messageObject[obj_id] , " " , obj_id)

                if(base.data.messageObject[obj_id]){
                    var tags = '<div class="gg-tag-icon"><div>';
                    col.addClass('gg-hot-spot');
                    col.append(tags);
                }


                if(base.data && base.data.attachmentObject &&  base.data.attachmentObject[obj_id]){
                    var tags = '<div class="gg-tag-attachment"><div>';
                    col.addClass('gg-hot-spot');
                    col.append(tags);
                }

                if(base.data && base.data.auditCellsObject &&  base.data.auditCellsObject[obj_id]){
                    var tags = '<div class="gg-tag-audit"><div>';
                    col.append(tags);
                }

                /*highlight cell if we have an error   */

                 if(base.data.errorObject && base.data.errorObject[obj_id]){
                    var tags = '<div class="gg-tag-error"><div>';
                    col.addClass('gg-hot-spot');
                 //   col.addClass('gg-error-spot');
                    col.append(tags);
                }

                 /* 9/8/2019  conditional drilldown symbol*/
                 if(value.drilldown && value.drilldown !== null && value.apply_rules == 1  && value.drilldown_rule == 1){
                    var x = base.buildDom.isEditable(value.edit_rules, _object);
                    if(x){
                        col.prepend(drillDownBtn);
                        col.addClass('drillDown');
                    }
                }else if(value.drilldown){
                    col.prepend(drillDownBtn);
                    col.addClass('drillDown');
                }

                if( value.link ){
                    col.append(linkBtn);
                    col.addClass('link');
                }


                if( value.editable === 'y' ){
                    if(  typeof value.edit_rules == "undefined" || base.buildDom.isRowColEditable(_object,value.edit_rules)  ) {
                        if (value.edit_type === 'form' && value.call_back) {
                            col.append(iconFormater(value.edit_icon))
                        }
                        var editIcon = '<div class="gg-edit-icon"><div>';
                        col.append(editIcon);
                    }
                }






                if( value.call_back && value.edit_type === 'comments'){
                    col.append(commentsBtn);
                }


                col.css('width',value.width+'px');
                rowFixed.append(col);

                if(_object.height ){
                    rowFixed.height(_object.height);
                }else{
                    rowFixed.height(base.options.defaultRowHeight);
                }

                index++;
            });

            ///// BUILD H-SCROLL COLS ROWS ///////////////////////////////////////////////////////////////////
            $.each( base.data.headerArrayDisplay , function( key, value ) {

                var formBtn = $('<div class="grid-action-btn grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-pencil "></i></div>');
                var infoBtn = $('<div class="grid-action-btn grid-form-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-info-circle "></i></div>');
                var obj_id = _object.object_id+'-'+value.map;
                var drillDownBtn =  $('<div class="grid-drilldown-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
                var linkBtn =  $('<div class="grid-link-btn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
                var downloadBtn = $('<div class="grid-download-actn" action="NEEDTOADD" pramaters="" ><i class="fa fa-link"></i></div>');
                var rowClass = "";

                var col_class= (_object.col_class && value.map=='error_msg')?_object.col_class:'';


                //DATA FORMATTER ////////////////////////////////////////////////////////////////////////////////////
                var dataValue = ( _object[value.map] == undefined ? "" : sanitize(_object[value.map]));

                if(value.format && value.format.length){

                    dataValue = formatter(dataValue,value.format,_object,value);
                }


              //  var col = $('<div obj_id="'+_object.object_id+'-'+value.map+'" rowObj="'+_object.object_id+'"  colObj="' +value.map+'" class="div-col"><div class="div-col-data">'+dataValue+'</div></div>')

              /*  MY CHAGES for highligh only  error_message cell if we have an error instead of highlight the entire  column
                    var col = $('<div obj_id="'+_object.object_id+'-'+value.map+'" rowObj="'+_object.object_id+'"  colObj="' +value.map+'" class="div-col '+col_class+'"><div class="div-col-data">'+dataValue+'</div></div>')
                    col.css('width',value.width+'px');
              */

              var col = $('<div obj_id="'+_object.object_id+'-'+value.map+'" rowObj="'+_object.object_id+'"  colObj="' +value.map+'" class="div-col"><div class="div-col-data">'+dataValue+'</div></div>')
              col.css('width',value.width+'px');

                if(value.map && value.map == "group" && dataValue != ""){
                    if(_object.children.length>0){
                        var col = $('<div obj_id="'+obj_id+'" rowObj="'+_object.object_id+'"  colObj="'+value.map+'" parent="'+_object.parent_id+'"class="div-col" style="padding-left: '+_object.padLeft+'px"><div class="div-col-data">'+
                            '<i class="custom-fa-class fa '+isExpand+'" style="cursor:pointer; padding-right: 3px; font-size: 13px;"></i>'
                            +_object.label+' ('+_object.children.length+')'+'</div></div>');
                            col.css('width',value.width+'px');

                    }
                }

                if(base.data.messageObject[obj_id]){
                    var tags = '<div class="gg-tag-icon"><div>';
                    col.addClass('gg-hot-spot');
                    col.append(tags);
                }
                 /* highlight cell if we have an attachment */

                if(base.data && base.data.attachmentObject &&  base.data.attachmentObject[obj_id]){
                    var tags = '<div class="gg-tag-attachment"><div>';
                    col.addClass('gg-hot-spot');
                    col.append(tags);
                }

                if(base.data && base.data.auditCellsObject &&  base.data.auditCellsObject[obj_id]){
                    var tags = '<div class="gg-tag-audit"><div>';
                    col.append(tags);
                }
                /*highlight cell if we have an error   */

                if(base.data.errorObject && base.data.errorObject[obj_id]){
                    var tags = '<div class="gg-tag-error"><div>';
                    col.addClass('gg-hot-spot');
                   // col.addClass('gg-error-spot');
	                    col.append(tags);
                }



                /*

                 if( value.call_back && value.edit_type === 'form'){
                    if( typeof value.edit_validation == "undefined" || editValidate(_object,value.edit_validation)  ) {
                        col.append(formBtn);
                    }
                }
                 */
                 /* 9/8/2019  conditional drilldown symbol*/
               // if(_object.GGCELCOLORTOTAL == 1 && value.drilldown && value.drilldown !== null && value.apply_rules == 1){
                if(value.drilldown && value.drilldown !== null && value.apply_rules == 1 && value.drilldown_rule == 1){
                    var x = base.buildDom.isEditable(value.edit_rules, _object);
                    if(x){
                        col.prepend(drillDownBtn);
                        col.addClass('drillDown');
                    }
                 }else if( value.drilldown){
                     col.prepend(drillDownBtn);
                     col.addClass('drillDown');
                 }

                if( value.link ){
                    col.append(linkBtn);
                    col.addClass('link');
                }


                if( value.editable === 'y' ){
                    if(  typeof value.edit_rules == "undefined" || base.buildDom.isRowColEditable(_object,value.edit_rules)  ) {
                        if (value.edit_type === 'form' && value.call_back) {
                            col.append(iconFormater(value.edit_icon));
                        }
                        var editIcon = '<div class="gg-edit-icon"><div>';
                        col.append(editIcon);
                    }
                }


              /*   if(value.drilldown && value.drilldown !== null ){
                    col.prepend(drillDownBtn);
                    col.addClass('drillDown');
                }

              */

                /* 9/8/2019  conditional drilldown symbol*/
                if(value.drilldown && value.drilldown !== null && value.apply_rules == 1  && value.drilldown_rule == 1){
                    var x = base.buildDom.isEditable(value.edit_rules, _object);
                    if(x){
                        col.prepend(drillDownBtn);
                        col.addClass('drillDown');
                    }
                }else if( value.drilldown){
                    col.prepend(drillDownBtn);
                    col.addClass('drillDown');
                }
                if( value.link ){
                    col.append(linkBtn);
                    col.addClass('link');
                }


                if( value.call_back && value.edit_type === 'comments'){
                    col.append(commentsBtn);
                }


                if( base.cellData[obj_id]) {
                    col.addClass(base.cellData[obj_id].cssClass);
                }else if( value.col_class){
                    col.addClass(value.col_class);
                }

                row.append(col);


                if(_object.GGROWCOLORTOTAL == 1){
                    row.addClass("ggTotalRow");
                    rowFixed.addClass("ggTotalRow");
                }
                if(_object.GGROWCOLORSUBTOTAL == 1){
                    row.addClass("ggTotalSubRow");
                    rowFixed.addClass("ggTotalSubRow");
                }



                if(_object.height ){
                    row.height(_object.height);
                }else{
                    row.height(base.options.defaultRowHeight);
                }

            });

            base.elements.ggleftcol.append(rowFixed);
            base.elements.ggrightcol.append(row);

        }

        base.$el.on('click', '.custom-fa-class' ,function(event){
            console.log("selected group ",base.selected);
            var filtered = [];
            var baseArray = base.data.dataArr;
            var safeCopyArray = _.clone(options.data.dataArr);

            function collapseExpand(baseArray){
                   /* collapse */
                   var tt = [];
                   if(base.selected.rowData.isCollapsed){
                    var filteredArray = [];
                        var removeChildren = function(dataChildren){                          
                            if(!dataChildren[0].hasOwnProperty('level') || dataChildren[0].children.length == 0){
                                baseArray =  _.differenceBy(baseArray,dataChildren,'object_id');
                            }
                            else{
                                _.each(dataChildren,function(ic){
                                    if(ic.children && ic.children.length>0){
                                        ic.isCollapsed = !ic.isCollapsed;
                                        var chil = ic.children; 
                                        baseArray =  _.filter(baseArray,function(rr){return rr.object_id != ic.object_id});

                                        removeChildren(chil);                                       
                                    }
                                })
                            }                            
                            
                        }
                        removeChildren(base.selected.rowData.children);
                        base.selected.rowData.isCollapsed = !base.selected.rowData.isCollapsed;
                        base.data.dataArr = baseArray;
                   }else
                   {
                        _.each(baseArray,function(item){ /*  expanding */                        
                                if(base.selected.rowData && base.selected.rowData.children && base.selected.rowData.children.length
                                    && base.selected.rowData.label == item['label'] && base.selected.rowData.level == item.level
                                    && base.selected.rowData.object_id == item['object_id']){
                                    if((item.isCollapsed == undefined || item.isCollapsed == false )
                                        ){
                                            filtered.push(item);
                                            item.isCollapsed = true;
                                            _.each(base.selected.rowData.children,function(ii){
                                                ii.isCollapsed = ii.isCollapsed ? !ii.isCollapsed: ii.isCollapsed;
                                                filtered.push(ii);
                                            });
                                        
                                    }else{
                                        item.isCollapsed = !item.isCollapsed;
                                        filtered.push(item);
                                    }
                            }else{
                                filtered.push(item);
                            }
                        });
                        base.data.dataArr = filtered;

                   }
                   
            }
            collapseExpand(baseArray);
            base.data.safeCopyArr = base.data.dataArr;
            options.data.dataArr = base.data.safeCopyArr;
           // base.data.isCollapsed = true;
            buildGrid();
        })

          /* pivot expand / collpase  7/30/2020*/
          base.pivotExpandService = {
            expandCollapse: function(){
                var safeCopyArr = _.clone(options.data.dataArr);
                
                let expandCollapsedArray = [];
               if( base.data && base.data.pivotGroup && base.data.pivotGroup.groupRows && base.data.pivotGroup.groupRows.length>0 && !base.data.isCollapsed){
                    _.each(safeCopyArr,function(item){
                                item.isCollapsed = true;
                                expandCollapsedArray.push(item);
                                var expandChildren = function(children){
                                    _.each(children,function(ii){

                                        if(ii.children && ii.children.length>0){
                                            ii.isCollapsed = true;
                                            expandCollapsedArray.push(ii);

                                            expandChildren(ii.children);
                                        }else{
                                            expandCollapsedArray.push(ii);

                                        }
                                    })

                                }
                                if(item.children && item.children.length>0){
                                    expandChildren(item.children);
                                }
                            });
                  //  base.data.dataArr = a;
                  base.data.dataArr = expandCollapsedArray;
                    base.data.isCollapsed = !base.data.isCollapsed
               }
                else if(base.data && base.data.pivotGroup && base.data.pivotGroup.groupRows && base.data.pivotGroup.groupRows.length>0){
                   /*  var a = _.each(safeCopyArr,function(item){
                                if(item.isCollapsed){
                                    item.isCollapsed = !item.isCollapsed;
                                }
                            });
                    var filtered = _.filter(a,{level:0});
                    base.data.dataArr = filtered;
                   base.data.isCollapsed = !base.data.isCollapsed */
                   _.each(safeCopyArr,function(item){
                        var removeChildren = function(dataChildren){                          
                            if(!dataChildren[0].hasOwnProperty('level') || dataChildren[0].children.length == 0){
                                safeCopyArr =  _.differenceBy(safeCopyArr,dataChildren,'object_id');
                            }
                            else{
                                _.each(dataChildren,function(ic){
                                    if(ic.children && ic.children.length>0){
                                        ic.isCollapsed = !ic.isCollapsed;
                                        var chil = ic.children; 
                                        safeCopyArr =  _.filter(safeCopyArr,function(rr){return rr.object_id != ic.object_id});
                                        removeChildren(chil);                                       
                                    }
                                })
                            }                            
                            
                        }
                        if(item.children && item.children.length>0){
                            item.isCollapsed = false;
                            removeChildren(item.children);
                        }else{
                            item.isCollapsed = false;
                        }

                     })
                     base.data.isCollapsed = !base.data.isCollapsed;
                   base.data.safeCopyArr = safeCopyArr;
                   base.data.dataArr = safeCopyArr;
                }
               buildGrid();
            }
        }


	    base.$el.on('change', '#pivotCheck' ,function(event){
            base.selVal = this.checked;
            this.checked ? base.pivotChecked = "enabled" : base.pivotChecked = "disabled";
            buildGrid();
        })
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////// Drop Down Actions ////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //$(document.body).on('click', '.gg-menu-action' ,function(){

        //_object[value.map]



        function dropdownAction(action,element,input,curDataCol,map){

            switch (action) {


                case 'copyAddRow':
                    var row_id =  base.selected.row_id;
                    var index = _.findIndex(base.data.safeCopyArr, { 'object_id':  base.selected.row_id });
                    base.edit.copyAddRow(index);
                    break;
                case 'addRowBelow':
                    var row_id =  base.selected.row_id;
                    var index = _.findIndex(base.data.safeCopyArr, { 'object_id':  base.selected.row_id });
                    base.edit.addRow(index,'below');
                    break;
                case 'addRowAbove':
                    var row_id =  base.selected.row_id;
                    var index = _.findIndex(base.data.safeCopyArr, { 'object_id':  base.selected.row_id });
                    base.edit.addRow(index,'above');
                    break;
                case 'deleteRow':
                    var row_id =  base.selected.row_id;
                    var index = _.findIndex(base.data.safeCopyArr, { 'object_id':  base.selected.row_id });
                    base.edit.deleteRow(index,'above');
                    break;
                case 'editCell':
                    base.edit.editCell('',false);
                    break;
                case 'addCol':
                    var toMove = _.findIndex(base.data.headerArraySafe, { 'object_id': curDataCol.object_id });
                    base.headerService.addCol("AA","string",toMove+1);
                    break;
                case 'removeCol':
                    var toRemove = _.findIndex(base.data.headerArraySafe, { 'object_id': curDataCol.object_id });
                    base.headerService.removeCol(toRemove);
                    break;
                case 'formatCol':
                    var template = '<lable>Column Name</lable><input class="input-sm form-control gg-modal-input" type="text" name="columnName" value="'+curDataCol.label+'">'
                    template += base.headerService.buildFormatSelection();
                    base.ModalService.openModalService("Format Column ",curDataCol.map,'formatCol',template);
                    base.ModalService.positionModal();
                    break;
                case 'fill':
                    base.headerService.fill(input,curDataCol.object_id);
                    break;
                case 'hideCol':
                    break;
                case 'highlightCol':
                    highlight(curDataCol,base.tools.color);
                    break;
                case 'highlight':
                    var color = element.attr('color');
                    highlight(element,color)
                    break;
                case 'sort-a-z':
                    sortBy(curDataCol.map,curDataCol.label,false);
                    break;
                case 'sort-z-a':
                    sortBy(curDataCol.map,curDataCol.label,true);
                    break;
                case 'un-sort':
                    unSort();
                    break;
                case 'find-like':
                    colSearchText(curDataCol.map,input,':','string' );
                    break;
                case 'find-not-like':
                    colSearchText(curDataCol.map,input,'!:','string' );
                    break;
                case 'find-equals-number':
                    colSearchText(curDataCol.map,input,'=','numeric' );
                    break;
                case 'find-not-equals-number':
                    colSearchText(curDataCol.map,input,'!=','numeric' );
                    break;
                case 'find-not-equal':
                    colSearchText(curDataCol.map,input,'!=','string' );
                    break;
                case 'find-greater':
                    colSearchText(curDataCol.map,input,'>=','numeric' );
                    break;
                case 'find-less':
                    colSearchText(curDataCol.map,input,'<=','numeric' );
                    break;
                case 'un-filter':
                    unFilter(map);
                    break;
                case 'blank':
                    colSearchText(curDataCol.map,'','blank','string' );
                    break;
                case 'not-blank':
                    colSearchText(curDataCol.map,'','!blank','string' );
                    break;
                case 'un-select-all':
                    base.removeBatchSelection = true;
                    base.batch.unselectAll();
                    break;
                case 'select-all':
                    base.batch.selectAll();
                    break;
                case 'select-row':
                    base.batch.selectRow();
                    break;
                case 'batch-action':
                    base.api.batchAction(element.attr("value"));
                    break;
                case 'add-comment':
                    base.messageService.renderAddMessageTemplate();
                    break;
                case 'add-attachment':
                    base.messageService.addAttachment();
                    break;
                case 'batchSelectAll':
                    base.batch.selectAll();
                    break;
                case 'batchUnSelect':
                    base.batch.unselectAll();
                    break;
                case 'audit-cells':
                    base.messageService.auditCellsModal();
                    break;
            }

            base.focus.gridBody.focus();

        };









        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////   GLOBAL SEARCH  ///////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        var throttledTextInput = _.debounce(function(_filterText){
            base.tableState.search.globalValue = _filterText;
            options.data.tableState.vScroll = {};
            pipe();
        }, 400);


        function globalSearch(_value){
            throttledTextInput(_value)
        };

        base.$el.find('.gg-clear-search').on('click', function(){
            base.elements.globalSearch.val("");
            throttledTextInput("");
        });

       /* options.data.tableState.vScroll : resetting this option to EMPTY if we made any search/sort/filter 6/10/2021*/ 


        function clearAll(){
            base.elements.globalSearch.val("");
            base.tableState.sort = {};
            base.tableState.search = {};
            base.tableState.colSearch = [];
            base.tableState.attachments = false;
            base.tableState.comments = false;
            base.tableState.auditCells = false;
            base.messageService.toggleCommentFilterIcon();
            base.messageService.toggleAttachmentFilterIcon();
            options.data.tableState.vScroll = {};
            if(base.data && base.data.dataArr.length>=0){
                pipe();
            }

        }

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////   SORT FILTER //////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function sort(e){
            var col_id = $(e.currentTarget).closest(".headerCol").attr('colobj');
            var curDataCol = _.find(base.data.headerArray, {'object_id':col_id});

            if( base.tableState.sort.predicate &&  base.tableState.sort.predicate ===  curDataCol.map){
                if( base.tableState.sort.reverse == true ){
                    unSort();
                }else if( base.tableState.sort.reverse == false) {
                    sortBy(curDataCol.map,curDataCol.label,true,curDataCol.data_type);
                }
            }else{
                sortBy(curDataCol.map,curDataCol.label,false,curDataCol.data_type);
            }

            e.stopPropagation();

        };


        function unSort(){
            base.tableState.sort = {};
            options.data.tableState.vScroll = {};
            pipe();
        }

        function unFilter(_map){
            /* if we clear the gridaction make template selection false  3/4/2020*/
            _.each(base.actionTemplates, function(item){
                if(item.map == _map){
                    if(item.selectedAction){
                        item.selectedAction = false;
                        item.map = '';
                    }
                    base.actionsService.clearSelectedTemp();
                }

            })
            options.data.actionTemplates = base.actionTemplates;

            var index = _.findIndex(base.tableState.colSearch, { predicate:_map });
            if (index > -1) {
                base.tableState.colSearch.splice(index, 1);
            }
            options.data.tableState.vScroll = {};
            pipe();
        }


        function colSearchText(_key,_value,_operator,_type){

            //MAKE SURE ONLY ON VALUE PER KEY
            var index = _.findIndex(base.tableState.colSearch, { predicate:_key });
            if (index > -1) {
                base.tableState.colSearch.splice(index, 1);
            }
            var searchObj = {};
            searchObj.predicate = _key;
            searchObj.value = _value;
            searchObj.type = _type
            searchObj.operator = _operator
            base.tableState.colSearch.push(searchObj);
            options.data.tableState.colSearch = base.tableState.colSearch; /* 3/4/2020 */
            options.data.tableState.vScroll = {};
            pipe();
        }


        function colSearchMultiFilter (_key,_value,_operator,_type,_reverse){
            var index = _.findIndex(base.tableState.colSearch, { predicate:_key });

            if (index > -1) {
                base.tableState.colSearch.splice(index, 1);
            }

            // Change 'undefined' to undefined
            // Heath
            // object:uniqueFilters method:removeSelected - this function will also Change 'undefined' to undefined
            // This is used to find any undefined values.
            // _value will pass 'undefined' so we need to change this to undefined to find any undefined values in base.data.safeCopyArr

            var indexArray = _value.indexOf("undefined");
            if (indexArray !== -1) {
                _value[indexArray] = undefined;
            }
            // Change 'null' to null
            // Divya
            // object:uniqueFilters method:removeSelected - this function will also Change 'null' to null
            // This is used to find any null values.
            // _value will pass 'null' so we need to change this to null to find any null values in base.data.safeCopyArr

            var indexNullArr = _value.indexOf("null");
            if(indexNullArr !== -1){
                _value[indexNullArr] = null;
            }

            var searchObj = {};
            searchObj.predicate = _key;
            searchObj.value = _value;
            searchObj.type = _type
            searchObj.operator = _operator
            searchObj.reverse = _reverse
            base.tableState.colSearch.push(searchObj);
            options.data.tableState.colSearch = base.tableState.colSearch; /* 3/4/2020 */
            options.data.tableState.vScroll = {};
            pipe();

        }

        //SORT BY KEY
        function sortBy(_key,_label,_reverse,_dataType){
            base.tableState.sort.predicate = _key;
            base.tableState.sort.lable = _label;
            base.tableState.sort.reverse = _reverse === true;
            base.tableState.sort.dataType = _dataType;
            options.data.tableState = base.tableState /* 3/4/2020 */
            options.data.tableState.vScroll = {};
            pipe();
        }

        function pipe(){

            var filtered =  base.data.safeCopyArr;



            console.log(" base.tableState  " , base.tableState);


            if( base.tableState.colSearch && base.tableState.colSearch.length ){
                _.each(base.tableState.colSearch, function(model){


                    if( model.operator  === "blank" || model.operator  === "!blank"  ){

                        if(model.operator  === "blank" ){
                            var array = [];
                            array.push(undefined);
                            filtered = _.findByValues(filtered, model.predicate ,array);
                        }else{
                            filtered =   _.filter(filtered, function(o) {
                                if( typeof  o[model.predicate] !== "undefined" &&  o[model.predicate] !== null &&  o[model.predicate] !== "" ){
                                    return o;
                                }
                            });
                        }


                    }else if( ( model.type  === 'numeric' || model.type  === 'number')  && model.predicate.length ){
                        filtered =   _.findNumByProperty(filtered,model.predicate,model.value,model.operator)
                    }else if(model.type === 'string' && model.predicate.length){

                        if( model.operator === ':' ){
                            filtered =  _.findByKeyPartialValue(filtered,model.value,model.predicate);
                        }else if( model.operator === '!:' ){
                            filtered =  _.findNotByKeyPartialValue(filtered,model.value,model.predicate);
                        }else if( model.operator === '=' ){
                            var obj = [];
                            obj[model.predicate] = model.value;
                            filtered =  _.filter(filtered,{ '+model[predicate]+': model.value });
                        }else if( model.operator === '!=' ){

                        }else{
                            filtered =  _.findByKeyPartialValue(filtered,model.value,model.predicate);
                        }

                    }else if( model.type === 'array' &&  model.value.length  ){

                        if(model.reverse){
                            filtered = _.findByValuesNot(filtered, model.predicate , model.value);
                        }else{
                            filtered = _.findByValues(filtered, model.predicate , model.value);
                        }
                    }
                });

                /* if block is added to get the scroll position while switcing the tabs, options.data.tableState.vScroll*/
                if( !_.isEmpty(options.data.tableState.vScroll)){
                    base.vDragg.draggerPositionY = options.data.tableState.vScroll.draggerPositionY;
                    base.vDragg.scrollPositionY = options.data.tableState.vScroll.scrollPositionY;
                }else{
                    base.vDragg.draggerPositionY = 0;
                    base.vDragg.scrollPositionY = 0;
    
                }
                
            }

            if(  base.tableState.search && base.tableState.search.globalValue){
                filtered =  _.findByPartialValue(filtered,base.tableState.search.globalValue);
                if( !_.isEmpty(options.data.tableState.vScroll)){
                    base.vDragg.draggerPositionY = options.data.tableState.vScroll.draggerPositionY;
                    base.vDragg.scrollPositionY = options.data.tableState.vScroll.scrollPositionY;
                }else{
                    base.vDragg.draggerPositionY = 0;
                    base.vDragg.scrollPositionY = 0;
    
                }
            }

            if( base.tableState.sort && base.tableState.sort.predicate ){

                if( base.tableState.sort.dataType === 'date') {
                    if (base.tableState.sort.reverse === true) {
                        filtered =_.sortBy(filtered, function(value) {
                            var dateString = ( value[base.tableState.sort.predicate] === undefined ? null : value[base.tableState.sort.predicate]);
                            return new Date(dateString);
                        }).reverse();
                    } else {
                        filtered = filtered =_.sortBy(filtered, function(value) {
                            var dateString = ( value[base.tableState.sort.predicate] === undefined ? null : value[base.tableState.sort.predicate]);
                            return new Date(dateString)
                        });
                    }
                }else{
                    if (base.tableState.sort.reverse === true) {
                        filtered = _.sortBy(filtered, base.tableState.sort.predicate).reverse();
                    } else {
                        filtered = _.sortBy(filtered, base.tableState.sort.predicate);
                    }
                }
            }




            if( base.tableState.comments){
                var row_key_arr = [];
                _.forEach( base.data.messageObject, function(value, key) {
                    row_key_arr.push(value.row_key)
                })

                if(row_key_arr.length && filtered.length){
                    if( !_.isEmpty(options.data.tableState.vScroll)){
                        base.vDragg.draggerPositionY = options.data.tableState.vScroll.draggerPositionY;
                        ase.vDragg.scrollPositionY = options.data.tableState.vScroll.scrollPositionY;
                    }else{
                        base.vDragg.draggerPositionY = 0;
                        base.vDragg.scrollPositionY = 0;
        
                    }
                    filtered = _.findByValues(filtered, 'object_id' , row_key_arr);
                }else{
                    addAlert("alert-warn"," There are no comments to filter on",4000 );
                }
            }

             /* added by Divya fiter the attachments 9/22/2019*/
            if( base.tableState.attachments){
                var row_key_arr1 = [];
                _.forEach( base.data.attachmentObject, function(value, key) {
                    row_key_arr1.push(value.row_key)
                })

                if(row_key_arr1.length && filtered.length){
                    if( !_.isEmpty(options.data.tableState.vScroll)){
                        base.vDragg.draggerPositionY = options.data.tableState.vScroll.draggerPositionY;
                        base.vDragg.scrollPositionY = options.data.tableState.vScroll.scrollPositionY;
                    }else{
                        base.vDragg.draggerPositionY = 0;
                        base.vDragg.scrollPositionY = 0;
        
                    }
                     filtered = _.findByValues(filtered, 'object_id' , row_key_arr1);
                }else{
                    addAlert("alert-warn"," There are no attachments to filter on",4000 );
                }
            }
	    if(base.tableState.auditCells){
                var row_key_arr1 = [];
                _.forEach( base.data.auditCellsObject, function(value, key) {
                    row_key_arr1.push(value.row_key)
                })

                if(row_key_arr1.length && filtered.length){
                     base.vDragg.draggerPositionY = 0;
                     base.vDragg.scrollPositionY = 0;
                     filtered = _.findByValues(filtered, 'object_id' , row_key_arr1);
                }else{
                    addAlert("alert-warn"," There are no overrides to filter on",4000 );
                } 
            }

            base.data.dataArr = filtered;
            var displayString = "Total Records:<b> " +base.data.safeCopyArr.length+ "</b> | Filtered: <b>"+base.data.dataArr.length+"</b>";
            if(options.data.pivotGroup && options.data.pivotGroup.groupRows && options.data.pivotGroup.groupRows.length>0 ||
                options.data.pivotGroup && options.data.pivotGroup.columnToRows && options.data.pivotGroup.columnToRows.length>0){
                    displayString = "Total Records:<b> " +options.data.totalRecords+ "</b> | Group or Pivot Rows: <b>"+base.data.safeCopyArr.length+"</b> | Filtered: <b>"+base.data.dataArr.length+"</b>"
            }
            base.elements.recordDetails.html(displayString);
            setFooterDetails(base.data.safeCopyArr.length,base.data.dataArr.length)
            buildFilterFunctions();
            buildGrid()
        }

///////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////  START FOOTER UI FILTER RENDERING  ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////


        function buildFilterFunctions(){
            base.elements.footerContainer.html("");
            var clearAll = '<div class="gg-clear-all"><i class="fa fa-times"></i></div>';
            var item = '<div class="filter-item"> A-Z <div class="gg-clear-item"><i class="fa fa-times"></i></div></div>';
            var showClearAll = false;

            // TableState Global Search
            if( base.tableState.search && base.tableState.search.globalValue){
                var item = '<div class="filter-item"> Global Search: '+sanitize(base.tableState.search.globalValue)+' <div class="gg-clear-item" action="globalSearch"><i class="fa fa-times"></i></div></div>';
                base.elements.footerContainer.append(item);
                showClearAll = true;
            }


            // Comment Filter
            if( base.tableState.comments){
                var item = '<div class="filter-item"> Comment Rows: True  <div class="gg-clear-item" action="comments"><i class="fa fa-times"></i></div></div>';
                base.elements.footerContainer.append(item);
                showClearAll = true;
            }

             /* attachment Filter --added by Divya 9/22/2019 */
             if( base.tableState.attachments){
                var item = '<div class="filter-item"> Attachment Rows: True  <div class="gg-clear-item" action="attachments"><i class="fa fa-times"></i></div></div>';
                base.elements.footerContainer.append(item);
                showClearAll = true;
            }
            /* audit Filter --added by Divya 5/31/2021 */
            if( base.tableState.auditCells){
                var item = '<div class="filter-item"> Override Rows: True  <div class="gg-clear-item" action="celloverrides"><i class="fa fa-times"></i></div></div>';
                base.elements.footerContainer.append(item);
                showClearAll = true;
            }

            // TableState Column Search
            if( base.tableState.colSearch.length ){
                _.each(base.tableState.colSearch, function(model){
                    var columnName = _.map(_.filter(base.data.headerArray, { 'map': model.predicate }), 'label');
                    var displayValue = model.value.toString();
                    if(displayValue.length > 50) {
                        displayValue = displayValue.substring(0,45)+"...";
                    }
                    var item = '<div class="filter-item"> '+sanitize(columnName)+ ' ' +model.operator+ ' ' +sanitize(displayValue)+' <div class="gg-clear-item" action="search" map="'+model.predicate+'"><i class="fa fa-times"></i></div></div>';
                    base.elements.footerContainer.append(item);
                    showClearAll = true;
                });
            }


            // TableState Column Search
            if( base.tableState.sort && base.tableState.sort.predicate){

                var sortString = "A <i class='fa fa-long-arrow-right'></i> Z";
                if( base.tableState.sort.reverse ){
                    sortString = "Z <i class='fa fa-long-arrow-right'></i> A"
                }

                var item = '<div class="filter-item"> '+base.tableState.sort.lable+ ' : '+sortString+ '<div class="gg-clear-item" action="sort"><i class="fa fa-times"></i></div></div>';
                base.elements.footerContainer.append(item);
                showClearAll = true;
            }

            if(showClearAll) {
                base.elements.footerContainer.prepend(clearAll);
                // base.elements.footerContainer.append(item)
            }
        }


        function clearFilter(_action,_map){
            if(_action == 'search'){
                unFilter(_map);
            }else if(_action == 'globalSearch'){
                base.tableState.search.globalValue = null;
                base.elements.globalSearch.val("");
            }else if(_action == 'sort'){
                base.tableState.sort = {};
            }else if( _action == 'comments'){
                base.tableState.comments = false;
                base.messageService.toggleCommentFilterIcon();
                /* added by Divya fiter the attachments 9/22/2019*/
            }else if(_action == 'attachments'){
                base.tableState.attachments = false;
                base.messageService.toggleAttachmentFilterIcon();
            }else if(_action == 'celloverrides'){
                base.tableState.auditCells = false;
                base.messageService.toggleOverrideCellstFilterIcon();
            }
            options.data.tableState.vScroll = {};
            pipe();
        }

        
        function setFooterDelete(_count){
            var string = _count === 1 ? '<b>' + _count + ' Row Removed' : '<b>' + _count + ' Rows Removed';
            base.elements.footerDelete.html(string)
            base.elements.footerDelete.addClass('gg-delete-count')
        }

        function setFooterDetails(_length,_filterLength){
            var string;
            if(_length > _filterLength) {
                string = "Total Records: <b>" + _length + "</b> Filterd: <b>" + _filterLength + "</b>";
                if(options.data.pivotGroup && options.data.pivotGroup.groupRows && options.data.pivotGroup.groupRows.length>0 ||
                    options.data.pivotGroup && options.data.pivotGroup.columnToRows && options.data.pivotGroup.columnToRows.length>0){
                        string = "Total Records:<b> " +options.data.totalRecords+ "</b> | Group or Pivot Rows: <b>"+_length+"</b>"
                        + "</b> | Filterd: <b>"+_filterLength+"</b>";
                }
            }else{
                string = "Total Records: <b>" + _length;
                if(options.data.pivotGroup && options.data.pivotGroup.groupRows && options.data.pivotGroup.groupRows.length>0 ||
                    options.data.pivotGroup && options.data.pivotGroup.columnToRows && options.data.pivotGroup.columnToRows.length>0){
                        string = "Total Records:<b> " +options.data.totalRecords+ "</b> | Group or Pivot Rows: <b>"+_length+"</b>";
                }
               
            }

           
            base.elements.footerDetails.html(string)
        }




        base.scroll = {
            trackHScroll: function(event){
                var offset =  base.elements.htrack.offset().left;
                var clickX = event.pageX - offset;
                var rightX = base.hDragg.draggerPositionX + base.hDragg.draggerWidth;
                var leftX = base.hDragg.draggerPositionX;


                if( clickX > rightX){
                    pageRight();
                }else if(clickX < leftX){
                    pageLeft();
                }


            },

            trackVScroll: function(event){
                var offset =  base.elements.vtrack.offset().top;
                var clickY = event.pageY - offset;
                var topY = base.vDragg.draggerPositionY;
                var bottomY = base.vDragg.draggerPositionY + base.vDragg.draggerHeight;


                if( clickY < topY){
                    pageUp();
                }else if(clickY > bottomY){
                    pageDown();
                }
            }

        }




///////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////  START H-SCROLL FUNCTIONS   ////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

        function buildHScrollBar(){
            //SET UP TRACK
            var left = getGridWidth(base.data.fixedHeaderArrayDisplay) +  base.elements.fixColHandler.width();
            base.elements.htrack.css('left',left+'px');

            if (options.data.settings.hasPivot == 1 && base.pivotChecked == "enabled") {
                var trackWidth = (base.$el.width() - left) - ( base.elements.hbtns.width() + base.elements.vtrack.width()) -270;
            } else if (options.data.settings.hasPivot == 1) {
                var trackWidth = (base.$el.width() - left) - ( base.elements.hbtns.width() + base.elements.vtrack.width()) -25;
            } else {
                var trackWidth = (base.$el.width() - left) - ( base.elements.hbtns.width() + base.elements.vtrack.width() );
            }
            base.elements.htrack.width(trackWidth);

            //SET UP WINDOW
            if(options.data.settings.hasPivot == 1 && base.pivotChecked == "enabled") {
                var scrollWindowWidth =  base.$el.width() - getGridWidth(base.data.fixedHeaderArrayDisplay) - base.elements.vtrack.width() - 270;
            } else if (options.data.settings.hasPivot == 1) {
                var scrollWindowWidth =  base.$el.width() - getGridWidth(base.data.fixedHeaderArrayDisplay) - base.elements.vtrack.width() - 25;
            } else {
                var scrollWindowWidth =  base.$el.width() - getGridWidth(base.data.fixedHeaderArrayDisplay) - base.elements.vtrack.width();
            }
            var containerWidth = base.elements.ggrightcol.width();
            var scrollWidth = containerWidth - scrollWindowWidth;
            var trackRatio = trackWidth / containerWidth;
            var draggerWidth = trackWidth * trackRatio;

            //IF GRID IS SMALLER THEN SCROLL AREA THEN DON'T SHOW GRID
            if( scrollWindowWidth > containerWidth  ){
                resetHScroll()
                draggerWidth = 0;
            }else{
                if( draggerWidth < base.options.vDraggerMinHeight ){
                    draggerWidth = base.options.vDraggerMinHeight;
                }
            }

            base.elements.hdragger.width(draggerWidth);
            trackWidth -= draggerWidth;
            trackRatio = trackWidth / scrollWidth;
            base.hDragg.scrollRatio = trackRatio;
            var scrollRatio = scrollWidth / trackWidth;
            base.hDragg.trackRatio = trackRatio;
            base.hDragg.draggerWidth = draggerWidth;
            base.hDragg.trackWidth = trackWidth - 3;
            base.hDragg.scrollRatio = scrollRatio;
            base.hDragg.scrollWidth = scrollWidth;
            base.hDragg.scrollWindowWidth = scrollWindowWidth;
            base.hDragg.offset = null;
            base.hDragg.maxStartIndex = findLastStartIndex()
            base.vDragg.maxStartIndex = findLastStartVIndex();

            drawHDragger();

        }

        // MOVE THIS FUNCTION

        function resetHScroll(){
            base.hDragg.draggerPositionX = 0;
            base.hDragg.scrollPositionX = 0;
            drawHDragger();
            buildHeaderDom();
            buildBodyDom();
        }


        function onHorzScrollOff(){
            base.hDragg.offset = null
        }


        function onHorzScroll(event){

            ////////////////////// HANDLES THE OFFSET OF MOUSE CLICK POINT AND LEFT OFFSET OF THE GRID ////////////////////////
            if( base.hDragg.offset == null ){
                base.hDragg.offset = event.pageX - (base.elements.hdragger.offset().left - base.layout.offset.left);
            }


            var draggerWidth = (event.pageX - base.elements.headerLeft.width() - base.hDragg.offset ) ;

            base.hDragg.draggerPositionX = draggerWidth;

            if( draggerWidth >=  0 && draggerWidth <= base.hDragg.trackWidth ){
                base.hDragg.draggerPositionX = draggerWidth;
            }

            if( draggerWidth <=  0){
                base.hDragg.draggerPositionX = 0;
            }

            base.hDragg.scrollPositionX = Math.round( base.hDragg.draggerPositionX * base.hDragg.scrollRatio );

            drawHDragger();
            buildHeaderDom();
            buildBodyDom();
        }



        function setColWidthArr(){
            base.horizontalWidthArray = [0];
            var addition = 0;
            for(var i = 0; i < base.data.headerArrayDisplay.length ; i++){
                if( base.data.headerArrayDisplay[i].width != undefined ){
                    base.horizontalWidthArray.push(parseInt(base.data.headerArrayDisplay[i].width) + addition)
                    addition += parseInt(base.data.headerArrayDisplay[i].width);
                }else{
                    base.horizontalWidthArray.push(parseInt(base.options.defaultColWidth) + addition)
                    addition += parseInt(base.options.defaultColWidth);
                }
            }
        }


        function drawHDragger(){
            setHArrayIndex();

            var position = base.hDragg.draggerPositionX;
            //used to move Hdragger to left when pivot is enabled/opened to avoid overlapping of htrack width and dragger position and making sure Hdragger in htrack width visibility. 
            if((base.elements.htrack.width() < base.hDragg.draggerPositionX + base.hDragg.draggerWidth - 15) && (base.pivotChecked == "enabled")) {
                position = base.elements.htrack.width() - ( base.hDragg.draggerWidth + 4);
            } else if(base.hDragg.scrollPositionX > base.hDragg.scrollWidth) {
                position = base.elements.htrack.width() - ( base.hDragg.draggerWidth + 4);
            }            
            base.elements.hdragger.css('left',(position) + 'px');
            setHeader();
        }

        function setHArrayIndex(){

            var startIndexH = 0,
                endIndexH = 0,
                maxStartIndexH =  base.data.headerArray.length;

            while(base.horizontalWidthArray[startIndexH + 1] <= base.hDragg.scrollPositionX){
                startIndexH++;
            }

            endIndexH = startIndexH;
            while(base.horizontalWidthArray[endIndexH] <= base.hDragg.scrollPositionX + base.hDragg.scrollWindowWidth){
                endIndexH++;
            }


            if(startIndexH > base.hDragg.maxStartIndex ){
                startIndexH = base.hDragg.maxStartIndex
            }


            endIndexH += base.options.colBuffer;
            base.hDragg.startIndex = startIndexH;
            base.hDragg.endIndex = endIndexH;

        }


        function scrollRight(){
            if( base.hDragg.endIndex < base.data.fixedHeaderSafeCopyArr.length + 2 ){
                var currentPosition = base.horizontalWidthArray[base.hDragg.startIndex +1];
                base.hDragg.scrollPositionX = currentPosition;
                base.hDragg.draggerPositionX = Math.round(base.hDragg.scrollPositionX * base.hDragg.trackRatio);
                drawHDragger();
                buildHeaderDom();
                buildBodyDom();
            }
        }


        function scrollLeft(){

            if( base.hDragg.startIndex > 0 ){
                var currentPosition = base.horizontalWidthArray[base.hDragg.startIndex -1];

                base.hDragg.scrollPositionX = currentPosition -1;
                base.hDragg.draggerPositionX = Math.round(base.hDragg.scrollPositionX * base.hDragg.trackRatio);

                drawHDragger();
                buildHeaderDom();
                buildBodyDom();

            }else{
                base.hDragg.scrollPositionX = 0;
                base.hDragg.draggerPositionX = 0;
                drawHDragger();
            }

        }


        function pageLeft(){

            if( base.hDragg.startIndex >  0 ){
                var index = (base.hDragg.startIndex - ( base.hDragg.endIndex - base.hDragg.startIndex) < 0 ) ? 0 : (base.hDragg.startIndex - ( base.hDragg.endIndex - base.hDragg.startIndex) - 1);
                //hack to avoid -1 ^
                if(index < 0 ){
                    index = 0;
                }

                var currentPosition = base.horizontalWidthArray[index];
                base.hDragg.scrollPositionX = currentPosition -1;
                base.hDragg.draggerPositionX = Math.round(base.hDragg.scrollPositionX * base.hDragg.trackRatio);

                drawHDragger();
                buildHeaderDom();
                buildBodyDom();

            }else{
                base.hDragg.scrollPositionX = 0;
                base.hDragg.draggerPositionX = 0;
                drawHDragger();
            }
        }

        function pageRight(){
            if( base.hDragg.endIndex < base.data.fixedHeaderSafeCopyArr.length + 2 ){
                var endIndexH = base.hDragg.startIndex;
                while(base.horizontalWidthArray[endIndexH] <= base.hDragg.scrollPositionX + base.hDragg.scrollWindowWidth){
                    endIndexH++;
                }
                if(endIndexH > base.hDragg.maxStartIndex){
                    endIndexH = base.hDragg.maxStartIndex;
                }


                var currentPosition = base.horizontalWidthArray[endIndexH];
                base.hDragg.scrollPositionX = currentPosition;
                base.hDragg.draggerPositionX = Math.round(base.hDragg.scrollPositionX * base.hDragg.trackRatio);
                drawHDragger();
                buildHeaderDom();
                buildBodyDom();
            }
        }





        function findLastStartIndex(){
            var reverseArray = _.reverse(_.clone(base.horizontalWidthArray));
            var colWidths = 0;
            var counter = 0;
            while(colWidths <= base.hDragg.scrollWindowWidth){
                var start =  reverseArray[counter];
                var end =  reverseArray[counter + 1];
                var col = start - end;
                colWidths += col
                counter++
            }
            var startIndex = base.horizontalWidthArray.length - counter
            return startIndex
        }







///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// START V-SCROLL FUNCTIONS /////////////////////////////////////////


        function findLastStartVIndex(){

            var gridHeight = base.elements.body.height();
            var rowHeight = 0;
            var index = base.data.dataArr.length - 1;

            if(base.data.dataArr.length < 0) {
                return 1;
            }


            while (rowHeight <= gridHeight) {
                if (typeof base.data.dataArr[index] == "undefined" || typeof base.data.dataArr[index].height == "undefined") {
                    rowHeight += base.options.defaultRowHeight;
                } else {
                    rowHeight += base.data.dataArr[index].height;
                }
                index--;
                if (index <= 0) {
                    break;
                }

            }
            index += 3;
            return index;

        }




        function buildVScrollBar(){

            var scrollHeight =  (base.layout.gridHeight) - (base.layout.scrollWindow - ( base.options.defaultRowHeight * 2) );
            var trackHeight = (base.elements.body.height() - base.elements.vbtns.height() ) - 3; //Remove 3 pixels to allow padding between dragger and scroll container
            var trackRatio = trackHeight / base.layout.gridHeight;
            var draggerHeight = trackHeight * trackRatio;


            if( draggerHeight < base.options.vDraggerMinHeight ){
                draggerHeight = base.options.vDraggerMinHeight;
            }

            trackHeight -= draggerHeight;
            trackRatio = trackHeight / scrollHeight;



            if(base.layout.gridHeight < base.layout.scrollWindow ){
                base.elements.vdragger.hide();
            }else if( !base.elements.vdragger.is(":visible")){
                base.elements.vdragger.show();
            }

            var scrollRatio = scrollHeight / trackHeight;
            base.vDragg.trackRatio = trackRatio;
            base.vDragg.draggerHeight = draggerHeight;
            base.vDragg.trackHeight = trackHeight;
            base.vDragg.scrollRatio = scrollRatio;
            base.vDragg.scrollHeight = scrollHeight;
            //SET DRAGGER HEIGHT
            base.elements.vdragger.height(draggerHeight+'px');

            if( base.vDragg.draggerPositionY > 0 && base.vDragg.preiviousTrackHeight != undefined ){
                var difference =  base.vDragg.trackHeight - base.vDragg.preiviousTrackHeight;
                base.vDragg.draggerPositionY += difference
            }

            base.vDragg.preiviousTrackHeight = trackHeight;
            drawVDragger();
        }


        function setRowHeightArr(){
            base.verticalHeightArray = [0];
            var addition = 0;
            for(var i = 0; i < base.data.dataArr.length; i++){

                if( base.data.dataArr[i].height != undefined ){
                    base.verticalHeightArray.push(parseInt(base.data.dataArr[i].height) + addition)
                    addition += parseInt(base.data.dataArr[i].height);
                }else{
                    base.verticalHeightArray.push(parseInt(base.options.defaultRowHeight) + addition)
                    addition += parseInt(base.options.defaultRowHeight);
                }
            }

            return addition;
        }





        function onScroll(event){
            /////////////////////////////////////////
            // TODO: MAKE SCROLL DRAGGER SCROLL FROM CLICKED LOCATION NOT FROM TOP- ( MIGHT NEED to pass offset at mouse down point )
            /////////////////////////////////////////
            // var subtract = event.pageY - base.elements.vdragger.offset().top;
            var draggerHeight = (event.pageY - base.elements.body.offset().top) ;


            if( draggerHeight >=  0 && draggerHeight <= base.vDragg.trackHeight ){
                base.vDragg.draggerPositionY = draggerHeight ;
            }

            if( draggerHeight <=  0){
                base.vDragg.draggerPositionY = 0;
            }

            if( draggerHeight >=  base.vDragg.trackHeight){
                base.vDragg.draggerPositionY = base.vDragg.trackHeight;
            }

            base.vDragg.scrollPositionY = Math.round(base.vDragg.draggerPositionY * base.vDragg.scrollRatio);
            drawVDragger();
            buildBodyDom();

        }


        function drawVDragger(){
            setArrayIndex();
            base.elements.vdragger.css('top',base.vDragg.draggerPositionY + 'px');
        }

        function setArrayIndex(){

            var startIndexV = 0, endIndexV = 0;
            while(base.verticalHeightArray[startIndexV + 1] <= base.vDragg.scrollPositionY ){
                startIndexV++;
            }
            endIndexV = startIndexV;
            while(base.verticalHeightArray[endIndexV] <= base.vDragg.scrollPositionY + base.elements.body.height()){
                endIndexV++;
            }

            if(startIndexV >= base.vDragg.maxStartIndex ){
                startIndexV = base.vDragg.maxStartIndex - 1;
                base.vDragg.draggerPositionY = base.vDragg.trackHeight;
            }



            base.vDragg.startIndex = startIndexV;
            base.vDragg.endIndex =  endIndexV;

            /* to store the scroll position on top of tableState : 6/10/2021*/
           if(options.data.tableState){
               if((base.data.safeCopyArr.length != base.data.dataArr.length) && (options.data.tableState.colSearch.length >0 || !_.isEmpty(options.data.tableState.search))){
                    options.data.tableState.vScroll = base.vDragg;
               }
           }

        }

        // setVScroll
        function setVScroll(index){

            if(isNaN(index)){
                addAlert("alert-danger","<b>Error:</b> Numeric values only ",4000 );
                return false;
            }

            if(index > base.data.dataArr.length ){
                index = base.data.dataArr.length;
            }else if( index == 0 ){
                index = 1;
            }

            var height = base.verticalHeightArray[index - 1];

            base.vDragg.scrollPositionY = height;
            base.vDragg.draggerPositionY = Math.round(height / base.vDragg.scrollRatio);
            drawVDragger();
            buildBodyDom();

        }


        // setVScroll
        function setHScroll(index){

            if(isNaN(index)){
                addAlert("alert-danger","<b>Error:</b> Numeric values only ",4000 );
                return false;
            }

            if(index > base.data.headerArray.length ){
                index = base.data.headerArray.length;
            }else if( index == 0 ){
                index = 1;
            }

            var width = base.horizontalWidthArray[index - 1];

            base.hDragg.scrollPositionX = width;
            base.hDragg.draggerPositionX = Math.round(width / base.hDragg.scrollRatio);

            drawHDragger();
            buildHeaderDom();

        }



        function scrollDown(){
            base.vDragg.scrollPositionY += base.options.defaultRowHeight * base.options.vClickThroughNum;
            base.vDragg.draggerPositionY = Math.round(base.vDragg.scrollPositionY * base.vDragg.trackRatio);
            drawVDragger();
            buildBodyDom()
        }

        function scrollUp(){
            if( base.vDragg.scrollPositionY > 1 ){
                Math.abs(base.vDragg.scrollPositionY -= base.options.defaultRowHeight * base.options.vClickThroughNum);
                base.vDragg.draggerPositionY = Math.round(base.vDragg.scrollPositionY * base.vDragg.trackRatio);
                drawVDragger();
                buildBodyDom();
            }
        }


        function pageUp(){
            if( base.vDragg.startIndex >  1 ){
                var pageThroughNum = Math.floor(base.elements.body.height() / base.options.defaultRowHeight);
                base.vDragg.scrollPositionY -= base.options.defaultRowHeight * pageThroughNum;

                if( base.vDragg.scrollPositionY < 0 ){
                    base.vDragg.scrollPositionY = 0;
                }

                base.vDragg.draggerPositionY = (Math.round(base.vDragg.scrollPositionY * base.vDragg.trackRatio) >= 0) ?  Math.round(base.vDragg.scrollPositionY * base.vDragg.trackRatio) : 1;
                var vIndex = ( (base.vDragg.startIndex - pageThroughNum) >= 0 ) ? base.vDragg.startIndex - pageThroughNum : 0;
                var row_object_id = base.data.dataArr[vIndex].object_id;
                setSelected(base.selected.col_id,row_object_id,'cell');

                drawVDragger();
                buildBodyDom();
            }

        }

        function pageDown(){


            var pageThroughNum =  Math.floor(base.elements.body.height() / base.options.defaultRowHeight);


            if( base.vDragg.endIndex <=  base.data.dataArr.length ){
                base.vDragg.scrollPositionY += base.options.defaultRowHeight * pageThroughNum;
                base.vDragg.draggerPositionY = Math.round(base.vDragg.scrollPositionY * base.vDragg.trackRatio);

                var row_object_id = base.data.dataArr[base.vDragg.startIndex + pageThroughNum].object_id ;
                setSelected(base.selected.col_id,row_object_id,'cell');

                drawVDragger();
                buildBodyDom();

            }


        }




//////////////////////////////////////// END V-SCROLL FUNCTIONS /////////////////////////////////////////


/////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// RESIZE COL FUNCTIONS ///////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

        var colResizeObj = {}, colSizeHandler;

        function setColResizeObj(){
            colResizeObj = {
                startPosition:0,
                currentPosition:0,
                currentHeight:0,
                object_id:null,
                dragOn:false,
                minWidth:base.options.minColWidth
            }
        }



        function dragColHandler(event){

           // var $element = $(event.data.element);
           var eventElem = (event.data);
           var $element = $(eventElem.element);

            if(!colResizeObj.dragOn){
                colResizeObj.startPosition = event.pageX;
                colResizeObj.object_id = $element.parent().attr("colobj")
            }

            colResizeObj.dragOn = true;
            colResizeObj.currentPosition = event.pageX;


            base.elements.resizeColLine.show();
            base.elements.resizeColLine.css('top', base.elements.header.position().top + 'px' );
            base.elements.resizeColLine.css('left',event.pageX+'px');
            base.elements.resizeColLine.css('height', ( base.elements.body.height()+base.elements.header.height())+'px');

        }


        function dragColHandlerEnd(event){
            base.elements.resizeColLine.hide();
            var col_id = colResizeObj.object_id;
            var curDataCol = _.find(base.data.headerArray, {'object_id':col_id});

            if( curDataCol != undefined ){
                var newWidth =  curDataCol.width + (colResizeObj.currentPosition - colResizeObj.startPosition);
                if( newWidth < colResizeObj.minWidth){
                    curDataCol.width = colResizeObj.minWidth;
                }else{
                    curDataCol.width = newWidth;
                }
                setColResizeObj();
                buildGrid();
            }
        }


////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// RESIZE ROW FUNCTIONS /////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////

        var rowSizeHandler, rowResizeObj = {}

        function setRowResizeObj(){
            rowResizeObj = {
                startPosition:0,
                currentPosition:0,
                currentHeight:0,
                object_id:null,
                dragOn:false,
                minHeight: base.options.minRowHeight

            }
        }

        function dragRowHandlerEnd(event){


            base.elements.resizeRowLine.hide();
            var row_id = rowResizeObj.object_id;
            var curDataRow;
            if (row_id === '-1') {
                curDataRow = base.headerRow = base.headerRow || {};
            } else {
                curDataRow = _.find(base.data.dataArr, {'object_id':row_id});
            }


            if(typeof curDataRow === 'undefined'){
                return false;
            }


            if( curDataRow.height === undefined ){
                curDataRow.height = base.options.defaultRowHeight;
            }



            if( curDataRow != undefined ){
                var newHeight =  curDataRow.height + (rowResizeObj.currentPosition - rowResizeObj.startPosition);
                // ***** Make Sure we are greater then  rowResizeObj.minHeight
                if( newHeight < rowResizeObj.minHeight){
                    curDataRow.height = rowResizeObj.minHeight;
                }else{
                    curDataRow.height = newHeight;
                }
                setRowResizeObj();
                buildGrid();
            }
        };


        function dragRowHandler(event){
           /// var $element = $(event.data.element);
           var eventElem = (event.data);
           var $element = $(eventElem.element);

            if(!rowResizeObj.dragOn){
                rowResizeObj.startPosition = event.pageY;
                rowResizeObj.object_id = $element.parent().parent().attr("rowobj")
            }
            rowResizeObj.dragOn = true;
            rowResizeObj.currentPosition = event.pageY;
            base.elements.resizeRowLine.show();
            base.elements.resizeRowLine.css('top',event.pageY+'px');
            base.elements.resizeRowLine.css('left',base.$el.offset().left + 'px');
        }






//////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////  REORDER COLUMNS  ////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////

        var colReorderObj = {}

        function setColReorderObj(){
            colReorderObj = {
                startPosition:0,
                currentPosition:0,
                currentHeight:0,
                object_id:null,
                dragOn:false,
                blockOffset:0,
                target_object_id:null,
                minWidth:50
            }
        }

        function dragReorderColHandler(event){
           // var $element = $(event.data.element)
           var eventElem = (event.data);
           var $element = $(eventElem.element);


            if(!colReorderObj.dragOn){
                colReorderObj.startPosition = event.pageX;
                colReorderObj.object_id = $element.attr("colobj");
                base.elements.resizeColBlock.css('left',event.pageX+'px');
                base.elements.resizeColBlock.width($element.width());
                base.elements.resizeColBlock.show();
                colReorderObj.blockOffset = event.pageX - $element.offset().left;
            }

            colReorderObj.dragOn = true;
            colReorderObj.currentPosition = event.pageX;
            colReorderObj.target_object_id = getColByWidth(event.pageX);

            showColIndicator(colReorderObj.target_object_id);
            base.elements.resizeColBlock.show();
            base.elements.resizeColBlock.height(base.elements.body.height());
            if(options.data.fromModal && options.data.fromModal ==  true) { //alignments adjustments for grid on modal..
                base.elements.resizeColBlock.css('top', 128 +'px');
                base.elements.resizeColBlock.css('left',((event.pageX - colReorderObj.blockOffset) - (document.querySelector(base.elements.selection.selector).offsetWidth/2))+'px');
            } else {
                base.elements.resizeColBlock.css('top',base.elements.body.offset().top+'px');
                base.elements.resizeColBlock.css('left',(event.pageX - colReorderObj.blockOffset)+'px');
            }

        }


        function showColIndicator(_object_id){
            if( _object_id !== null ){
                base.elements.colLine.show();
                base.elements.colLine.height(base.elements.body.height());
                var element =  $( "[colobj='"+_object_id+"']" );
                if(options.data.fromModal !=undefined && options.data.fromModal){  //these styles will apply only when call the grid on Modal 8/19/2021
                    base.elements.colLine.css('left', (element.offset().left + (element.width()/2)) +'px');// need to change as it was fixed to only a perticlular resolution..
                    base.elements.colLine.css('top',base.elements.body.offset().top - 64 +'px');


                }else{
                    base.elements.colLine.css('top',base.elements.body.offset().top+'px');
                    base.elements.colLine.css('left', (element.offset().left + element.width()) +'px');
                }
            }
        }





        //TODO: how to reorder with new safe array
        function dragReorderColHandlerEnd(event){
            base.elements.resizeColBlock.hide();
            base.elements.colLine.hide();
            var toMove = _.findIndex(base.data.headerArraySafe, { 'object_id': colReorderObj.object_id });
            var toWhere = _.findIndex(base.data.headerArraySafe, { 'object_id': colReorderObj.target_object_id });

            if( toMove > 0 && toWhere > 0){
                if(toWhere == 0 ){
                    toWhere ++;
                }
                arraymove(base.data.headerArraySafe,toMove, toWhere);
                setColReorderObj();
                buildGrid();
            }
        };



        function arraymove(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }







        function getColByWidth(_mouseX){
            var hScrollPosition  =  base.elements.ggleftcol.offset().left + base.elements.ggleftcol.width();
            if( _mouseX > hScrollPosition &&  base.hDragg.scrollPositionX > 0){
                _mouseX =  base.horizontalWidthArray[base.hDragg.startIndex] + _mouseX;
                // _mouseX += base.hDragg.scrollPositionX;
            }

            var offsetLeft = base.$el.offset().left,
                previousX = offsetLeft,
                currentX = offsetLeft,
                returnobj_id = null

            for (var i = 0; i < base.data.headerArray.length; i++) {
                currentX += base.data.headerArray[i].width;
                if( _mouseX >=  previousX && _mouseX <=  currentX  ){
                    returnobj_id = base.data.headerArray[i].object_id;
                    return returnobj_id;
                }
                previousX = currentX;
            }

            return returnobj_id;
        }




////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// FIXED COLUMNS  ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

        var colFixedObj = {};

        function setColFixedObj(){
            colFixedObj = {
                startPosition:0,
                currentPosition:0,
                currentHeight:0,
                object_id:null,
                dragOn:false,
                target_object_id:null
            }
        }

////////////////RIGHT HERE
        function positionColFreezeHandle(){
            var top = base.elements.header.offset().top + 1;
            var left = base.layout.offset.left;

            var height = base.elements.body.height() + base.elements.header.height() -1;
            var fixedColumns = ( base.options.fixedColumn > base.data.headerArray.length )? base.data.headerArray.length : base.options.fixedColumn;
            var width = getWidthTotal(base.data.headerArray,'width',fixedColumns);

            
            base.elements.fixColHandler.height(height);
            if(options.data.fromModal !=undefined && options.data.fromModal){ // these styles will apply only when call the grid on Modal 8/19/2021
                base.elements.fixColHandler.css('left', width+'px');
                base.elements.fixColHandler.css('top',top - 64 +'px');
            }else{
                base.elements.fixColHandler.css('left',left + width+'px');
                base.elements.fixColHandler.css('top',top+'px');
            }
            

        }



        function dragFixedColHandler(event){
           // var $element = $(event.data.element);
           var eventElem = (event.data);
           var $element = $(eventElem.element);

            base.elements.fixColHandler.css('left',(event.pageX)+'px');
            colFixedObj.dragOn = true;
            colFixedObj.currentPosition = event.pageX;
            colFixedObj.target_object_id = getColByWidth(event.pageX);

            if(colFixedObj.target_object_id === null ){
                colFixedObj.target_object_id = base.data.headerArray[0].object_id
            }

            showColIndicator(colFixedObj.target_object_id);
        }


        function dragFixedColHandlerEnd(event){
            if( colFixedObj.target_object_id != undefined ){
                base.options.fixedColumn = _.findIndex(base.data.headerArray, { object_id: colFixedObj.target_object_id }) + 1;
                if( base.options.fixedColumn < 1 ){
                    base.options.fixedColumn = 1;
                }
                base.elements.colLine.hide();
                setColFixedObj();
                buildGrid();
            }

        }


        function getWidthTotal(_array,_property,_index){


            var width = 0;
            for (var i = 0; i < _index; i++) {
                width += _array[i][_property];
            }


            return width;
        }


        function selectRowGroup(row_id){
            var col_id =  base.data.headerArray[1].map;
            setSelected(col_id,row_id,'row',true);
        }


        function selectRow(_element,_ctrlClick){
            var row_id = _element.attr('rowobj');
            var col_id =  base.data.headerArray[1].map;
            setSelected(col_id,row_id,'row',_ctrlClick);
        }



        function selectCol(map,_ctrlClick){
            if( base.data.dataArr.length){
                var row_id = base.data.dataArr[0].object_id;
                var map  = _.find(base.data.headerArray, {'map': map}).map;
                setSelected(map,row_id,'header',_ctrlClick);
            }
        }


        function selectCell(element,type,event){
            var obj_id = $(element).attr('obj_id');
            var row_id= $(element).parent().attr('rowobj');
            var col_id = $(element).attr('colobj');
            setSelected(col_id,row_id,'cell');
            //BATCH

            //base.renderSelected.clearAll();

            if(col_id === "batchSelect" && event.which === 1 ){
                base.batch.select();
            }
        }


        function setDefaultSelect(){


            if( base.data.dataArr.length && ( base.selected.row_id === null ||  base.selected.col_id === null) ){
                var map = _.filter(base.data.headerArray, {'display':'y'})[1].map;
                var row_id = base.data.dataArr[0].object_id;
                setSelected(map,row_id,'cell');
                base.$el.find('.gg-body').focus();
                return true;
            }else{
                //TODO: MAKE THIS WORK WITH GROUP SELECTIONS : ON CHAN
                setSelected(base.selected.col_id,base.selected.row_id,'cell',false);
            }



            return false;
        }


        base.gridSelect = {
            reset: function() {
                resetSelectedValue()
            }
        }


        function resetSelectedValue(){
            base.largeText.hide();
            $('.div-col',base.$el).removeClass("selected_input");
            $('.div-row',base.$el).removeClass("selected-row");
            $('.headerCol',base.$el).removeClass("selected-col-header");
            $('.div-col',base.$el).removeClass("selected-col");
            $('.div-col-prime',base.$el).removeClass("selected-prime-row");
            $('.div-row',base.$el).removeClass("selection-row");

            base.elements.editValue.hide();
            base.elements.editValue.html("");
            base.selected.color = "green";
            base.selected.select_type=null;
            base.selected.row_id=null;
            base.selected.col_id=null;
            base.selected.obj_id=null;
            base.selected.data_type=null;
            base.selected.edit_type=null;
            base.selected.validation=null;
            base.selected.action=null;
            base.selected.format=null;
            base.selected.value=null;
            base.selected.oldValue=null;
            base.selected.rowData=null;
            base.selected.editable='n';
            base.selected.new_row_editable = '0';
            base.selected.element=null;
            base.selected.edit_type = null;
            base.selected.static_values = null;
        }

        //setSelected



        function setSelected(col_id,row_id,type,ctrlClick){

            //  console.log("=========================================");
            //   console.log("col_id" , col_id);
            //  console.log("row_id" , row_id);
            //   console.log("type" , type);


            if(  base.data.dataArr.length <= 0){
                //addAlert("alert-warn","Cell could not be selected",1000 );
                return;
            }


            //DO WE HAVE A GROUP
            if(!ctrlClick === true){
                base.selectedGroup = [];
            }

            resetSelectedValue();

            var curDataCol = _.find(base.data.headerArray, {'map':col_id});


            //Checks to see if a column that might of been hidden had the selected cell. If so we call setDefaultSelect()
            if(typeof curDataCol === 'undefined'  ){
                base.selected.row_id = null;
                setDefaultSelect();
                return false;
            }


            var curDataRow  = _.find(base.data.safeCopyArr, {'object_id':row_id});


            if(typeof curDataRow === 'undefined'  ) {
                base.selected.row_id = null;
                setDefaultSelect();
                return false;
            }

            var element =  $("[obj_id="+row_id+"-"+col_id+"]",base.$el);

            base.selected.select_type = type;
            base.selected.col_id = curDataCol.map;
            base.selected.row_id = curDataRow.object_id;
            base.selected.obj_id = base.selected.row_id + "-" +  base.selected.col_id;
            base.selected.format = curDataCol.format;
            base.selected.validation = curDataCol.validation;
            base.selected.data_type = curDataCol.data_type;
            base.selected.action = curDataCol.action;
            base.selected.value = curDataRow[curDataCol.map];
            base.selected.oldValue = curDataRow[curDataCol.map];
            base.selected.rowData = curDataRow;
            base.selected.colData = curDataCol;
            base.selected.editable = curDataCol.editable;
            base.selected.element = element;
            base.selected.new_row_editable = (curDataCol.new_row_editable) ? curDataCol.new_row_editable : '0' ;
            base.selected.edit_type = curDataCol.edit_type;
            base.selected.static_values = curDataCol.static_values;


            if(type === 'cell' ){
                //base.renderSelected.renderType = "cellGroup";
                base.renderSelected.setDefaultCell(base.selected);
                // base.largeText.show();
                if(base.selected.edit_type === "comments"){
                    base.largeText.show();
                }
                /* added by Divya 9/22/2019 */
               else if(base.selected.edit_type === "attachments"){
                    base.largeText.show();
                }
            }

           /*  if(type === 'cell' ){
                //base.renderSelected.renderType = "cellGroup";
                base.renderSelected.setDefaultCell(base.selected);
                // base.largeText.show();
                if(base.selected.edit_type === "attachments"){
                    base.largeText.show();
                }
            } */

            options.data.selected = base.selected;
            // $(element).addClass('selected_input');

            //todo: do not allow mix of select type in selectedGroup: cell,header,row
            if( typeof  _.find(base.selectedGroup , {'obj_id':base.selected.obj_id } ) === 'undefined'     ){
                base.selectedGroup.push(_.clone(base.selected));
            }


            base.renderSelected.renderSelected();


            // IF FOUCS IS IN globalSearch DO NOT SET FOCUS ON GRID
            if (base.elements.globalSearch.is(':focus')){
                return true;
            }

            base.focus.gridBody.focus();
            return true;

        }


        function scrollToSelected(_vIndex,_hIndex){
            if( _vIndex <  base.vDragg.startIndex ||  _vIndex > base.vDragg.endIndex ){
                setVScroll(_vIndex);
            }

            if( _hIndex <  base.hDragg.startIndex ||  _hIndex > base.hDragg.endIndex ){
                // setHScroll(_hIndex);
            }
        }



        function arrowMove(_direction){

            var newVIndex = null,
                newHIndex = null,
                vIndex = _.findIndex(base.data.dataArr, {'object_id': base.selected.row_id}),
                hIndex = _.findIndex(base.data.headerArray, {'map': base.selected.col_id})
            if(_direction === 'up' && vIndex > 0){
                base.renderSelected.clearAll();

                newVIndex = vIndex - 1;
                if( newVIndex < base.vDragg.startIndex){
                    scrollUp();
                }
                scrollToSelected(vIndex,hIndex);
                var row_object_id = base.data.dataArr[newVIndex].object_id;
                setSelected(base.selected.col_id,row_object_id,'cell');


            }else if ( _direction === 'down' && vIndex < base.data.dataArr.length -1 ){
                base.renderSelected.clearAll();
                newVIndex = vIndex + 1;
                if( newVIndex >= base.vDragg.endIndex){
                    scrollDown();
                }
                scrollToSelected(vIndex,hIndex);
                var row_object_id = base.data.dataArr[newVIndex].object_id;
                setSelected(base.selected.col_id,row_object_id,'cell');

            }else if(_direction === 'left' &&  hIndex > 1){
                                base.renderSelected.clearAll();

                newHIndex = hIndex - 1;
               
                if( (newHIndex - base.options.fixedColumn) <= base.hDragg.startIndex - 1){
                    scrollLeft();
                }
                var col_object_id = base.data.headerArray[newHIndex].map;
                setSelected(col_object_id,base.selected.row_id,'cell');

            }else if(_direction === 'right' && hIndex < base.data.headerArray.length){
                newHIndex = hIndex + 1;
                base.renderSelected.clearAll();


                if( newHIndex === base.options.fixedColumn && base.hDragg.startIndex > 0  ){
                    resetHScroll();
                }

                if( (newHIndex - base.options.fixedColumn) >= base.hDragg.endIndex - 2 ){
                    scrollRight();
                }

                if(newHIndex < base.data.headerArray.length) {
                    var col_object_id = base.data.headerArray[newHIndex].map;
                }else{
                    var col_object_id = base.data.headerArray[newHIndex-1].map;
                }

                var element = base.$el.find("[obj_id='" + base.selected.row_id + "-" + col_object_id + "']");
                setSelected(col_object_id,base.selected.row_id,'cell');

            }


        }



////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// batchSelecter ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////



        base.batch = {
            maxSelectAll:3500,
            select: function() {

                var batchObj = {row_id:base.selected.row_id,data:base.selected.rowData};


                if(base.selected.rowData === null  ){
                    return false
                }



                if(base.selected.rowData.ggNotEditable  ){
                    var msg = ( options.data.settings.batch_rule_message) ? options.data.settings.batch_rule_message : 'Row is not Selectable'
                    addAlert("alert-warn",msg,1000 );
                    return
                }



                if ( base.selected.rowData.batchSelect) {
                    delete base.selected.rowData.batchSelect;
                    _.remove(base.batchSelect, {'row_id': base.selected.row_id});
                } else {
                    batchObj.data.batchSelect = "true";
                    base.batchSelect.push(batchObj);
                }
                hideContextMenu();
                
                buildBodyDom();


            },



            //-here

            unSelectNonEditable: function(){
                if( base.selected.rowData.ggNotEditable &&  _.find(base.batchSelect,{ 'row_id': base.selected.row_id})  ){
                    delete base.selected.rowData.batchSelect;
                    _.remove(base.batchSelect, {'row_id': base.selected.row_id});
                }
                buildBodyDom();
            },



            setBatchSelected: function(){

                /* TODO: PROMT USER FOR NOW WE WILL NOT KEEP ITEMS CHECKED
                 var batchArray =  _.filter( base.data.dataArr, {batchSelect:"true"});
                 _.forEach( batchArray, function(value, key) {
                 ////////console.log("value : ------------------  " , value)
                 var batchObj = {row_id:value.object_id,data:value};
                 base.batchSelect.push(batchObj);
                 })
                 */

                base.batchSelect = [];
                

            },

            removeBatchSelectn: function(isRemoved,batchSelect){
                var bdata = {};
                bdata.batchSelect=batchSelect;
                bdata.removeBatchSelection = isRemoved;
                if( options.data.settings.batch_select == 1){
                    base.api.callBack( 'removeBatchSelect',bdata,'removeBatchSelect');
                }
            },

            setSelectedFromBatch: function(){

                /* TODO: PROMT USER FOR NOW WE WILL NOT KEEP ITEMS CHECKED
                 if(base.batchSelect.length > 0 ){
                 _.forEach( base.batchSelect, function(value, key) {
                 var obj = _.find(base.data.dataArr, { 'object_id': value.row_id });
                 obj.batchSelect = "true"
                 base.batchSelect[key].data = obj;
                 })

                 }
                 */
                base.batchSelect = [];
                this.removeBatchSelectn(base.removeBatchSelection,base.batchSelect);
            },

            selectRow: function(){
                this.select();
            },

      
            unselectAll: function(){
                _.forEach( base.batchSelect, function(value, key) {
                    _.remove(base.batchSelect, {'row_id': value.data.row_id});
                    var obj = _.find(base.data.dataArr, { 'object_id': value.row_id });

                    if(typeof obj != "undefined" && typeof obj.batchSelect != "undefined"){
                        delete obj.batchSelect;
                    }
                });

                base.batchSelect = [];
                this.removeBatchSelectn(base.removeBatchSelection,base.batchSelect);
                buildBodyDom();
            },


            selectAll: function(){
                this.unselectAll();
                if( base.data.dataArr.length > this.maxSelectAll  ){
                    var message = "You cannot select more than "+this.maxSelectAll + " at one time. Please use the filters to reduce your selection. ";
                    addAlert("alert-warn",message,4000 );
                    return false;            }
                _.forEach( base.data.dataArr, function(value, key) {


                    if( value.ggNotEditable  ) {


                    }else{
                        value.batchSelect = "true";
                        var batchObj = {row_id: value.object_id, data: value};
                        base.batchSelect.push(batchObj)

                    }

                });
                    this.removeBatchSelectn(!base.removeBatchSelection,base.batchSelect);
                buildBodyDom();
            }

        }



////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////  base.edit  //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


        base.edit = {

            visible: true,
            keyDown: function (e,focus) {


                if( typeof base.selected.colData.edit_rules != "undefined" && !base.buildDom.isRowColEditable(base.selected.rowData,base.selected.colData.edit_rules)  ) {
                    var msg = ( base.selected.colData.edit_rule_message) ? base.selected.colData.edit_rule_message : 'Cell is not Editable'
                    addAlert("alert-warn",msg,1000 );
                    return false;
                }

                if(base.selected.edit_type == 'form' && base.selected.colData.edit_icon == 'download'){
                    console.log("downladfsdf");
                  //  this.downloadAllDoc(base.selected.row_id);
                }else if( base.selected.edit_type == 'form'){
                    this.modalFrom();
                }else if( base.selected.edit_type == 'dropdown'){
                    this.dropDownEdit('',false);
                }else if(base.selected.edit_type === 'checkbox' && base.selected.editable === 'y'){
                    this.textAreaEdit(e,focus);
                    //TODO: See if this needs to be removed
                }else if (base.selected.edit_type === 'checkbox'){

                }
                else if( base.selected.editable === 'y'  ){
                    this.textAreaEdit(e,focus);
                }else if(  base.options.gridType === 'batch-save' &&  _.indexOf(base.data.newRow, base.selected.row_id)  > -1 && base.selected.new_row_editable === '1'  ){
                    this.textAreaEdit(e,focus);
                }else{
                    if( e.which != 16 ){
                        addAlert("alert-warn","This cell is not editable",4000 );
                    }else{
                        return true;
                    }
                }


            },

            editCell: function() {

                if( typeof base.selected.colData.edit_rules != "undefined" && !base.buildDom.isRowColEditable(base.selected.rowData,base.selected.colData.edit_rules)  ) {
                    var msg = ( base.selected.colData.edit_rule_message) ? base.selected.colData.edit_rule_message : 'Cell is not Editable'
                    addAlert("alert-warn",msg,1000 );
                    return false;
                }

                if(base.selected.edit_type == 'form' && base.selected.colData.edit_icon == 'download'){
                    console.log("downladfsdf");
                   // this.downloadAllDoc(base.selected.row_id);
                }else if( base.selected.edit_type == 'form'){

                    console.log("dsfkadsfsdfdsafjasd;lfjasdfjasjjalskdfjasdfjadsfj")

                    this.modalFrom();
                }else if( base.selected.edit_type == 'dropdown'){
                    this.dropDownEdit('',false);
                }else if( base.selected.edit_type == 'comments'){
                    base.largeText.edit();
                }else if(base.selected.editable === 'y' ){
                    this.textAreaEdit('',false);
                }else if(  base.options.gridType === 'batch-save' &&  _.indexOf(base.data.newRow, base.selected.row_id)  > -1 && base.selected.new_row_editable === '1'    ){
                    this.textAreaEdit('',false);
                }else if (base.selected.edit_type == 'attachments'){
                    base.largeText.edit();
                }
                else{
                    addAlert("alert-warn","This cell is not editable",2000);
                }
            },


            pasteCell: function(_val){

                if( typeof base.selected.colData.edit_rules != "undefined" && !base.buildDom.isRowColEditable(base.selected.rowData,base.selected.colData.edit_rules)  ) {
                    var msg = ( base.selected.colData.edit_rule_message) ? base.selected.colData.edit_rule_message : 'Cell is not Editable'
                    addAlert("alert-warn",msg,1000 );
                    return false;
                }

                if(base.selected.editable === 'y' &&  base.selected.edit_type === 'text' ){
                    this.setValue(_val);
                }else if(  base.options.gridType === 'batch-save' &&  _.indexOf(base.data.newRow, base.selected.row_id)  > -1  && base.selected.new_row_editable === '1'   ){
                    this.setValue(_val);
                }else if(  base.selected.editable === 'y' &&  base.selected.edit_type === 'dropdown'  ){
                    this.checkDropDown(_val);
                }else{
                    addAlert("alert-warn","This cell is not editable",2000 );
                }
            },



            copy: function(){
                var type = "";
                var copyArray = [];
                var columnArray = [];

                if( base.selectedGroup == 'all'){
                    var csv = this.ConvertToCSV(base.data.dataArr)
                    return csv;
                }

                _.forEach( base.selectedGroup, function(value, key) {
                    if(value.select_type === 'row' ){
                        type = "row";
                        copyArray.push(value.rowData)
                    }else if( value.select_type === 'header' ){
                        type = "header";
                        columnArray.push(value.col_id);
                    }else if( base.renderSelected.renderType === "cellGroup" ){
                        type = "cellGroup";
                        columnArray.push(value.col_id);

                    }else if( value.select_type === 'cell' ){
                        type = "cell";
                    }


                });


                switch (type) {
                    case "cell":

                        return base.edit.copyCell();
                        break;

                    case "cellGroup":

                        var headers = _.uniq(columnArray);
                        var array = this.copyCellGroup(base.selectedGroup,headers);
                        var csv = this.ConvertToCSV(array,headers)
                        return csv;
                        break;
                    case "header":
                        var copyArray = base.edit.reduceByColumns(columnArray);
                        var csv = this.ConvertToCSV(copyArray)
                        return csv;
                        break;s
                    case "row":
                        return base.edit.copyRow(copyArray);
                        break;
                }


            },




            copyCol: function(){

            },


            copyCell: function(){
                return base.selected.value;
            },


            copyCellGroup: function(_array,_headers){
                var array = [];
                _.forEach( _array, function(value, key) {
                    array.push(value.row_id)
                });
                return this.reduceByColumnsRows(array,_headers);
            },


            copyRow: function(_array){
                var csv = this.ConvertToCSV(_array)
                return csv;
            },


            ConvertToXlsTable: function(objArray) {
                var str = '<table><tr><td style="background-color: #0d0d0d">test1</td><td>test2</td><td>test3</td><td>test3</td></tr></table>';
            },

            ConvertToCSV: function(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += '\t'

                        line += array[i][index];
                    }

                    str += line + '\r\n';
                }
                return str;
            },


            reduceByColumns: function(_headers){
                var returnArray = [];
                _.forEach( base.data.dataArr, function(value, key) {
                    var row = {};
                    var obj = _.pick(value, _headers);
                    returnArray.push(obj);
                });
                return returnArray;
            },


            reduceByColumnsRows: function(_rows,_headers){
                var returnArray = [];
                var filtered = _.findByValues(base.data.dataArr, "object_id", _rows);

                _.forEach( filtered, function(value, key) {
                    var row = {};
                    var obj = _.pick(value, _headers);
                    returnArray.push(obj);
                });


                return returnArray;
            },






            autoComplete: function(){
                //TODO: ADD AUTO COMPLETE
                //AUTO COMPLETE  //// REQUIRES | https://github.com/devbridge/jQuery-Autocomplete

            },

            selectCalendar: function(){

            },

            dropDown: function(){

            },


            checkAreaEdit: function (e,focus) {
                var element = base.selected.element;
                var value = 1;

                if( typeof base.selected.value == "undefined" ){
                    value = 1;
                }else{
                    value = ( base.selected.value == 1 ) ? 0 : 1;
                }


                base.edit.setValue(value);
                buildGrid();
            },





            buildSelectOptions: function(values,selected){
                var  htmlString = "<select>";
                _.forEach( values, function(value, key) {
                    if( value.value === selected ){
                        htmlString += "<option selected value='"+value.value+"'>"+value.label+"</option>";
                    }else{
                        htmlString += "<option  value='"+value.value+"'>"+value.label+"</option>";
                    }
                });
                htmlString += "<select>";
                return htmlString;
            },


            checkDropDown: function (_val) {
                var validated = false;
                var values = JSON.parse(base.selected.static_values)
                _.forEach( values, function(value, key) {
                    if(value.value == _val ){
                        validated = true;
                    }
                });

                if(validated){
                    this.setValue(_val);
                    return true;
                }else{
                    addAlert("alert-warn",_val + " is not a valid input. Double click to see valid inputs",4000 );
                    return false;
                }


            },

            dropDownEdit: function (e,focus) {
                var element = base.selected.element;
                if(    base.selected.static_values === null ){
                    addAlert("alert-warn","no values have been set for this drop down",4000 );
                    return false;
                }

                var inputDropMenu = $(base.edit.buildSelectOptions(JSON.parse(base.selected.static_values),base.selected.value));

                base.elements.editValue.append(inputDropMenu);
                base.selected.changed = true;
                base.elements.editValue.show();
                inputDropMenu.css('width', $(element).width() );
                inputDropMenu.css('height', $(element).height() );

                base.elements.editValue.css('top', $(element).offset().top + 'px');
                base.elements.editValue.css('left', $(element).offset().left + 'px');
                base.elements.editValue.css('width', $(element).width());

                inputDropMenu.focus();
                inputDropMenu.change(function () {
                    base.edit.setValue($( this ).val());
                })

                inputDropMenu.focus();

            },






            textAreaEdit: function (e,focus) {

                inputText.focus();
                var element = base.selected.element;
                var code = e.keyCode || e.which;
                var input = String.fromCharCode(code).toLowerCase();


                if(code === 8 || code === 16){
                    var input = "";
                }


                base.elements.editValue.unbind('keydown');


                if(base.selected.edit_type == 'text' ){
                    base.selected.changed = true;
                    base.elements.editValue.html("");
                    base.elements.editValue.show();
                    inputText.css('width', $(element).width() );
                    inputText.css('height', $(element).height() );

                    if ((code >= 96 && code <= 111)  || (code >= 185 && code <= 193) ){
                        input = this.getKeyBoardKeys(code)
                    }

                    if( !focus){
                        input = base.selected.value || "";
                        inputText.val(input);
                    }else{
                        inputText.val(input);
                    }

                    base.elements.editValue.append(inputText);
                    inputText.focus();

                    if( focus) {
                        base.elements.editValue.bind("keydown", function (e) {
                            var arrowCode = e.keyCode || e.which;
                            if (arrowCode === 37 || arrowCode === 38 || arrowCode === 39 || arrowCode === 40) {
                                base.$el.find('.gg-body').focus();
                                if(options.data.pivotGroup){
                                    base.$el.find('.gg-body.isPivot-gridLayout').focus();
                                }
                                base.elements.editValue.unbind('keydown');
                                if (arrowCode == '38') {
                                    base.arrowMove("up");
                                }
                                else if (arrowCode == '40') {
                                    base.arrowMove("down");
                                }
                                else if (arrowCode == '37') {
                                    base.arrowMove("left");
                                }
                                else if (arrowCode == '39') {
                                    base.arrowMove("right");
                                }
                            }
                        })
                    }
                }


                if(options.data.fromModal && options.data.fromModal == true) { //alignments adjustments for grid on modal..
                    base.elements.editValue.css('top', $(element).offset().top - 64 + 'px');
                    base.elements.editValue.css('left', (document.querySelector(base.elements.selection.selector).offsetLeft + 8) + 'px');
                    base.elements.editValue.css('width', $(element).width());
                } else {
                    base.elements.editValue.css('top', $(element).offset().top + 'px');
                    base.elements.editValue.css('left', $(element).offset().left + 'px');
                    base.elements.editValue.css('width', $(element).width());
                }



            },


            modalFrom: function(){
                var callName = base.selected.colData.call_back;
                var data = {
                    colData:base.selected.colData,
                    rowData:base.selected.rowData
                }
                base.api.callBack(callName,data,'form' );
                return true;
            },



            setValue: function(_value){
                //HACK to fix JOYS emptyspace hack from piviot UTil
                if(isWhiteSpace(_value)){
                    _value = "";
                }



                _value = sanitize(_value);

                if(this.validate(_value)) {

                    if(base.selected.validation === 'date'){
                        _value = dateFormat(_value);
                    }

                    var oldRowData = _.cloneDeep(base.selected.rowData);
                    base.selected.rowData[base.selected.col_id] = _value;


                    var $obj =  base.$el.find('.div-col[obj_id='+base.selected.obj_id+']');


                    //TOD0: CHECK NOT SURE THIS IS NEEDED - Heath 2/5/2019
                    base.elements.editValue.html("");
                    base.elements.editValue.show();


                    var data = {
                        colData : base.selected.colData,
                        rowData : base.selected.rowData,
                        oldRowData: oldRowData,
                        /* 5/30/2019 */
                        col_id : base.selected.col_id,
                        row_id : base.selected.row_id,
                        selected_type : base.selected.select_type
                    }


                    //SAVE TO DB
                    if( base.selected.colData.call_back && base.selected.colData.call_back.length ){
                        base.api.callBack(base.selected.colData.call_back,data,'input');
                    }


                    if( base.options.gridType === 'batch-save' ){
                        base.api.callBack('batch-save',data,'batch-save');
                    }


                }else{
                    //TODO: make this generic
                    var message = " <b>Error:</b> wrong data type"; //Default
                    if(base.validationMessages[base.selected.validation] ){
                        message = base.validationMessages[base.selected.validation];
                    }

                    addAlert("alert-danger",message,4000 );
                    base.elements.editValue.html("");
                    base.elements.editValue.show();
                }



                //DATA HAS BEEN UPDATED SO RERUN IS SELECTABLE
                base.buildDom.batchSelectable(base.selected.rowData);
                base.batch.unSelectNonEditable();



                buildGrid();
                base.$el.find('.gg-body').focus();
            },


            validate: function(value){

                if(base.selected.validation != null && base.selected.validation != "" && !isWhiteSpace(value) && value != "" ) {  ///// -----VALIDATION


                    if (base.selected.validation == 'numeric') {

                        if (IsNumeric(value)) {
                            return true;
                        } else {
                            return false;
                        }
                    }else if(base.selected.validation == 'date'){

                        if (IsDate(value)) {
                            return true;
                        } else {
                            return false;
                        }

                    }else if (base.selected.validation == 'uppercase'){
                        if(value === value.toUpperCase()){
                            return true;
                        }else{
                            return false;
                        }
                    }else if (base.selected.validation == 'lowercase'){
                        if(value === value.toLowerCase()){
                            return true;
                        }else{
                            return false;
                        }
                    }

                    return false;
                }else{
                    return true;
                }
            },


            copyAddRow  : function(index){

                var object_id =  this.createUniqueObjectId(4);
                var copiedRow = _.cloneDeep(base.selected.rowData);
                copiedRow.object_id = object_id;

                //TODO: FIX SO THAT WE ONLY HAVE ONE CASE OF OBJECT_ID not lower and upper
                copiedRow.OBJECT_ID = object_id;


                copiedRow.gridBatchStatus = 'new';
                ++index;

                if(base.options.gridType === 'batch-save'){
                    options.data.dataArr.splice(index, 0, copiedRow);
                    var tempObj = {index:index,data:copiedRow,type:'add'}
                    base.api.callBack('row-added',tempObj,'row-added');
                }else{
                    options.data.dataArr.splice(index, 0, copiedRow);

                }

                base.data.dataArr =  options.data.dataArr;
                base.data.safeCopyArr =  base.data.dataArr;
                base.data.newRow.push(object_id);
                pipe();
            },



            addRow : function(index,position){
                var p = ((position == 'above') ? index : index +1);
                if(p < 0 ){
                    p = 0;
                }

                var object_id =  this.createUniqueObjectId(4);

                if(base.options.gridType === 'batch-save'){
                    var newRow = {object_id:object_id,gridBatchStatus:'new'};
                    options.data.dataArr.splice(p, 0, newRow);
                    var tempObj = {index:index,data:newRow,type:'add'}
                    base.api.callBack('row-added',tempObj,'row-added');
                }else{
                    options.data.dataArr.splice(p, 0, {object_id:object_id});
                }


                base.data.dataArr =  options.data.dataArr;
                base.data.safeCopyArr =  base.data.dataArr;
                base.data.newRow.push(object_id);
                pipe();

            },





            deleteRow : function(index){
                if( base.data.safeCopyArr.length > 1){
                    base.deleteCount ++ ;
                    options.data.dataArr.splice(index, 1);
                    base.data.dataArr =  options.data.dataArr;
                    base.data.safeCopyArr =  base.data.dataArr;
                    var indexNewRow = base.data.newRow.indexOf(base.selected.row_id);
                    if( indexNewRow > -1){

                        base.data.newRow.splice(indexNewRow, 1);


                        if(base.options.gridType === 'batch-save'){
                            var tempObj = {index:index,data:base.selected.curDataRow ,type:'remove'}
                            base.api.callBack('row-added',tempObj,'row-added');
                        }
                    }
                    setFooterDelete(base.deleteCount)
                    pipe();
                }else{
                    addAlert("alert-warn"," You can not delete the last row in a template  ",'3000' );
                }
            },


            createUniqueObjectId : function(_length){
                return base.data.safeCopyArr.length + "_" + randomString(_length);
            },


            createUniqueColId : function(_length){
                return base.data.headerArraySafe.length + "_" + randomString(_length);
            },



            getKeyBoardKeys: function(id){
                var key = 0;
                switch (id) {
                    case 96:
                        key = 0;
                        break;
                    case 97:
                        key = 1;
                        break;
                    case 98:
                        key = 2;
                        break;
                    case 99:
                        key = 3;
                        break;
                    case 100:
                        key = 4;
                        break;
                    case 101:
                        key = 5;
                        break;
                    case 102:
                        key = 6;
                        break;
                    case 103:
                        key = 7;
                        break;
                    case 104:
                        key = 8;
                        break;
                    case 105:
                        key = 9;
                        break;
                    case 106:
                        key = "*";
                        break;
                    case 107:
                        key = "+";
                        break;
                    case 109:
                        key = "-";
                        break;
                    case 110:
                        key = ".";
                        break;
                    case 111:
                        key = "/";
                        break;
                    case 186:
                        key = ";";
                        break;
                    case 187:
                        key = "=";
                        break;
                    case 188:
                        key = ",";
                        break;
                    case 189:
                        key = "-";
                        break;
                    case 190:
                        key = ".";
                        break;
                    case 191:
                        key = "/";
                        break;
                }


                return key;

            }





        }





////////////////////////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////// SANITIZE FUNCTIONS  /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
        function sanitize(input) {
            if( input && input.length && typeof input === 'string' ){
                var output = input.replace(/<script[^>]*?>.*?<\/script>/gi, '').
                replace(/<[\/\!]*?[^<>]*?>/gi, '').
                replace(/<style[^>]*?>.*?<\/style>/gi, '').
                replace(/<![\s\S]*?--[ \t\n\r]*>/gi, '');
                return output;
            }
            return input;
        };
////////////////////////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////// VALIDATION FUNCTIONS  /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


        function isBlank(str) {
            return (!str || /^\s*$/.test(str));
        }


        function isEmpty(str) {
            return (!str || 0 === str.length);
        }

        function isWhiteSpace(str){
            if( IsNumeric(str) ){
                return false;
            }
            if(isBlank(str)){
                return true;
            };


            if(str.charCodeAt(0) === 8){
                return true;
            }

            var string = str.trim();
            return ( string.length === 0);
        }



        function IsNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }


        function IsDate(date) {
            var format =  base.dateFormats[0].value;
            return  moment( Date.parse(date)).isValid(); //;
        }


        function randomString(length) {
            var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
            if (! length) {
                length = Math.floor(Math.random() * chars.length);
            }
            var str = '';
            for (var i = 0; i < length; i++) {
                str += chars[Math.floor(Math.random() * chars.length)];
            }
            return str;
        }

////////////////////////////////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////// TOOLS FUNCTIONS  /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

//TODO REFACTOR FOR ALL CLASSES

        function highlight(_element,color){
            var highLightString = "high-light-"+color;
            base.selected.highLightColor = color;
            _.forEach( base.selectedGroup, function(value, key) {
                highLightElement(value,highLightString,color);
            });
            buildBodyDom();
        }

        function highLightElement(_object,_highLightString,_color){
            if( _object.select_type === 'cell' ){
                var partial_match = "high-light-";
                if( _color == 'none' ) {
                    updateCellClass(_object,_highLightString,'remove','high-light-');
                }else{
                    updateCellClass(_object,_highLightString,'add','high-light-');
                }
            }else if( _object.select_type === 'header' ){
                var curDataCol = _.find(base.data.headerArray, {'map': _object.col_id});
                var removeObj = removePartialString(curDataCol.col_class,'high-light-');
                curDataCol.col_class = removeObj.cssClass;
                if( _color != 'none' ) {
                    curDataCol.col_class += ' ' + _highLightString;
                }

            }else if( _object.select_type === 'row'  ) {
                var curDataRow = _.find(base.data.dataArr, {'object_id': _object.row_id});
                if(! curDataRow.row_class){
                    curDataRow.row_class = "";
                }
                var removeObj = removePartialString(curDataRow.row_class,'high-light-');
                curDataRow.row_class = removeObj.cssClass;
                if( _color != 'none' ) {
                    curDataRow.row_class += ' ' + _highLightString;
                }

            }
        }





        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////    UPDATE CELL CLASS      /////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function removePartialString(_data,_partial){

            if(_data == null ){
                return false;
            }


            var x = _data;
            var n = x .indexOf(_partial);
            var returnObj = {};
            var removedClass = "";

            if( n > 0) {
                var p = x.substring(n, x.length);
                removedClass = p;
                _data =  _data.replace(p, '');
            }

            returnObj.cssClass = _data;
            returnObj.removedClass = removedClass;

            return returnObj;
        }





        function updateCellClass(_object,new_class,_type,_partial_match){

            if(typeof base.cellData[_object.obj_id] == "undefined"){
                base.cellData[_object.obj_id] = [];
                var col_class = _.result(_.find(base.data.headerArray, {'map':_object.col_id}),'col_class');
                base.cellData[_object.obj_id].cssClass = col_class;
            }

            if(_type == 'add' ){
                var removeObj =  removePartialString( base.cellData[_object.obj_id].cssClass ,_partial_match);
                base.cellData[_object.obj_id].cssClass = removeObj.cssClass;
                base.cellData[_object.obj_id].cssClass += ' ' + new_class;
                $(base.selected.element).removeClass(removeObj.removedClass);
                $(base.selected.element).addClass(new_class);

            }else{
                var removeObj =  removePartialString( base.cellData[_object.obj_id].cssClass ,_partial_match);
                base.cellData[_object.obj_id].cssClass = removeObj.cssClass;
                $(_object.element).removeClass(removeObj.removedClass);
            }
        }




        ////// END ///////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// TOOLS ACTION  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


        function toolsAction(_element){
            var action = _element.attr('action');

            // console.log("------------- toolsAction -------------- " , action)
            switch (action) {
                case 'highlight':
                    var color = _element.attr('color')
                    highlight(_element,color);
                    paintColor(color);
                    break;
                case 'full-screen':
                    base.$el.toggleClass('gg-full-screen');
                    // added(by Omi) the below line to adjust modal position on full-screen..
                    (base.$el.hasClass('gg-full-screen')) ? base.$el.find('.gg-col-order-cont').addClass('adjust-modal-full-screen') : base.$el.find('.gg-col-order-cont').removeClass('adjust-modal-full-screen');
                    buildGrid();
                    break;
                case 'new-window':
                    var url= new URL(window.location.href);
                    window.open(url, 'newwindow', 'width='+window.innerWidth+', height='+window.innerHeight);
                   // window.open(window.location.href,'newwindow', 'width='+window.innerWidth+', height='+window.innerHeight);
                    break;
                case 'export' :
                    exportExcel(base.base_url + '/exportToExcel', false);
                    break;
                case 'filtered-export' :
                	exportExcel(base.base_url + '/exportToExcel', true);
                	break;
                case 'paste-xls' :
                    base.pasteXls.paste();
                    break;
                case 'show-hide' :
                    base.headerService.colToolsOpen();
                    break;
                case 'expand-cols' :
                    base.headerService.expandCols();
                    break;
                case 'total-selected' :
                    base.mathFormulas.selectedSum();
                    break;
                case 'batch-action' :
                    base.api.batchAction(_element.attr("value"));
                    break;
                case 'apply-template':
                    base.headerService.colToolsApply(_element.attr("index"));
                    break;
                case 'comment-tools':
                    base.messageService.goToComment();
                    break;
                case 'manage-actions':
                    base.gridActions.launchGridActionModal();
                    break;
                case 'manage-pivot-actions':
                    base.pivotActions.launchPivotActionModal();
                    break;
                case 'format':
                    break;
                case 'actionTemplate':
                    base.actionsService.applyActionTemplate(_element.attr("action_template_key"));
                    break;
                case 'clearActionTemplate':
                    base.actionsService.clearActionTemplate();
                    break;
                case 'expand-collapse-pivot':
                    base.pivotExpandService.expandCollapse();
                break;
            }
            base.focus.gridBody.focus();
        }



        base.mathFormulas = {

            clearTotal: function(){
                base.elements.totalText.html("");
            },

            selectedSum: function () {
                this.clearTotal();
                var value = "";
                var totalObj = {};

                if( base.selected.select_type === "cell" ){
                    totalObj = base.mathFormulas.totalCells();
                }else{
                    totalObj = base.mathFormulas.totalCol();
                }

                var string = "";

                if( totalObj.average != null && !isNaN(totalObj.average)){
                    var average = "";
                    /*
                     if(base.selected.format && base.selected.format.length){
                     average = formatter(totalObj.average,base.selected.format)
                     }else{
                     average = totalObj.average.toFixed(2);
                     }
                     */
                    average = totalObj.average.toFixed(2);
                    string += "Average: " + average + "&nbsp;&nbsp";
                }


                if( totalObj.count ){
                    string += "Count: " + totalObj.count + "&nbsp;&nbsp";
                }


                if( totalObj.sum != null ){
                    var sum = 0;

                    if( Number(totalObj.sum) === totalObj.sum && totalObj.sum % 1 === 0 || totalObj.sum === 0.00){
                        sum = totalObj.sum;
                    }else{
                        sum = totalObj.sum.toFixed(2);
                    }


                    var formatSum = numberWithCommas(sum);


                    string += "Sum: " + formatSum + "&nbsp;&nbsp";
                }


                base.elements.totalText.html(string);


            },

            totalCol :function(){
                var total = "";
                var object = {};
                object.sum = null;
                object.count = null;
                object.average = null;

                console.log("totalCol called")

                if( base.selected.select_type === 'header' ){
                    var total = 0;
                    var count = 0;

                    _.forEach(base.data.dataArr, function (value, key) {
                        _.forEach(base.selectedGroup, function (valueCol, keyCol) {
                            if (typeof value[valueCol.col_id] != "undefined") {
                                if(!isNaN(value[valueCol.col_id]) && valueCol.colData.round_off == '1'){
                                    total += Math.round(parseFloat(value[valueCol.col_id]));
                                }
                                else if(!isNaN(value[valueCol.col_id])){
                                    total += parseFloat(value[valueCol.col_id]);
                                }
                                ++count;
                            }
                        });
                    });

                    object.sum = total;
                    object.count = count;

                    if( object.sum != null ){
                        object.average = object.sum/count;
                    }


                }

                return object;
            },


            totalCells :function(){
                var object = {};
                object.sum = null;
                object.count = null;
                object.average = null;
                if( base.selected.select_type === 'cell' ){

                    _.forEach(base.selectedGroup, function (value, key) {
                        if( typeof value.value != "undefined" ){
                            if(!isNaN(value.value)) {
                                object.sum += parseFloat(value.value);
                            }
                            ++object.count
                        }
                    });

                    object.average = object.sum/object.count;
                }
                return object;
            }



        }

//////////////////////////////////////// AJAX ENGINE  ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


        function loadTemplate(_url,_pramaters,_element){
            $(_element).load( _url, function() {
                //////////////////////////console.log("loadTemplate()")
            });
        }



        //////////////////////////////////////////////////////////////////////////////////////////////////
        //// EXPORT EXCEL FUNCTION : Written By JOY
        //////////////////////////////////////////////////////////////////////////////////////////////////

        /*function exportExcel(_url){
            var action_id = angular.element('#action_id')[0].innerHTML;
            var grid_id = angular.element('#grid_id')[0].innerHTML;
            var sso_id = angular.element('#sso_id')[0].innerHTML;
            var all_filter_data = (angular.element('#all_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            var display_filter_data = (angular.element('#display_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            _url = _url + '?' + 'action_id=' + action_id + '&' + 'grid_id=' + grid_id + '&' + 'sso_id=' + sso_id + all_filter_data + display_filter_data;
            window.open(_url,'blank');
        }*/
        function exportExcel(_url, isFilteredGrid) {
        	addAlert("alert-warn","Please wait until the file downloads", 6000);
            var action_id = angular.element('#action_id')[0].innerHTML;
            var grid_id = angular.element('#grid_id')[0].innerHTML;
            var sso_id = angular.element('#sso_id')[0].innerHTML;
            var all_filter_data = (angular.element('#all_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            var display_filter_data = (angular.element('#display_filter_data')[0].innerHTML).replace(/&amp;/g, '&');
            var display_all_me_code = (angular.element('#display_all_me_code')[0].innerHTML).replace(/&amp;/g, '&');
            var filterArrayAll = all_filter_data.split('&');
            var filterArrayDisplay = display_filter_data.split('&');

             /*dvalaboju001 2/1/2020*/
             var screen_name = angular.element('#screen_name')[0].innerHTML;
            var client_name = angular.element("#client_name")[0].innerHTML;
            var screenObj = angular.element('#screenObj')[0].innerHTML;
            console.log("screen_name",screen_name);
            console.log("client_name",client_name);
            console.log("screenObj",screenObj);

            //Added by Vijay to included the filtered column details
            if(base.tableState.colSearch.length){//Check null, undefined and predicate
           	 	for(var i = 0; i < base.tableState.colSearch.length; i++){
           	 		filterArrayDisplay[filterArrayDisplay.length] = "colFilter_"+base.tableState.colSearch[i].predicate+"="+base.tableState.colSearch[i].value.toString();
                }
            }
            if(display_all_me_code){
            	filterArrayDisplay[filterArrayDisplay.length] = "display_mE_code="+display_all_me_code;
            }
            //---------------------------------------------------------
            var jsonData = {};
            for(var i = 0; i < filterArrayAll.length; i++){
                jsonData[filterArrayAll[i].split('=')[0]] = filterArrayAll[i].split('=')[1];
            }
            for(var i = 0; i < filterArrayDisplay.length; i++){
                jsonData[filterArrayDisplay[i].split('=')[0]] = filterArrayDisplay[i].split('=')[1];
            }
            var _params = {};
            var jsonObj = JSON.stringify(jsonData);
            _url = _url + '?' + 'action_id=' + action_id + '&' + 'grid_id=' + grid_id + '&' + 'sso_id=' + sso_id /*+ all_filter_data + display_filter_data*/;
            _params = _.extend({
                jsonObj: jsonObj
            }, _params);

            //Added by Vijay to included the filtered column details
            if(isFilteredGrid){
            	_params = _.extend({
            		filteredGridData: JSON.stringify(base.data.dataArr)
            	}, _params);
            	_url = _url + '&' + 'filtered_export=' + isFilteredGrid
            }
            //-----------------------------------------------------
            var tableData = JSON.parse(_params.gridData);
            screenObj = JSON.parse(screenObj);
            screenObj['export_date'] = moment().format('MM/DD/YYYY LT');
            var strFilename = screenObj['report_name'].replace(/[^a-zA-Z0-9]/g,'-') + "-" +moment().format('YYYY-MM-DD-hh-mm')+".xlsx";

            /**
             * This is using webworker and exceljs.min.js and file saver. START
             */
            const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (window.Worker) {
                var myWorker = new Worker('./lib/webworker/exportToExcel.js');
                if(isFilteredGrid){
                    screenObj.filters = Object.assign({},screenObj['filters'],...colFilters);
                }
                myWorker.postMessage({tableData,screenObj,headerDisplArr});
                myWorker.onmessage = function(e) {
                    console.log('messgae recived');
                    if(e.data.error){
                        addAlert("alert-error"," Data can not be exported, please contact System administrator",4000 );
                    }
                    const blob = new Blob([e.data],{type: EXCEL_TYPE});
                    try {
                        saveAs(blob, strFilename);
                        myWorker.terminate();
                    }catch(error){
                        addAlert("alert-error"," Data can not be exported, please contact System administrator",4000 );
                    }

                }
            } else {
                console.log('Your browser doesn\'t support web workers.');

                /**
                 * This old api implementation.
                 */

            var promise= $.ajax({
                method: 'post',
                url: base.base_url+_url,
                data: _params
            })
            .done(function( data ) {
                if( data.callSuccess != 1 ){
                    addAlert("alert-error"," Data can not be exported, please contact System administrator",4000 );
                }else{
                    let downloadURL= base.base_url+'/download'+'?'+'file_name='+data.file_name+"&client_key="+sessionStorage.getItem('client_key');
                    window.open(downloadURL,'blank');
                }
               });
            }
        }



////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// CONTEXTMENU FUNCTIONS  /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

        function displayContextMenu(_event){
            var element = base.selected.element;
            buildContextMenu(element);
            positionContextMenu(element,_event)
        }

        function buildContextMenu(){
            var element = base.selected.element;
            var rowobj =  element.attr('rowobj');
            var colmap = element.attr('colobj');
            var currentDataCol = _.find(base.data.headerArray, {'map':colmap} )
            var colobj = currentDataCol.object_id;
            var curDataRow = _.find(base.data.safeCopyArr, {'object_id':rowobj});
            var cellValue =  curDataRow[colmap];
            var cellDisplay = cellValue;
            var auditCells = _.filter(options.data.headerArray,{'audit_cells':"1"}); /* 5/29/2019 */


            var menuBase = $("<ul></ul>");
            base.elements.contextMenu.html("");
            //menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='note' ><a><i class='fa fa-tag'></i> Attach Message</a> <div></div></li>");


            //  menuBase.append("<li role='presentation' class='divider'></li>");

            //////// START: FILTERS ////////////////////////////////

            if( base.batchSelectMode ){

                var badge = (base.batchSelect && base.batchSelect.length > 0) ? '<span class="label label-success label-as-badge">'+base.batchSelect.length+'</span>' : '';
                if(curDataRow.batchSelect){
                    menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='select-row'  ><a><i class='fa fa-square-o'></i> Unselect Row</a> <div></div></li>");
                }else{
                    menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='select-row'  ><a><i class='fa fa-check'></i> Select Row</a> <div></div></li>");
                }

                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='select-all'  ><a><i class='fa fa-check-square-o'></i> Select <b>All</b></a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='un-select-all'  ><a><i class='fa fa-square-o'></i> Unselect <b>All</b> "+badge+" </a>      <div></div></li>");
                menuBase.append("<li role='presentation' class='divider'></li>");


                if( base.batchActions && base.batchActions.length && base.selected.rowData.gridBatchStatus !== 'new') {
                    _.forEach(base.batchActions, function (value, key) {
                        var icon = (value.icon && value.icon != "" ) ? value.icon : "fa fa-dot-circle-o ";
                        var listElement = '<li class="gg-context-action batch-actions" colobj="' + colobj + '"  action="batch-action" value="' + key + '"><a><i class="' + icon + '"></i> ' + value.name + '</a> <div></div></li>'
                        menuBase.append(listElement);
                    })
                    menuBase.append("<li role='presentation' class='divider'></li>");
                }else if( base.selected.rowData.gridBatchStatus && base.selected.rowData.gridBatchStatus === 'new' ){
                    menuBase.append("<li class='gg-context-action   ' colobj='"+colobj+"' style='background-color:#f5857c' action='deleteRow' value='' ><a><i class='fa fa-times'></i> Remove New Row</a> <div></div></li>");
                }



            }






            if( typeof cellValue === "undefined" || cellValue === null || cellValue === ""  || cellValue === " ") {

                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='blank' value='" + cellValue + "' ><a>is blank</a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='not-blank' value='" + cellValue + "' ><a>is not blank </a> <div></div></li>");


            }else if( currentDataCol.data_type === 'number' ){

                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-equals-number' value='"+cellValue+"' ><a>Equals <b>"+cellValue+"</b></a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-not-equals-number' value='"+cellValue+"' ><a>Does Not Equal <b>"+cellValue+"</b></a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-greater' value='"+cellValue+"'> <a>Greater Than <b>"+cellValue+"</b></a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-less' value='"+cellValue+"' ><a>Less Than <b>"+cellValue+"</b></a> <div></div></li>");

            }else{

                if(cellDisplay.length > 29){
                    cellDisplay = cellDisplay.substring(0,29) + "...";
                }

                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-like' value='"+cellValue+"' ><a>Contains <b>"+cellDisplay+"</b></a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='find-not-like' value='"+cellValue+"' ><a>Does Not Contain <b>"+cellDisplay+"</b></a> <div></div></li>");

            }

            var model =  _.filter(base.tableState.colSearch, { predicate: colmap });



            menuBase.append("<li role='presentation' class='divider'></li>");

            if( model != undefined && !_.isEmpty(model) ) {
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='un-filter' value='"+cellValue+"' ><a><i class='fa fa-times'></i> Clear Filter</a> <div></div></li>");
            }


            if( ( currentDataCol.removable || base.options.gridType === 'import')  && cellValue !== undefined ){
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='fill' value='"+cellValue+"' ><a> Fill With: <b>"+cellValue+"</b></a> <div></div></li>");
            }



            if(options.data.settings && options.data.settings.comments){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='add-comment' ><a><i class='fa fa-commenting'></i> Insert Comment</a> <div></div></li>");
            }

           /*  if(options.data.settings && options.data.settings.attachments){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='add-attachment' ><a><i class='fa fa-paperclip'></i> Insert Attachment</a> <div></div></li>");
            }
 */
            /* audit cell options 5/29/2019*/

            if(_.find(auditCells,{'grid_header_key':currentDataCol.grid_header_key}) != undefined){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='audit-cells' ><a><i class='fa fa-history'></i> Audit Cells</a> <div></div></li>");
            }

            /*  */
            if(  currentDataCol.removable  ){
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='removeCol' ><a><i class='fa fa-minus'></i> Remove Column</a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='formatCol' ><a><i class='fa fa-list-alt '></i> Format Column</a> <div></div></li>");

            }


            if( base.options.gridType === 'import' ){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='addRowAbove' value='' ><a><i class='fa fa-plus'></i> Add Row Above</a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='addRowBelow' value='' ><a><i class='fa fa-plus'></i> Add Row Below</a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='deleteRow' value='' ><a><i class='fa fa-minus'></i> Remove Row</a> <div></div></li>");
            }



            if(  base.options.gridType === 'batch-save'  ){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' style='background-color:#8dd2e7' action='copyAddRow' value='' ><a> <i class='fa fa-files-o'></i>  Copy Row</a> <div></div></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='addRowBelow' value='' ><a><i class='fa fa-plus'></i>  Add Blank Row</a> <div></div></li>");
                if( curDataRow.gridBatchStatus &&  curDataRow.gridBatchStatus === 'new') {
                    menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='deleteRow' value='' ><a> <i class='fa fa-times'></i> Remove Row</a> <div></div></li>");
                }

            }



            if( base.options.gridType != 'import'  ) {
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='" + colobj + "' action='addCol' ><a><i class='fa fa-plus'></i> Add Column</a> <div></div></li>");
            }



            //////// END: FILTERS ////////////////////////////////


            if( currentDataCol.map !== 'batchSelect'){
                menuBase.append("<li role='presentation' class='divider'></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='highlight' color='"+base.tools.color+"' ><a> <i style='color:"+base.tools.color+"' class='fa fa-paint-brush'></i> Highlight <div class='color-block "+base.tools.color+"'></div></a></li>");
                menuBase.append("<li class='gg-context-action' colobj='"+colobj+"' action='highlight' color='no-fill' ><a>Clear Highlight &nbsp;<div class='color-block no-fill'></div></a></li>");
            }

            base.elements.contextMenu.append(menuBase);
            base.elements.contextMenu.show();




        }


        function positionContextMenu(_element,_event){

            var positionY  = _event.pageY,
                positionX  = _event.pageX,
                bottomHeight = positionY + base.elements.contextMenu.height(),
                rightWidth = positionX + base.elements.contextMenu.width();
            //Set positionY
            if(bottomHeight > windowHeight){
                var offsetY = (bottomHeight - windowHeight) + 2;
                positionY = positionY - offsetY;
            }

            //Set positionX
            if( rightWidth >  windowWidth){
                var offsetX = (rightWidth - windowWidth) + 10;
                positionX = positionX - offsetX;
            }

            base.elements.contextMenu.css('top',positionY+'px');
            base.elements.contextMenu.css('left',positionX+'px');

        }




        function hideContextMenu(){

            if( base.elements.contextMenu.is(":visible")) {
                base.elements.contextMenu.hide();
                base.focus.gridBody.focus();
            }



        }





////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// ALERT FUNCTIONS  ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////



        base.alertArrayIndex = 0;

        function addAlert(_type,_message,_timeout){
            var alert_id = ++base.alertArrayIndex;
            var alertObject = {alert_id:alert_id, type:_type, message:_message, timeOut:_timeout, visible:false};
            renderAlert(alertObject);
            if(_timeout !== undefined ){
                setTimeout(function() { removeAlert(alert_id,'timeOut'); }, _timeout);
            }

        }

        function removeAlert(_alert_id,_action){
            var alert =  $(".gg-alert[alert-id='" + _alert_id + "']");
            if(_action == 'timeOut'){
                alert.fadeOut("normal", function() {
                    alert.remove();
                });
            }else{
                alert.remove();
            }

        };


        function renderAlert(_object){
            var $alert = $('<div class="gg-alert alert alert-dismissible fade in" role="alert"><button type="button" class="close gg-alert-close">×</button><div class="gg-alert-message"></div></div>');
            $alert.addClass(_object.type);
            $alert.attr('alert-id',_object.alert_id);
            $alert.find('.gg-alert-message').html(_object.message);
            base.elements.alertContainer.append($alert);

        }


        /* */


////| END: AJAX ENGINE  | //////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// TEMPLATES  ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
        var colSizeHandlerTemplate = $("<div class='resize-col-line'><div class='resize-col-icon'></div></div>"),


            refreshIconTemplate = $("<div class='gg-refresh-icon'><img src='assets/img/loader-yellow-slow.svg' width='60' height='60'> <p>Refreshing Data</p></div>"),


            colFixedHandlerTemplate = '<div class="fixed-col-handler"></div>',


            hiddenTextAreaTemplate = $('<div class="gg-hidden-ta-cnt"><textarea class="gg-hidden-textarea"></textarea></div>'),



            largeTextDisplay = $('<div class="gg-lg-text-cnt"><div class="gg-lg-text"></div><div class="gg-lg-text-btns"><div class="btn btn-info btn-xs gg-lg-text-edit" >Edit</div></div></div>'),





            modalFilterTemplate = $('<div class="gg-modal-filter">'+
                '<div class="gg-modal-header">'+
                '<div class="gg-title">Show Results Where</div><i action="close"  class="fa fa-times modal-action"></i>'+
                '</div>'+
                '<div class="gg-modal-ggBody form-group">'+
                '<div id="gg-modal-select">'+
                '</div>'+
                '<div class="gg-divider"></div>'+
                '<input type="text" class="gg-modal-input input-sm form-control" name="value" placeholder="enter search">'+
                '</div>'+

                '<div class="gg-modal-footer">'+
                '<div action="submit" class="gg-modal-action btn btn-xs btn-info">Ok</div>'+
                '<div action="close" style="margin-left:5px" class="gg-modal-action btn btn-xs btn-danger">Cancel</div>'+
                '</div>'+
                '</div>'),


            modalTemplate = $('<div class="gg-modal-container">'+
                '<div class="gg-modal-header">'+
                '<div class="gg-title"></div><div class="gg-close"><i action="close"  class="fa fa-times gg-modal-action"></i></div>'+
                '</div>'+
                '<div class="gg-modal-ggBody">'+
                '</div>'+
                '<div class="gg-modal-footer">'+
                '<div action="" class="gg-modal-action btn btn-xs btn-info">Ok</div>'+
                '<div action="close" style="margin-left:5px" class="gg-modal-action btn btn-xs btn-danger">Cancel</div>'+
                '</div>'+
                '</div>'),




            colFormatTemplate = '<select name="select" class="input-sm form-control gg-modal-select">'+
                '<option value="string">String</option>'+
                '<option value="number">Number</option>'+
                '<option value="date">Date</option>'+
                '</select>',




            modalFilterNumber = '<select name="select" class="input-sm form-control gg-modal-select">'+
                '<option value="=">Equals </option>'+
                '<option value="!=">Does <u>Not</u> Equal</option>'+
                '<option value=":">Contains</option>'+
                '<option value="!:">Does <u>Not</u> Contains</option>'+
                '<option value=">=">Greater Than</option>'+
                '<option value="<=">Less Than</option>'+
                '<option value="blank">Is Blank</option>'+
                '<option value="!blank">Is <u>Not</u> Blank</option>'+
                '</select>',

            modalFilterString = '<select name="select" class="input-sm form-control gg-modal-select">'+
                '<option value="=">Equals </option>'+
                '<option value="!=">Does <u>Not</u> Equal</option>'+
                '<option value=":">Contains</option>'+
                '<option value="!:">Does <u>Not</u> Contains</option>'+
                '<option value="blank">Is Blank</option>'+
                '<option value="!blank">Is <u>Not</u> Blank</option>'+
                '</select>',

            modalFilterDate = '<select name="select" class="input-sm form-control gg-modal-select">'+
                '<option value="=">NEED TO ADD </option>'+
                '</select>',


            rowSizeHandlerTemplate = $("<div class='resize-row-line'><div class='resize-row-icon'></div></div>"),

            //--unused--//alertTemplate = $('<div class="gg-alert alert  alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button><div class="gg-alert-message"></div></div>'),

            colReorderHandlerTemplate = $("<div class='reorder-col-block'></div>"),

            colIndicatorTemplate = $("<div class='col-line'></div>"),

            editTemplate = $("<div class='edit-grid-value'></div>"),

            inputText = $('<input type="text" name="currentValue" class="gg-input-text">'),



            checkInput =  $('<input type="checkbox" name="currentCheck" class="gg-input-check">'),



            contextMenuTemplate = $('<div class="gg-context-menu dropdown" style="background-color:rgba(255, 255, 255, 0.9)" ></div>'),

             errorItemTemplate =//$('<div style="display:none" class="gg-error-container error-popup">'+
                                 $('<div style="display:none" class="gg-error-container">'+
                                 '<div class="gg-list-container"></div>'+
                                 '<div class="gg-message-detail-backdrop"></div>'+
                                 '<div class="gg-detail-container"></div>'+
                                 '</div>'),


            messageItemTemplate = $('<div style="display:none " class="gg-message-container">' +
                '<div class="gg-list-container"></div>' +
                '<div class="gg-message-detail-backdrop"></div>'+
                '<div class="gg-detail-container"></div>' +
                '</div>'),
            loadingTemplate = $(' <div class="gtw-web-components gg-loader">' +
            // '<div class="gg-loader-text"> ' +
            //  '<img src="assets/img/ge-logo-loader-dark-bg.svg" width="70" height="70">' +
            //  '<p>fetching data</p> ' +


            // '<div class="gt-loader-logo"> ' +
            // ' <img class="loader" src="assets/img/loader-yellow-slow.svg" width="140" height="140">' +
            // '<img class="logo" src="assets/img/logo-60.png" >' +
            // ' <div class="text">fetching data</div>' +
            // '</div>' +



            // '</div> ' +
            '<div class="gg-loader-text viewport-center"><div class="d-inline-block a-p-20 text-center rounded"><span class="a-loading loading-large a-dark"></span></div></div>' +
            '</div>'),


            selectFiltersTemplate = $(' <div class="gg-loader"> <div class="gg-loader-text"> ' +
                '<img src="assets/img/arrow-filter-sm.png" >' +
                '</div> ' +
                '</div>'),

            dropDstDownTemplate = '<div class="stopEvent gg-dropMenu dropdown" style="position:relative;"> '+
            '<a class="btn btn-super-sm btn-default dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a> '+
            '<ul class="dropdown-menu gg-menu-container">'+
            '<li  id="dropDstDownFilters" action="string">'+
            '</li> </ul>',


            selectFiltersTemplate = $(' <div class="gg-loader"> <div class="gg-loader-text"> ' +
                '<img src="assets/img/arrow-filter-sm.png" >' +
                '</div> ' +
                '</div>'),

            dropDownTemplate = '<div class="stopEvent gg-dropMenu dropdown" style="position:relative;"> '+
                '<a class="btn btn-super-sm btn-default dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a> '+
                '<ul class="dropdown-menu gg-menu-container">'+
                '<li class="gg-menu-action" action="select-all"><a> <i class="fa fa-check-square-o"></i> Select All </a></li> '+
                '<li class="gg-menu-action" action="un-select-all"><a> <i class="fa fa-square-o"></i>  Un-select All </li> '+
                '<li role="presentation" class="divider"></li> '+
                '<li class="gg-menu-action" action="sort-a-z"><a>Sort A <i class="fa fa-long-arrow-right"></i> Z</a></li> '+
                '<li class="gg-menu-action" action="sort-z-a"><a>Sort Z <i class="fa fa-long-arrow-right"></i> A</a></li> '+
                '<li class="gg-menu-action gg-remove-sort" action="un-sort"><a>Remove Sort</a></li> '+
                '<li role="presentation" class="divider"></li> '+
                '<li class="gg-menu-action" action="addCol"><a>Add Column</a></li>'+
                '<li class="gg-menu-action" action="removeCol"><a>Remove Column</a></li>'+
                '<li class="gg-menu-action" action="formatCol"><a>Format Column</a></li>'+


                '<li role="presentation" class="divider"></li> '+
                '<li class="gg-menu-action gg-menu-highlight" action="highlightCol"><a>Highlight Column</a></li> '+
                '<li role="presentation" class="divider"></li> '+
                '<li class="gg-menu-action gg-remove-filter" action="un-filter"><a>Remove Filter</a></li> '+
                '<li class="gg-menu-action" id="dropDownFilters" action="string">'+
                '</li> '+
                '<li role="presentation" class="divider"></li> '+
                '<li> ' +
                '<div class="gg-multi-select-container"> ' +
                '<div class="gg-multi-select-search"><i class="fa fa-search"></i> <textarea type="text" placeholder="type or paste from excel" class="gg-multi-select-input" id="multiSelectFilter" name="multiSelectFilter" ></textarea></div> ' +
                '<div class="gg-multi-select" style="resize: both;"> ' +
                '</div> ' +
                '<div class="gg-multi-select-btns"> ' +
                '<div class="btn btn-info btn-xs gg-multi-select-apply">Ok</div> ' +
                '<div class="btn btn-warning btn-xs gg-multi-select-cancel">Cancel</div> ' +
                '</div> ' +
                '</div> ' +
                '</li> '+
                '</ul> '+
                '</div> ',

            mainTemplate = '';



//// ++ END ++////////////////////////////////// TEMPLATES  ////////////////////////////////////////////////////





        function positionMenu(_element,_position){

            var positionX  = _position.offset().left + _position.width(),
                positionY  = _position.offset().top

            _element.css('top',positionY+'px');
            _element.css('left',positionX+'px');

        }



        function positionDropDownAction(_element){

            //_element.css('display','px');


            var menuLeft = _element.width() + _element.offset().left,
                menuBottom = _element.height() + _element.offset().top ,
                bodyBottom = base.elements.body.offset().top + base.elements.body.height() - 50;


            if( menuLeft > base.$el.width()  ){
                _element.css('left',-_element.width()+'px')
            }


            if( menuBottom > bodyBottom  ){
                _element.css('height',bodyBottom+'px');
                _element.scrollTop(1).scrollTop(0);
            }

        }



////////////////////////////////////////////////////////.parent////////////////////////////////////////////
//////////////////////////////////////// PUBLIC FUNCTIONS /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////




        base.positionMenu = function(_element){
            setTimeout(function(){
                positionDropDownAction(_element);
            }, 1);

        }


        base.arrowMove = function(_direction){
            arrowMove(_direction);
        }

        base.pageUp = function(){
            pageUp();
        }


        base.pageDown = function(){
            pageDown();
        }


        base.gotoLineNumber = function(_val){
            setVScroll(_val);
        }


        base.resetHScroll = function(){
            resetHScroll();
        }


        //base.elements.messageItemContainer


        base.closeContextMenu = function(_element){
            hideContextMenu(_element)
        }


        base.contextmenu = function(_event){
            displayContextMenu(_event)
        }

        base.removeAlert = function(_element){
            removeAlert(_element.parent().attr('alert-id'),'click');
        }


        base.toolsAction = function(_element){
            toolsAction(_element);
        }

        base.selectRow = function(_element,_ctrlClick){
            selectRow(_element,_ctrlClick);
        }





        base.clearFilter = function(_action,_map){
            clearFilter(_action,_map);
        }


        base.clearAll = function(){
            //////////////////////////////console.log(_value);
            clearAll();
        }

        base.globalSearch = function(_value){
            //////////////////////////////console.log(_value);
            globalSearch(_value);
        }

        base.columnSearch = function(_col_id,_value){
            var curDataCol = _.find(base.data.headerArray, {'object_id':_col_id});
            colSearchText(curDataCol.map,_value,":",'string');
            ////////////////console.log("_col_id " , _col_id);
        }


        base.selectCell = function(element,event){
            selectCell(element,'cell',event);
        }

        base.selectCol = function(element,_ctrlClick){
            selectCol(element,_ctrlClick);
        }

        base.dropdownAction = function(action,element,input,col_id){
            var curDataCol = _.find(base.data.headerArray, {'object_id':col_id});
            ////////////////console.log("curDataCol: " , curDataCol);
            var map = curDataCol.map;
            ////////////////console.log("map: " , map);

            dropdownAction(action,element,input,curDataCol,map);


        };

        base.sort = function(e){
            sort(e);
        };

        base.dragFixedColHandlerEnd = function(e){
            dragFixedColHandlerEnd(e);
        };

        base.dragFixedColHandler = function(e){
            dragFixedColHandler(e);
        };

        base.dragReorderColHandlerEnd = function(e){
            dragReorderColHandlerEnd(e);
        };

        base.dragReorderColHandler = function(e){
            dragReorderColHandler(e);
        };

        base.dragRowHandlerEnd = function(e){
            dragRowHandlerEnd(e);
        };

        base.dragRowHandler = function(e){
            dragRowHandler(e);
        };

        base.dragColHandlerEnd = function(e){
            dragColHandlerEnd(e);
        };

        base.dragColHandler = function(e){
            dragColHandler(e);
        };

        base.resize = function(e) {


            if(base != null){

                if(base != null && base.loader.visible ){
                    base.gridFiltersDivHeight = 0;// need to make this to 0,otherwise white space gets added to grid bottom on window resize/showLoader
                    base.loader.setSize();
                }else{
                    buildGrid();
                }

            }


        };

        base.onHorzScrollOff = function(e){
            onHorzScrollOff(e);
        }


        base.hScrollDrag = function(e){
            onHorzScroll(e);
        }


        base.vScrollDrag = function(e){
            onScroll(e);
        }


        base.scrollDown = function(){
            if(base.data.dataArr && base.data.dataArr.length > 0){
                scrollDown();
            }


        };

        base.scrollUp = function(){
            if(base.data.dataArr && base.data.dataArr.length > 0){
                scrollUp();
            }
        };

        base.scrollLeft = function(){

            scrollLeft();

        }

        base.scrollRight = function(){
            scrollRight();
        }

        base.debug = function(e) {
            debug(e);
        };

        base.init()
    };



////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// Default Options /// /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

    $.gizmoGrid.defaultOptions = {
        fixedColumn: 1,
        defaultRowHeight: 30,
        defaultColWidth: 100,
        minColWidth:50,
        minRowHeight:30,
        vDraggerMinHeight:30,
        vClickThroughNum:1,
        rowBuffer:1,
        colBuffer:1,
        maxUniqueColFilters:2700,
        gridType:'standard',
        maxXlsRows:1000,
        profileImageUrl:"https://colab.gecdn.com/profile_images/86/",
        profileImageExtension:".jpg",
        callBack: function () {}
    }

////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// PUBLIC EVENTS /// /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

    $.fn.gizmoGrid = function(options){
        return this.each(function(){
            var gg = new $.gizmoGrid(this, options);
            var interval = null;

            var element = $(this);
            //EXPOSE THE PUBLIC API
            element.data('gizmoGridApi', gg.api);

            gg.$el.on('click', '.gg-lg-text-edit' ,function(event){
                gg.largeText.edit();
            });

            gg.$el.on('click', '.gg-lg-text-save' ,function(event){
                gg.largeText.save();
            });

            gg.$el.on('click', '.gg-lg-text-cancel' ,function(event){
                gg.largeText.cancel();
            });



            ////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////       headerService      //////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////

            gg.headerService.overlayElement.on('click',function(event){
                gg.headerService.colToolsClose();
            });


            gg.$el.on('click', '.gg-col-reset' ,function(event){
                gg.headerService.colToolTemplateReset(0);
            });


            gg.$el.on('click', '.gg-col-show-all' ,function(event){
                gg.headerService.checkAll();
            });

            gg.$el.on('click', '.gg-col-hide-all' ,function(event){
                gg.headerService.uncheckAll();
            });

            gg.$el.on('change', '.gg-col-select' ,function(event){
                // gg.headerService.colToolsSave();
                var val = $(this).val();
                gg.headerService.colToolsApply(val);
            });


            //Handels all btn actions in gg.headerService.colTools
            gg.$el.on('click', '.gg-col-action' ,function(event){
                var attr = $(this).attr( "action" )
                gg.headerService.colToolTemplateControls(attr);
            });

            gg.$el.on('keyup' , '#gg-col-temp-input',function(e){
                var code = e.keyCode || e.which;
                if(code == 13){
                    gg.headerService.colToolTemplateControls('save');
                }
            });

            gg.$el.on('keyup' , '#gg-col-update-input',function(e){
                var code = e.keyCode || e.which;
                if(code == 13){
                    gg.headerService.colToolTemplateControls('update');
                }
            });






            gg.$el.on('click', '.gg-col-close' ,function(event){
                gg.headerService.colToolsClose();
            });


            gg.$el.on('click', '.gg-comment-display' ,function(event){
                event.originalEvent.preventDefault();
                var element = $(this)
                gg.messageService.setMessageLevel(element.attr( "action" ));
            });

            gg.$el.on('click', '.gg-comment-filter' ,function(event){
                event.originalEvent.preventDefault();
                var element = $(this)
                gg.messageService.toggleCommentRows();
            });

            gg.$el.on('click', '.gg-attachment-filter' ,function(event){
                event.originalEvent.preventDefault();
                var element = $(this)
                gg.messageService.toggleAttachmentRows();
            });


            gg.$el.on('click', '.gg-override-filter' ,function(event){
                event.originalEvent.preventDefault();
                var element = $(this)
                gg.messageService.toggleOverrideCellstRows();
            });

            /************************dvalaboju001 11/14/2019****************** */

            gg.$el.on('click', '.gg-export-action' ,function(event){
                event.originalEvent.preventDefault();
                var element = $(this);
                var action = $(this).attr('action');
                var filtered = JSON.parse($(this).attr('filtered'));
                gg.exportToExcel.exportExcel('/exportToExcel', filtered);
            });


            gg.$el.on('mousedown', '.gg-col-tools-header' ,function(event){
                var element = $(this)
                event.originalEvent.preventDefault();
                var evtobj= window.event? event : e;
                var cloneElement = element.clone();
                element.addClass('gg-col-header-alpha');
                if(evtobj.ctrlKey){
                    gg.headerService.addDragElement(cloneElement);
                    return false
                }else{
                    gg.headerService.addDragElement(cloneElement);
                    dragToolCol(event);
                }

                $(window).bind('mousemove',  dragToolCol);
                $(window).bind('mouseup', unBindDragToolCol);

            });


            gg.$el.on('mousedown', '.gg-check-show-hide' ,function(event){
                event.stopPropagation();
                event.preventDefault();
                ////console.log(this);
                var map = $(this).parent().attr('map');
                var display = $(this).parent().attr('display');
                gg.headerService.hideShowCol(map,display)
            });




            function dragToolCol(event){
                ////console.log('event' , event);
                var left = event.pageX;
                var top = event.pageY;

                $(".gg-col-tools-header" ).removeClass( "gg-target-element" );
                gg.headerService.dragColTool(left,top);


                var target = $(event.target);
                if ( target.is( "i" ) ) {
                    target.addClass('gg-target-element')
                } else if  (target.is( "div.gg-col-tools-header" )) {
                    target.addClass('gg-target-element')
                }

            }

            function unBindDragToolCol(event){
                $(window).unbind('mousemove', dragToolCol);
                $(window).unbind('mouseup', unBindDragToolCol);

                ////console.log('event.target' , event.target);

                var target = $(event.target);
                var map = "";
                var display = "";

                if ( target.is( "i" ) ) {
                    target = target.parent();
                    map = target.attr('map');
                    display = target.attr('display');
                } else if  (target.is( "div.gg-col-tools-header" )) {
                    map = target.attr('map');
                    display = target.attr('display');
                } else{
                    target == null;
                }


                ////console.log(target);
                ////console.log("map " , map);
                ////console.log("display " , display);

                gg.headerService.dropColTool(map,display);

            }





            ////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////


            /////////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////// .div-col SELECT /////////////////////////////////////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////////////////////

            gg.$el.on('mousedown', '.div-col' ,function(event){
                var element = event.currentTarget;

                event.originalEvent.preventDefault();

                setTimeout(function(){
                    gg.renderSelected.clearAll();
                    gg.selectCell(element,event);

                    $(window).bind('mousemove', cellSelectHandler);
                    $(window).bind('mouseup', unBindCellSelect);


                    if(gg.preventDefaultContext) {
                        if (event.which === 3) {
                            gg.contextmenu(event);
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    }
                    gg.$el.find('.gg-body').focus();
                }, 10);
            });


            function cellSelectHandler(event){
                var target = $( event.target );
                var fromCellRow = gg.selected.row_id;
                var fromCellCol = gg.selected.col_id;
                if ( target.is( ".div-col-data" ) ) {
                    target = $( event.target ).parent()
                    var toCellRow = target.attr('rowobj');
                    var toCellCol = target.attr('colobj');
                    gg.renderSelected.selectCellGroup(fromCellRow,fromCellCol,toCellRow,toCellCol);
                }else if( target.is( ".div-col" )  ){
                    var toCellRow = target.attr('rowobj');
                    var toCellCol = target.attr('colobj');
                    gg.renderSelected.selectCellGroup(fromCellRow,fromCellCol,toCellRow,toCellCol);
                }

                return true;
            }



            function unBindCellSelect(event){
                //////console.log("UNBIND")
                $(window).unbind('mousemove', cellSelectHandler);
                $(window).unbind('mouseup', unBindCellSelect);
                gg.renderSelected.setCellGroup();
            }





            /////////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////// .div-col-prime SELECT /////////////////////////////////////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////////////////////

            gg.$el.on('mousedown', '.div-col-prime' ,function(event){
                event.originalEvent.preventDefault();
                $(window).bind('mousemove', rowSelectHandler);
                $(window).bind('mouseup', unBindRowSelectDrag);
                gg.selectRow($(this),false);
                gg.renderSelected.selectRowGroup(gg.selected.row_id,gg.selected.row_id);
            });


            /*
             gg.$el.on('click', '.div-col-prime' ,function(event){

             if (event.ctrlKey) {
             gg.selectRow($(this), true);
             } else if(event.shiftKey){
             }else{
             gg.selectRow($(this),false);
             }

             });

             */




            function rowSelectHandler(event){
                var target = $( event.target );
                var fromRow = gg.selected.row_id;
                if ( target.is( ".div-col-data" ) ) {
                    target = $( event.target ).parent()
                    var toRow = target.attr('rowobj');
                    gg.renderSelected.selectRowGroup(fromRow,toRow);
                }else if( target.is( ".div-col-prime" )  ){
                    var toRow = target.attr('rowobj');
                    gg.renderSelected.selectRowGroup(fromRow,toRow);
                }


                //gg.scrollDown(event);

                //TODO: CREATE TICKER ------------------------------
                var top = gg.elements.body.offset().top;
                var bottom = gg.elements.body.height() + gg.elements.body.offset().top;

                if( event.pageY > bottom ){
                    gg.scrollDown(event);
                }

                if( event.pageY < top ){
                    gg.scrollUp(event);
                }


                return true;

            }

            function unBindRowSelectDrag(event){
                // event.data.element.removeClass('gg-btn-mouse-down');
                //////console.log("UNBIND")
                $(window).unbind('mousemove', rowSelectHandler);
                $(window).unbind('mouseup', unBindRowSelectDrag);

                gg.renderSelected.setSelectedGroup();
            }


            /////////////// END DRAG MODAL ///////////////////////////////////////////////////////////////////////////





            gg.$el.on('click', '.gg-check-click' ,function(event){
                gg.edit.checkAreaEdit();
            });



            ///////////////////////////////// START  V-TRACK /////////////////////////////////////
            gg.elements.vtrack.mousedown(function(e) {
                clearInterval(interval);
                if( $(e.target).attr('class') === 'vScroll-track'){
                    var thisEvent = e;
                    gg.scroll.trackVScroll(thisEvent);
                    interval = setInterval(function(e){
                        gg.scroll.trackVScroll(thisEvent);
                    },100)
                    var element =  $(this);
                    $(window).bind('mouseup',{element:element}, unBindVtrackVScroll);
                }else{
                    ////////console.log("called unbind")
                    $(window).unbind('mouseup', unBindVtrackVScroll);
                }
            })

            function unBindVtrackVScroll(event){
                clearInterval(interval);
                $(window).unbind('mouseup', unBindVtrackVScroll);
            }

            ///////////////////////////////// END  V-TRACK /////////////////////////////////////




            ///////////////////////////////// START  H-TRACK /////////////////////////////////////

            gg.elements.htrack.mousedown(function(e) {
                clearInterval(interval);
                if( $(e.target).attr('class') === 'hScroll-track'){
                    var thisEvent = e;
                    gg.scroll.trackHScroll(thisEvent);
                    interval = setInterval(function(e){
                        gg.scroll.trackHScroll(thisEvent);
                    },100)
                    var element =  $(this);
                    $(window).bind('mouseup',{element:element}, unBindHtrackHScroll);
                }else{
                    ////////console.log("called unbind")
                    $(window).unbind('mouseup', unBindHtrackHScroll);
                }
            })

            function unBindHtrackHScroll(event){
                clearInterval(interval);
                $(window).unbind('mouseup', unBindHtrackHScroll);
            }

            ///////////////////////////////// END  H-TRACK /////////////////////////////////////


            // CLOSE ALL OFF CLICKS
            gg.$el.on('click' ,function(event){
                if(event.which !== 3 ) {
                    gg.closeContextMenu();
                }
                gg.$el.find('.gg-drop-down-menu').removeClass('open');
            });


            gg.$el.on('click', '.open-input' ,function(event){
                var map = $(this).closest('.headerCol').attr('map');
                var action = $(this).attr('action');
                gg.FilterModalService.openFilterModalService(map,action);
            });


            //TODO: REMOVE
            /*gg.$el.on('click', '.modal-action' ,function(event){
                var action = $(this).attr('action');
                var map = $(this).closest('.gg-modal-filter').attr('map');
                switch (action) {
                    case 'close':
                        gg.FilterModalService.closeFilterModalService(map,action);
                        break;
                    case 'submit':
                        gg.FilterModalService.filter(map,action);
                        break;
                }
                //closeFilterModalService
            });*/


            gg.$el.on('click', '.gg-modal-action' ,function(event){
                var action = $(this).attr('action');
                var map = $(this).closest('.gg-modal-filter').attr('map');
                switch (action) {
                    case 'close':
                        gg.ModalService.closeModalService(map,action);
                        break;
                    case 'submit':
                        gg.FilterModalService.filter(map,action);
                        break;
                    case 'formatCol':
                        var select = gg.elements.modalContainer.find('.gg-modal-select').val();
                        var name = gg.elements.modalContainer.find('.gg-modal-input').val();
                        var thisMap = $(this).attr('map');
                        var currentDataCol = _.find(gg.data.headerArray, {'map':thisMap} )
                        gg.headerService.formatCol(currentDataCol,select,name)
                        gg.ModalService.closeModalService(map,action);
                        break;
                }
                //closeFilterModalService
            });





            ////////////////////////////////////////////////////////////////////////////////////////
            /// : START Multi-Filter  //////////////////////////////////////////////////////////////

            gg.$el.on('click', '.gg-dropMenu .dropdown-toggle' ,function(event){

                var parent = $(this).parent(),
                    element = parent.find('.gg-menu-container');
                var map =  parent.parent().attr('map');
                //RESET SEARCH FILTER
                parent.find('.gg-multi-select-input').val('');
                gg.positionMenu(element);

                if( !element.is(':visible') ){
                    gg.uniqueFilters.buildMenu(map,gg.data.dataArr,'','',true);
                }else{
                    gg.focus.gridBody.focus();
                }
            });


            gg.$el.on('keyup' , '.gg-multi-select-input',function(e){
                var code = e.keyCode || e.which;
                if(code == 13){
                    var map = $(this).closest('.headerCol').attr('map');
                    gg.uniqueFilters.applyFilter(map);
                    gg.focus.gridBody.focus();
                }
            });


            gg.$el.on('click', '.gg-multi-select-cancel' ,function(event){
                gg.focus.gridBody.focus();
            });



            gg.$el.on('click', '.gg-multi-select-apply' ,function(e){
                var map = $(this).closest('.headerCol').attr('map');
                gg.uniqueFilters.applyFilter(map);
                gg.focus.gridBody.focus();
            });


            gg.$el.on('keyup change', '.gg-multi-select-input' ,function(event){
                var searchString = $(this).val();
                var map = $(this).closest('.headerCol').attr('map');
                gg.uniqueFilters.buildMenu(map,gg.data.dataArr,searchString,true,false);
                event.stopPropagation();

            });

            gg.$el.on('click', '.gg-multi-checkbox_all' ,function(e){
                var map = $(this).closest('.headerCol').attr('map');
                var searchString = $(this).parent().parent().parent().find('.gg-multi-select-input').val();
                if( $(this).is(':checked')){
                    gg.uniqueFilters.buildMenu(map,gg.data.dataArr,searchString,true,false);
                }else{
                    gg.uniqueFilters.buildMenu(map,gg.data.dataArr,searchString,false,false);
                }
            });

            gg.$el.on('click', '.gg-multi-checkbox' ,function(event){
                var map = $(this).closest('.headerCol').attr('map');
                if( $(this).is(':checked') === false ){
                    $(".gg-multi-checkbox_all").prop('checked', false);
                    gg.uniqueFilters.removeSelected(map,$(this).val());
                }else{
                    gg.uniqueFilters.addSelected(map,$(this).val());
                }
            });

            gg.$el.on('click', '.gg-multi-select' ,function(event){
                event.stopPropagation();
            });

	        // gg.$el.on('click', '.gg-dropMenu .dropdown-menu' ,function(event){
            //     event.stopPropagation();
            // });
            /// : END Multi-Filter  //////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////

            gg.$el.on('click', '.gg-column-click' ,function(event){
                var element = $(event.currentTarget);
                var parent = element.parent();
                var col_id = element.closest('.gg-dropMenu').attr('colObj');
                var inputVal = parent.find('.gg-column-search');
                gg.columnSearch(col_id, inputVal.val());
            })


            gg.$el.on('keydown', '.go-to-input' ,function(event){
                event.stopPropagation();
                if(event.which == 13) {
                    event.stopPropagation()
                    var val = $(this).val();
                    gg.gotoLineNumber(val);
                    $(this).val("");
                }
            });


            gg.$el.on('keydown', '.gg-input-text' ,function(event){
                if(event.which == 13) {
                    $('.gg-input-text', gg.$el).blur();
                }
            });


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// COPY PASTE HANDLERS  /////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////



            gg.$el.on('keydown',function(e){
                var evtobj= window.event? event : e
                var className = document.activeElement.getAttribute("class");
                var code = e.keyCode || e.which;



                //console.log("evtobj " , evtobj);
                //console.log("className " , className);
                //console.log("code " , code);
                //console.log("evtobj.metaKey " , evtobj.metaKey);


                if( (evtobj.ctrlKey  || evtobj.metaKey)    && ( className == 'gg-body' || className == 'gg-hidden-textarea' 
                    || className=='gg-body isPivot-gridLayout' || className=='gg-body isPivotExpand-gridLayout')) {





                    //console.log("DID WE MAKE IT IN HERE ");

                    gg.elements.hiddenTA.val("");
                    gg.elements.hiddenTA.focus();


                    if(  code === 67 ){
                        gg.elements.hiddenTA.val(gg.edit.copy());
                        gg.elements.hiddenTA.select();
                        var successful = document.execCommand('copy');
                        // gg.elements.hiddenTA.val("");

                    }

                    gg.elements.hiddenTA.bind("keyup", function (e) {
                        if( code === 86){
                            var data = gg.elements.hiddenTA.val().trim();
                            gg.elements.hiddenTA.val("");


                            //Check to see if this is on cell or many
                            var rows = data.split("\n").length;
                            var cells = data.split("\t").length;
                            if(rows > 1 || cells > 1 ){
                                gg.pasteXls.processXls(data);
                            }else{
                                gg.edit.pasteCell(data);
                            }



                        }

                        gg.focus.gridBody.focus();
                        gg.elements.hiddenTA.unbind('keyup');
                    });
                }
            });




            gg.$el.on('click', '.endCap' ,function(e){
                //////console.log("test");

                gg.renderSelected.selectedAll();

            });



            // working
            gg.$el.on('mousedown', '.gg-input-text' ,function(event){

                if( event.which === 3 ){
                    //////////console.log("mouse down mouse down ")
                    gg.edit.setValue($(this).val());

                    gg.elements.editValue.hide();
                    gg.elements.editValue.html("");
                    gg.contextmenu(event);

                    var element = event.currentTarget;



                    //base.elements.editValue.css("top","0px");

                    //event.stopPropagation();
                }
            })



            gg.$el.on('keydown' , '.gg-body, .gg-body.isPivotExpand-gridLayout, .gg-body.isPivot-gridLayout' ,function(e){
                var className = document.activeElement.getAttribute("class");
                var code = e.keyCode || e.which;
                var evtobj= window.event? event : e
                e.preventDefault();

                var keyDownDebounce = _.debounce(function(e) {

                    e.stopPropagation();
                    e.preventDefault();



                    console.log("code ---is this being called className  " , className);
                    //console.log("evtobj " , evtobj);
                    if(className == 'gg-body' || className == 'gg-body isPivotExpand-gridLayout' 
                        || className == 'gg-body isPivot-gridLayout'){
                        if( _.inRange(parseInt(code),112,124) ){
                            ////////console.log("f-keys");
                        }else if( _.inRange(parseInt(code),146,185) ) {
                            ////////console.log("uknown-keys");
                        }else {
    
    
                            switch (code) {
                                case 18:
                                    ////////console.log("18");
                                    break;
                                case 17:
                                    ////////console.log("17");
                                    break;
                                case 91:
                                    ////////console.log("17");
                                    break;
    
                                case 38:
                                    gg.arrowMove("up");
                                    break;
    
                                case 40:
                                    gg.arrowMove("down");
                                    break;
    
                                case 37:
                                    gg.arrowMove("left");
                                    break;
    
                                case 39:
                                    gg.arrowMove("right");
                                    break;
    
                                case 33:
                                    gg.pageUp();
                                    break;
    
                                case 34:
                                    gg.pageDown();
                                    break;
    
                                case 17:
                                    gg.edit.keyDown(e, true);
                                    break;
    
                                case 13:
                                    gg.edit.keyDown(e, false);
                                    break;
    
    
                                case 91:
                                    gg.edit.keyDown(e, false);
                                    break;
    
                                default:
                                    gg.edit.keyDown(e, true);
                            }
                            e.preventDefault();
    
                        }
                    }
                   

                }, 5);
                keyDownDebounce(e);
            });

            gg.$el.on('click', '.gg-grid-action' ,function(event){
                var element = $(this)
                //action_key = element.closest('.gg-dropMenu').attr('colObj'),
                //input = element.parent().find('.gg-dropMenu-input').val(),
                //action = element.attr('action');
                //gg.dropdownAction(action,element,input,col_id)

                //action_key = element.closest('.gg-dropMenu').attr('action_key'),
                var action_key = element.attr('action_key');
                event.stopPropagation();
                event.preventDefault();
                console.log(action_key);
                console.log(element);
            });
            gg.$el.on('keydown', '.gg-dropMenu-input' ,function(event){
                if(event.which == 13) {
                    var element = $(this),
                        action = element.parent().find('.gg-menu-action').attr('action'),
                        col_id = element.closest('.gg-dropMenu').attr('colObj'),
                        input = element.parent().find('.gg-dropMenu-input').val();
                    gg.dropdownAction(action,element,input,col_id)
                }
            });


            gg.$el.on('click', '.gg-menu-action' ,function(e){
                var element = $(this),
                    col_id = element.closest('.gg-dropMenu').attr('colObj'),
                    input = element.parent().find('.gg-dropMenu-input').val(),
                    action = element.attr('action');
                gg.dropdownAction(action,element,input,col_id)

            });

            gg.$el.on('click', '.gg-context-action' ,function(e){

                var element = $(this),
                    col_id = element.attr('colobj'),
                    input = element.attr('value'),
                    action = element.attr('action');
                gg.dropdownAction(action,element,input,col_id);
            });


            gg.$el.on('mouseover', '.gg-hot-spot' ,function(event){
                var element = $(event.currentTarget);
                gg.messageService.hoverShow(element.attr('obj_id'),element.offset().left,element.offset().top,element.width(),element.height());
            });

            gg.$el.on('mouseout', '.gg-hot-spot' ,function(e){
                //gg.dropdownAction(e);
                var openElement = $('.gg-message-container', gg.$el);
                if(!openElement.is(':hover')){
                    gg.messageService.hoverHide();
                }

            });

            $('.gg-message-container' ,gg.$el).mouseleave(function(e) {
                gg.messageService.hoverHide();
            });
            gg.$el.on('click', '.gg-attachment-action' ,function(event){
                var parent_key = $(this).attr('parent_key'),
                parent_type = $(this).attr('parent_type'),
                action = $(this).attr('action'),
                file_name=$(this).attr('file_name');
                console.log("++++++---+++++",parent_key,parent_type,action,gg,base);
                if(action=="download"){
                    gg.messageService.downLoadAttachment(file_name,parent_key);
                   /*  var secondaryWindow = window.open(base.base_url +'/downloadCommentAttachement?fileName=' +file_name+"&commentKey="+parent_key);
                    if (secondaryWindow == null) {
                        return;
                    }
                    // Check if there was a problem downloading the attachment.
                    secondaryWindow.onload = function () {
                        if (secondaryWindow.document.body.innerHTML.length > 0) {
                                $scope.$broadcast('gtw.admin.client.downloadLogo.failed');
                                secondaryWindow.close();
                        }
                    }; */
                }else if( action  === 'edit'){
                   gg.messageService.editAttachments(parent_key,parent_type);

                }else if(action === 'preview'){
                    gg.messageService.previewAttachments(parent_key,file_name);

                }else if(action === 'deleteAttachment')
                    gg.messageService.deleteAttachmentByname(file_name,parent_key,parent_type);
            });
            gg.$el.on('click', '.gg-message-action' ,function(event){
                var comment_key = $(this).attr('comment_key'),
                    action = $(this).attr('action'),
                    task_status = $(this).attr('task_status'),
                    task_action_item = $(this).attr('task_action_item');

                //console.log("comment_key: " , comment_key);
                //console.log("action: " , action);


                if( action  === 'edit'){
                    gg.messageService.editMessage(comment_key);
                }else if(action  === 'resolve' ){
                    gg.messageService.resolve(comment_key,task_status,task_action_item);
                }else if(action  === 'showForm' ){
                    gg.$el.find('.reply-form-update[comment_key='+comment_key+']').show();
                    gg.$el.find('.gg-reply-comments[comment_key='+comment_key+']').hide();
                }else if(action  === 'cancelEdit' ){
                    gg.$el.find('.reply-form-update[comment_key='+comment_key+']').hide();
                    var val = gg.$el.find('.gg-reply-comments[comment_key='+comment_key+']').text();
                    gg.$el.find('.gg-reply-update-text-area[comment_key='+comment_key+']').val(val);
                    gg.$el.find('.gg-reply-comments[comment_key='+comment_key+']').show();
                }else if(action  === 'deleteReply' ){
                    gg.messageService.deleteReply(comment_key);
                }else if(action  === 'editReply') {
                    var text = gg.$el.find('.gg-reply-update-text-area[comment_key='+comment_key+']').val();
                    gg.messageService.editReply(comment_key,text);
                }else if(action  === 'copyLink') {
                    //gg.messageService.editReply(comment_key);
                    //console.log("copy the link")
                }

            });


            gg.$el.on('click', '.gg-header' ,function(event){
                gg.messageService.destroyMessages()
            });


            gg.$el.on('blur', '.gg-input-text' ,function(event){

                gg.elements.editValue.hide();
                gg.elements.editValue.html("");
                gg.edit.setValue( $(this).val() );
                event.stopPropagation();

            });



            gg.$el.on('click', '.div-col' ,function(event){
                var element = event.currentTarget;
                if( $(element).hasClass('gg-hot-spot')){
                    var element = $(event.currentTarget);
                    gg.messageService.renderMessages(element.attr('obj_id'),element.offset().left,element.offset().top,element.width(),element.height());
                    gg.messageService.toggleOpen();
                }else{
                    gg.messageService.destroyMessages();
                }



                /*
                if(  gg.selected.colData.edit_type === "dropdown"){
                    var element = event.currentTarget;
                    gg.edit.editCell(element,false);
                }
                */


            });




            gg.$el.on('click', '.gg-reply-btn' ,function(event){
                $(".gg-message-scroll").scrollTop($(".gg-message-scroll")[0].scrollHeight);
                gg.$el.find('.gg-reply-text-area').focus();

            });


            gg.$el.on('click', '.gg-add-reply' ,function(event){
                gg.messageService.addReply(  gg.$el.find('.gg-reply-text-area').val());
            });



            gg.$el.on('click', '.gg-cancel-reply' ,function(event){
                gg.$el.find('.gg-reply-text-area').val('');
            });


            gg.$el.on('contextmenu' ,function(event){

                if(gg.preventDefaultContext){
                    event.stopPropagation();
                    event.preventDefault();
                    return false;
                }



            });

            gg.$el.on('click', '.gg-alert-close' ,function(event){
                gg.removeAlert($(this));
            });


            gg.$el.on('click', '.tools-action' ,function(event){
                gg.toolsAction($(this));
            });



            gg.$el.on('click', '.gg-clear-item' ,function(event){
                //gg.actionsService.clearSelectedTemp(); /*  commented 3/4/2020 if we need to clear the individual item, we dont want to clear the grid actions */
                gg.clearFilter($(this).attr('action'),$(this).attr('map'));
            });


            gg.$el.on('click', '.gg-clear-all' ,function(event){
                gg.actionsService.clearSelectedTemp();
                gg.clearAll();
            });


            gg.$el.on('keyup', '.gg-global-search' ,function(event){
                event.stopPropagation();
                gg.globalSearch( $(this).val() );
            })






            gg.$el.on('keyup', '.gg-column-search' ,function(event){
                if(event.which == 13) {
                    var element = $(event.currentTarget);
                    var col_id = element.closest('.gg-dropMenu').attr('colObj');
                    gg.columnSearch(col_id, $(this).val());
                }
            })


            gg.$el.on('click', '.close-form' ,function(event){
                ////////////////////////////////console.log("test------", $(this).val());
                gg.closeForm();
            })


            gg.$el.on('click', '.grid-action-btn' ,function(event){
                ////////////////////////////////console.log("test------", $(this).val());
                var rowobj = $(this).parent().attr('rowobj');
                var colobj =  $(this).parent().attr('colobj');
                var curDataRow = _.find(gg.data.safeCopyArr, {'object_id':rowobj});
                var curDataCol = _.find(gg.data.headerArray, {'map':colobj});
                var data = {
                    colData:curDataCol,
                    rowData:curDataRow
                }
                console.log("dafadfasdfadsf**********************");
                gg.api.callBack( curDataCol.call_back,data,'form');
            })

            gg.$el.on('click', '.grid-download-btn' ,function(event){
                var rowobj = $(this).parent().attr('rowobj');
                  var rowobj = $(this).parent().attr('rowobj');
                var colobj =  $(this).parent().attr('colobj');
                var curDataRow = _.find(gg.data.safeCopyArr, {'object_id':rowobj});
                var curDataCol = _.find(gg.data.headerArray, {'map':colobj});
               /// downloadAllDoc(curDataRow.DOC_PARENT_KEY)
               var id = curDataRow.DOC_PARENT_KEY != null ? curDataRow.DOC_PARENT_KEY : curDataRow.OBJECT_ID;
               gg.messageService.downloadAllDoc(id);
            })

            gg.$el.on('click', '.grid-download-file-btn' ,function(event){
                var rowobj = $(this).parent().attr('rowobj');
                var colobj =  $(this).parent().attr('colobj');
                var curDataRow = _.find(gg.data.safeCopyArr, {'object_id':rowobj});
                var curDataCol = _.find(gg.data.headerArray, {'map':colobj});
               gg.messageService.downloadDoc(curDataRow.FILE_NAME);
            })


            gg.$el.on('click', '.grid-drill-down-btn' ,function(event){
                var data = {
                    colData: gg.selected.colData,
                    rowData: gg.selected.rowData,
                    value:   gg.selected.value
                }

                gg.api.callBack( 'drillDown',data,'drillDown');
            })


            gg.$el.on('click', '.grid-link-btn' ,function(event){
                var data = {
                    colData: gg.selected.colData,
                    rowData: gg.selected.rowData,
                    value:   gg.selected.value
                }
                gg.api.callBack( 'link',data,'link');
            })


            gg.$el.on('click', '.grid-drilldown-btn' ,function(event){
                var data = {
                    colData: gg.selected.colData,
                    rowData: gg.selected.rowData,
                    value:   gg.selected.value
                }

                gg.api.callBack( 'drillDown',data,'drillDown');
            })


            gg.$el.on('keydown', '.gg-input-text' ,function(event){
                event.stopPropagation();
            });

            gg.$el.on('keydown', '.gg-dropMenu-input' ,function(event){
                event.stopPropagation();
            });


            gg.$el.on('keydown', '.div-col' ,function(event){
               console.log("keydown test test tets");
               event.stopPropagation();

            });


            gg.$el.on('dblclick', '.div-col' ,function(e){
                var element = e.currentTarget;
                gg.edit.editCell(element,false);
            });


            gg.$el.on('click', '.sortHeader' ,function(e){
                gg.sort(e);
                gg.focus.gridBody.focus();
            });


            $('.btn-scroll-right' ,gg.$el).mousedown(function(e) {
                clearInterval(interval);
                $(this).addClass('gg-btn-mouse-down');
                gg.scrollRight(e);
                interval = setInterval(function(e){
                    gg.scrollRight(e);
                },100)
                var element =  $(this);
                $(window).bind('mouseup',{element:element}, unBindVButtonScroll);
            })


            $('.btn-scroll-left' ,gg.$el).mousedown(function(e) {
                clearInterval(interval);
                $(this).addClass('gg-btn-mouse-down');
                gg.scrollLeft(e);
                interval = setInterval(function(e){
                    gg.scrollLeft(e);
                },100)
                var element =  $(this);
                $(window).bind('mouseup',{element:element}, unBindVButtonScroll);
            })


            $('.btn-scroll-down' ,gg.$el).mousedown(function(e) {
                clearInterval(interval);
                $(this).addClass('gg-btn-mouse-down');
                gg.scrollDown(e);
                interval = setInterval(function(e){
                    gg.scrollDown(e);
                },100)
                var element =  $(this);
                $(window).bind('mouseup',{element:element}, unBindVButtonScroll);
            })

            $('.btn-scroll-up' ,gg.$el).mousedown(function(e) {
                clearInterval(interval);
                $(this).addClass('gg-btn-mouse-down');
                gg.scrollUp(e);
                interval = setInterval(function(e){
                    gg.scrollUp(e);
                },100)
                var element =  $(this);
                $(window).bind('mouseup',{element:element}, unBindVButtonScroll);
            })


            function unBindVButtonScroll(event){
                clearInterval(interval);
                event.data.element.removeClass('gg-btn-mouse-down');
                $(window).unbind('mouseup', unBindVButtonScroll);
            }

            /////////////// TOREMOVE TOREMOVE ------------ ///////////////////////////////////////////////////////////
            /////////////// START DRAG MODAL /////////////////////////////////////////////////////////////////////////

            $('.gg-modal-filter .gg-modal-header' ,gg.$el).mousedown(function(e) {
                var element =  $(this);
                $(window).bind('mousemove',{element:element}, dragFilterModalHandler);
                $(window).bind('mouseup',{element:element}, unBindFilterModalDrag);
            })

            function dragFilterModalHandler(event){
                var $element =  $('.gg-modal-filter .gg-modal-header' ,gg.$el)
                gg.FilterModalService.dragModal( event.pageY - 15, event.pageX - $element.width() / 2)
            }

            function unBindFilterModalDrag(event){
                // event.data.element.removeClass('gg-btn-mouse-down');
                $(window).unbind('mousemove', dragFilterModalHandler);
                $(window).unbind('mouseup', unBindFilterModalDrag);
            }

            /////////////// END DRAG MODAL ///////////////////////////////////////////////////////////////////////////


            /////////////// START DRAG MODAL /////////////////////////////////////////////////////////////////////////

            $('.gg-modal-container .gg-modal-header' ,gg.$el).mousedown(function(e) {
                var element =  $(this);
                $(window).bind('mousemove',{element:element}, dragModalHandler);
                $(window).bind('mouseup',{element:element}, unBindModalDrag);
            })

            function dragModalHandler(event){
                var $element =  $('.gg-modal-container .gg-modal-header' ,gg.$el)
                gg.ModalService.dragModal( event.pageY - 15, event.pageX - $element.width() / 2)
            }

            function unBindModalDrag(event){
                // event.data.element.removeClass('gg-btn-mouse-down');
                $(window).unbind('mousemove', dragModalHandler);
                $(window).unbind('mouseup', unBindModalDrag);
            }

            /////////////// END DRAG MODAL ///////////////////////////////////////////////////////////////////////////



            /////////////// START V-SCROLL //////////////////////////////////////////////////////

            gg.$el.on('mousedown', '.vScroll-dragger' ,function(event){
                $('body').css( 'cursor', 'pointer' );
                var element = event.target;
                $(window).bind('mouseup',{element:element}, unBindVDragger);
                $(window).bind('mousemove',{element:element}, gg.vScrollDrag);
                event.originalEvent.preventDefault();
            });


            gg.$el.bind('mousewheel DOMMouseScroll', function(event){

                if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
                    gg.scrollUp(event);
                }
                else {
                    gg.scrollDown(event);
                }

            });

            function unBindVDragger(){
                $('body').css( 'cursor', 'default' );
                $(window).unbind('mousemove', gg.vScrollDrag);
                $(window).unbind('mouseup', unBindVDragger);
            }


            /////////////// END V-SCROLL //////////////////////////////////////////////////////

            /////////////// START V-SCROLL //////////////////////////////////////////////////////

            gg.$el.on('mousedown', '.hScroll-dragger' ,function(event){

                event.stopPropagation();
                event.preventDefault();
                $('body').css( 'cursor', 'pointer' );
                var element = event.target;
                $(window).bind('mouseup',{element:element}, unBindHDragger);
                $(window).bind('mousemove',{element:element}, gg.hScrollDrag);
                event.originalEvent.preventDefault();
                return false

            });

            function unBindHDragger(){
                $('body').css( 'cursor', 'default' );
                $(window).unbind('mousemove', gg.hScrollDrag);
                $(window).unbind('mouseup', unBindHDragger);
                gg.onHorzScrollOff();
            }


            ////////////////// END H-SCROLL //////////////////////////////////////////////////////////





            $(window).resize(function(e) {
                gg.resize(e);
            });




            // ++ START ++ //////////////////////////////// COL RESIZE /////////////////////////////
            gg.$el.on('mousedown', '.col-resize-handle' ,function(event){
                event.stopPropagation();
                var element = event.target;
                $(window).bind('mouseup',{element:element}, unBindDragColHandler);
                $(window).bind('mousemove',{element:element}, gg.dragColHandler);
                event.preventDefault();
            });

            function unBindDragColHandler(){
                $(window).unbind('mousemove', gg.dragColHandler);
                $(window).unbind('mouseup', unBindDragColHandler);
                gg.dragColHandlerEnd();
                event.stopPropagation();
            }

            // ++ END ++ ///////////////////////////////// COL RESIZE /////////////////////////////

            // ++ START ++ //////////////////////////////// ROW RESIZE /////////////////////////////
            gg.$el.on('mousedown', '.row-resize-handle' ,function(event){
                event.stopPropagation();
                var element = event.target;
                $(window).bind('mouseup',{element:element}, unBindDragRowHandler);
                $(window).bind('mousemove',{element:element}, gg.dragRowHandler);
                event.preventDefault();
            });

            function unBindDragRowHandler(){
                $(window).unbind('mousemove', gg.dragRowHandler);
                $(window).unbind('mouseup', unBindDragRowHandler);
                gg.dragRowHandlerEnd();
                event.stopPropagation();
            }

            // ++ END ++ ///////////////////////////////// ROW RESIZE /////////////////////////////

            // ++ START ++ //////////////////////////////// COL REORDER /////////////////////////////

            gg.$el.on('mousedown', '.dragOrder' ,function(event){
                if (event.which !== 3) { //MAKE SURE NOT RIGHT CLICK
                    ////////console.log("this " , this);
                    event.stopPropagation();

                    console.log("Grid info ----------------------------" , gg);
                    var element = $(this);

                    //DO NOT LET USER CHANGE ORDER ON IMPORT
                    if( gg.options.gridType && gg.options.gridType !== 'import'){
                        $(window).bind('mouseup',{element:element}, unBindColReorderHandler);
                        $(window).bind('mousemove',{element:element}, gg.dragReorderColHandler);
                    }



                    var map = element.attr("map");

                    //////console.log(" map : " ,map);

                    if (event.ctrlKey) {
                        gg.renderSelected.setColGroup(map,true);
                    } else if(event.shiftKey){
                        //WILL BE SHIFT SELECT
                    }else{
                        // gg.selectCol($(element),false);
                        gg.renderSelected.setColGroup(map,false);
                    }

                    event.preventDefault();



                }
            });


            function unBindColReorderHandler(){
                $(window).unbind('mousemove', gg.dragReorderColHandler);
                $(window).unbind('mouseup', unBindColReorderHandler);
                gg.dragReorderColHandlerEnd();
                event.stopPropagation();
            }

            // ++ END ++ ///////////////////////////////// COL REORDER /////////////////////////////

            // ++ START ++ //////////////////////////////// COL FIXED  /////////////////////////////


            gg.$el.on('mousedown', '.fixed-col-handler' ,function(event){

                gg.resetHScroll();

                if (event.which !== 3) { //MAKE SURE NOT RIGHT CLICK
                    event.stopPropagation();
                    var element = event.target;
                    $(window).bind('mouseup',{element:element}, unBindColFixedHandler);
                    $(window).bind('mousemove',{element:element}, gg.dragFixedColHandler);
                    event.preventDefault();
                }

            });

            function unBindColFixedHandler(){
                $(window).unbind('mousemove', gg.dragFixedColHandler);
                $(window).unbind('mouseup', unBindColFixedHandler);
                gg.dragFixedColHandlerEnd();
                event.stopPropagation();
            }

            // ++ END ++ //////////////////////////////// COL FIXED  /////////////////////////////
            gg.$el.on('mousedown', '.stopEvent' ,function(event){
                event.stopPropagation();
            });


            ///// JQUERY MESSAGE FUNCTIONS ///////////////////////////////////////////////////////////////////////

            gg.$el.on('click', '.gg-tab' ,function(event){
                $('.gg-tab' ,gg.$el).removeClass('active');
                $('.gg-tab-body' ,gg.$el).css('display','none');
                $(this).addClass('active');
                var tabShow = $(this)[0].getAttribute('show');//$(this).attr('show');
                $(".gg-tab-body[gg-tab='"+tabShow+"']" ,gg.$el).css('display','block');
            })




            gg.$el.on('click', '.gg-message' ,function(event){
                var comment_key = $(this).attr('comment_key');
                var element = $(this).find('.gg-message-arrow').find('i');
                element.removeClass('fa-chevron-right');
                element.addClass('fa-spinner fa-pulse ');
                gg.messageService.loadMessage(comment_key);
            });


            gg.$el.on('click', '.gg-attachement' ,function(event){
                var parent_key = $(this).attr('parent_key');
                var parent_type = $(this).attr('parent_type');
                var element = $(this).find('.gg-message-arrow').find('i');
                element.removeClass('fa-chevron-right');
                element.addClass('fa-spinner fa-pulse ');
                gg.messageService.loadAttachment(parent_key,parent_type);
            });

            gg.$el.on('click', '.gg-message-top' ,function(event){
                gg.messageService.back();
            });




            $(document).on("resizeGrid", function (e) {
                setTimeout(function(){
                    gg.resize(e);
                }, 500);
            });



            gg.$el.bind( "ggUpdateData", function( e , data ) {
                gg.api.updateData(data);
            });

            gg.$el.bind( "ggLoadData", function( e , data ) {
                gg.api.loadData(data);
            });



            //TODO: MAKE IT SO ALL OTHER gg-drop-down-menu WHEN CLICKED

            gg.$el.on('click', '.gg-toggle-menu' ,function(event){

                var position = $(this).attr( "position" );
                var $dropMenu = $(this).find('.gg-drop-down-menu');

                if( $dropMenu.hasClass('open') ){
                    $dropMenu.removeClass('open')
                }else{
                    $dropMenu.addClass('open');
                }

                if( position == 'left' ){
                    var width = $dropMenu.width();
                    $dropMenu.css('left','-'+width+'px');
                }

                event.stopPropagation();
            });



        });
    };



//////////////////////////////////// ADD TO LODASH //////////////////////////////////////////////////////

//Add to LODASH Library
// findArrayByPartialValue
// Searched an ARRAY (String or Number) for partial values
_.mixin({
    'findArrayByPartialValue': function(collection, searchValue) {
        return _.filter(collection, function(object) {
                var n = ((typeof object === "undefined" || object === null) ? "" : object.toString());
                if (_.includes(n.toLowerCase(), searchValue.toLowerCase())) {
                    return true
                }
                return false;
        });
    }
});


// findByPartialValue
// Searched an Collection (String or Number) for partial values
    _.mixin({
        'findByPartialValue': function(collection, value) {
            return _.filter(collection, function(object) {
                for(var key in object){
                    if( object[key] != '' && object[key] != null) {
                        var n = object[key].toString();
                        if (_.includes(n.toLowerCase(), value.toLowerCase())) {
                            return true
                        }
                    }
                };
                return false;
            });
        }
    });



    _.mixin({
        'findByKeyPartialValue': function(collection, value, predicate) {
            return _.filter(collection, function(object) {
                var n = object[predicate];
                if( n != undefined  ){
                    n = n.toString();
                    if( _.includes(n.toLowerCase(), value.toLowerCase()) ){
                        return true
                    }
                    return false;
                }
                return false;
            })
        }
    });


    _.mixin({
        'findNotByKeyPartialValue': function(collection, value, predicate) {

            return _.filter(collection, function(object) {
                var n = object[predicate];
                if( n != undefined  ){
                    n = n.toString();
                    if( !_.includes(n.toLowerCase(), value.toLowerCase()) ){
                        return true
                    }
                    return false;
                }
                return false;
            })
        }
    });






//Add to LODASH Library
    _.mixin({
        'findNumByProperty': function(collection,property,value,operator) {
            return _.filter(collection, function(object) {

                // var num = parseInt(object[property]);
                var num = parseFloat(object[property]);

                if( operator == '>=' && num >= value ){
                    return true;
                }
                if( operator == '<=' && num <= value ){
                    return true;
                }
                if( operator == '=' && num == value ){
                    return true;
                }
                if( operator == '!=' && num != value ){
                    return true;
                }

                return false;
            });
        }
    });



    /*
     * Provides a convenience extension to _.isEmpty which allows for
     * determining an object as being empty based on either the default
     * implementation or by evaluating each property to undefined, in
     * which case the object is considered empty.
     */
    _.mixin( function() {
        // reference the original implementation
        var _isEmpty = _.isEmpty;
        return {
            // If defined is true, and value is an object, object is considered
            // to be empty if all properties are undefined, otherwise the default
            // implementation is invoked.
            isEmpty: function(value, defined) {
                if (defined && _.isObject(value)) {
                    return !_.any( value, function(value, key) {
                        return value !== undefined;
                    });
                }
                return _isEmpty(value);
            }
        }
    }());




    _.mixin({
        'uniqueColVal': function(_arr,_map,_data_type) {
            var data = _.map(_arr, _map);
            var uniq =   _.uniq(data);
            return uniq;
        }
    })


    _.mixin({
        'findByValues': function(collection, property, values) {
            return _.filter(collection, function(item) {
                return _.includes(values, item[property]);
            });
        }
    });

    _.mixin({
        'findByValuesNot': function(collection, property, values) {
            return _.filter(collection, function(item) {
                 return !_.includes(values, item[property]);
            });
        }
    });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////START:  handlebarsjs helpers /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    Handlebars.registerHelper('ifCond', function(condition,equles, val1, val2) {
        if(condition === equles ){
            return val1;
        }else{
            return val2;
        }
    });




    Handlebars.registerHelper('splitName', function(name) {
        var t = name.split(" ");
        return t.length > 1 ?t[0].slice(0, 1)  + t[1].slice(0, 1):t[0].slice(0, 1);
    });


    Handlebars.registerHelper('showEdit', function( sso_id ,userAccessLevel ,curUser ,notifyList ) {

        function checkList(_curUser){
            var found = _.find(notifyList, { 'sso_id': _curUser })
            // console.log("notifyList"  , notifyList);
            // console.log("_curUser"  , _curUser);
            // console.log("found"  , found);
            if( found !== undefined ){
                return true;
            }else{
                return false;
            }
        }

        if(sso_id === curUser) {
            return true;
        }else if(userAccessLevel === 5){
            return true;
        }else if(checkList(curUser)){
            return true;
        }

        return false;
    });


    Handlebars.registerHelper('trimString', function(passedString, startstring, endstring) {
        var theString = passedString.substring( startstring, endstring );
        if(passedString.length > endstring){
            theString += " ...";
        }
        return new Handlebars.SafeString(theString)
    });



    Handlebars.registerHelper('timeAgo', function(_date) {
        var date = new Date(_date);
        return moment( date).fromNow();
    });



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////END:  handlebarsjs helpers /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////START:  handlebarsjs templates //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var detailAttaHBTemplate = '<div class="gg-message-detail">'+
'        <div class="gg-message-top">' +
            '<li class="flex-item1"><i class="fa fa-chevron-left"></i></li>'+
            '<li class="flex-item2">{{ parent_type }}</li>'+
            '<li class="flex-item3"><img class="circular--square" src="assets/img/default-user-icon.png"></li>'+
            '<li class="flex-item4">{{timeAgo date}}</li> '+
            '<li class="flex-item1"></li> '+
'       </div>'+
'</div>';


var detailMessageHBTemplate = '<div class="gg-message-detail {{type}}">' +
'    <div class="gg-message-top">' +
'        <i class="fa fa-chevron-left" style="cursor: pointer"></i>' +
'        <span style="padding-left: 10px;font-family:ge-inspira;">{{ display_name }}</span>' +
'        <div style="display:inline-block; float:right;">'+
'           <span>{{timeAgo date}}</span>' +
'           <i class="fa fa-times" style="margin-left: 10px; cursor: pointer;"></i>'+
'        </div>'+
'    </div>' +
'    <div class="gg-message-header">' +
'        <div class="flex-wrapper">' +
'            <div class="flex-1">' +
        '   {{#if avatar_path}} '+
        '   <img alt="User1" height="50" width="50" cx="30" cy="30" r="19" x="45%" y="45%" style="float:left;margin-top: 7px;margin-right: 12px;" title="{{display_name}}"'+
            'src="{{{avatar_path}}}">'+
        '   {{else}}' +
'                <svg height="65" width="60" style="float: left">' +
'                    <circle cx="30" cy="30" r="25" stroke-width="3" fill="#cd5763"></circle>' +
'                    <text fill="white" font-size="24" font-family="Arial" x="50%" y="50%" alignment-baseline="middle" text-anchor="middle">{{{splitName display_name}}}</text>' +
'                </svg>{{/if}}' +
'            </div>' +
'            <div class="flex-2">' +
'                <ul>' +
'                    <li>Type:  Action Item</li>' +
'                    <li>' +
'                        View Type:  ' +
'                        <span class="circle-tag" style="{{ifCond is_public 1 "background-color: #33cc33" "background-color: #ff0000"}}"></span> {{ifCond is_public 1 "Public" "Private"}}' +
'                    </li>' +
'                    <li>Cell Data:  ({{value}})</li>' +
'                    <li>Target Date:  {{timeAgo date}}</li>' +
'                </ul>' +
'            </div>' +
'            <div class="flex-3">' +
'                <ul class="ul-list-style">' +
'                    <li class="li-list-style">' +
'                        <button type="button" class="btn btn-xs btn-default gg-message-action" style="height: 26px" task_status="{{task_status}}" task_action_item = "{{task_action_item}}" comment_key="{{comment_key}}" action="resolve">Resolve</button>' +
'                    </li>' +
'                    <li class="li-list-style">'+
'                       <div class="gg-drop-down">' +
'                           <div class="edit-btn gg-toggle-menu" position="left" style="font-size: 15px;"><i class="fa fa-ellipsis-v"></i>' +
'                               <ul class="gg-drop-down-menu">' +
'                                   <li class="gg-message-action" action="edit" comment_key="{{comment_key}}">Edit</li>' +
'                               </ul>' +
'                           </div>' +
'                       </div>' +
'                     </li>' +
'                </ul>' +
'            </div>' +
'        </div>' +
'        {{#if (showEdit sso_id userAccessLevel curUser notifyList) }}' +
'            <div class="right" style="padding-top:4px;"></div>' +
'        {{/if}}' +
'    </div>' +
'    <div class="clearfix"></div>' +
'' +
'    <div class="tab-bar">' +
'        <ul class="row" style="margin:0px">' +
'            <li show="comments" class="gg-tab col-md-4 active">Comments</li>' +
'            <li show="notify" class="gg-tab col-md-4">User Assign</li>' +
'            <li show="attachments" class="gg-tab col-md-4">Documents</li>' +
'        </ul>' +
'    </div>' +
'    <div class="gg-message-tabs" style="background: transparent; height: 300px;">' +
'        <div class="gg-tab-body" style="display:block" gg-tab="comments">' +
'           <div class="body" style="display:block">'+
'               {{message}}' +
'           </div>'+
'            <div class="gg-message-reply gg-messages-scroll">' +
'                {{#each replyList}}' +
'                    <div class="gg-reply clearfix" comment_key="{{comment_key}}" parent_key="{{parent_key}}">' +
                '   {{#if avatar_path}} '+
                '   <img alt="User1" height="40" width="40" cx="20" cy="20" r="19" x="45%" y="45%" style="float:left;margin-top: 7px;margin-right: 12px;" title="{{display_name}}"'+
                    'src="{{{avatar_path}}}">'+
                '   {{else}}' +
'                        <svg height="60" width="55" style="float: left">' +
'                            <circle cx="25" cy="25" r="20" stroke-width="3" fill="#1ca5cf"></circle>' +
'                            <text fill="white" font-size="20" font-family="Arial" x="45%" y="45%" alignment-baseline="middle" text-anchor="middle">{{{splitName display_name}}}</text>' +
'                        </svg>{{/if}}' +
'                        <div class="details-col">' +
'                            <h2>{{display_name}}<span style="font-size:12px; float: right;">{{timeAgo date}}</span></h2>' +
'                        </div>' +
'                        <div class="edit-col">' +
'                            <div class="gg-drop-down">' +
'                                <div class="edit-btn gg-toggle-menu" position="left"><i class="fa fa-ellipsis-v"></i>' +
'                                    <ul class="gg-drop-down-menu">' +
'                                        <li class="gg-message-action" action="showForm" comment_key="{{comment_key}}">Edit</li>' +
'                                        <li class="gg-message-action" action="deleteReply" comment_key="{{comment_key}}">Delete' +
'                                        </li>' +
'                                    </ul>' +
'                                </div>' +
'                            </div>' +
'                        </div>' +
'                        <div class="reply-form-update" comment_key="{{comment_key}}" parent_key="{{parent_key}}">' +
'                            <textarea class="gg-reply-update-text-area" comment_key="{{comment_key}}" name="update_comment">{{message}}</textarea>' +
'                            <div class="btn btn-xs btn-warning pull-right gg-message-action" action="cancelEdit" comment_key="{{comment_key}}" style="margin-top: 35px;">Cancel</div>' +
'                            <div class="btn btn-xs btn-info pull-right  gg-message-action" action="editReply" comment_key="{{comment_key}}" style="margin-right:3px; margin-top: 35px;">Save</div>' +
'                        </div>' +
'                        <div class="gg-reply-comments" comment_key="{{comment_key}}">' +
'                            <span>{{message}}</span>' +
'                        </div>' +
'                    </div>' +
'                {{/each}}' +
'            </div>' +
'            <div class="gg-reply-form">' +
'                <textarea class="gg-reply-text-area" placeholder="add comment" name="new_comment"></textarea>' +
'                <i class="fa fa-paperclip attach" aria-hidden="true"></i>'+
'                <div class="btn btn-xs btn-info pull-right gg-add-reply">' +
'                    <i class="fa fa-arrow-circle-up" style="font-size: 20px"></i>' +
'                </div>' +
'            </div>' +
'        </div>' +
'        <div class="gg-tab-body" gg-tab="attachments">' +
'           <div class="gg-attachment gg-messages-scroll">'+
'            <b>Attachments:</b><br />' +
'            {{#each attachmentList}}' +
'                <li><i class="fa fa-file"></i> {{file_name}}</li>' +
'            {{/each}}' +
'           </div>'+
'        </div>' +
'        <div class="gg-tab-body" gg-tab="notify" style="padding: 5px;">' +
'           <div class="user-assigned">'+
'               <label>Assigned:</label><br>'+
'               <span><i class="fa fa-user user-icon"></i>{{ display_name }}</span>'+
'           </div>'+
'           <div class="user-notify">'+
'               <b>Notify:</b><br />' +
'               {{#each notifyList}}' +
'                   <li><i class="fa fa-user user-icon"></i> {{sso_id}} - {{display_name}} </li>' +
'               {{/each}}' +
'           </div>'+
'        </div>' +
'    </div>' +
'</div>';

var listMessageHBTemplate =
    '  <div class="gg-messages-scroll">'+
    '  {{#each comments}}'+
    '  <div class="gg-message clearfix" comment_key="{{comment_key}}">'+
    '  <div class="gg-message-status {{type}}"></div>'+
    '   {{#if avatar_path}} '+
    '   <img alt="User1" height="40" width="40" cx="20" cy="20" r="19" x="45%" y="45%" style="float:left;margin-top: 7px;margin-right: 12px;" title="{{display_name}}"'+
        'src="{{{avatar_path}}}">'+
    '   {{else}}' +
    '  <svg height="60" width="55" style="float: left"><circle cx="25" cy="25" r="20" stroke-width="3" fill="#cd5763"></circle>'+
        '<text fill="white" font-size="20" font-family="Arial" x="45%" y="45%" alignment-baseline="middle" text-anchor="middle"> {{{splitName display_name}}}</text></svg>{{/if}}'+
    '  <div class="gg-message-details">'+
    '  <div class="name">{{display_name}}<div class="date"><b>{{timeAgo date}}</b></div></div>'+
    '  <div class="subject"><span>{{trimString message 0 80}}</span></div>'+
    '  </div>'+
    '  <div class="gg-message-arrow" >' +
        '<i class="fa fa-chevron-right"></i>' +
      '</div>'+
    '  </div>'+
    '  {{/each}}'+
    '  </div>';

 var listAttachmentHBTemplate =

    '  <div class="gg-messages-scroll">'+
   '  {{#each attachments}}'+
    '  <div class="gg-message" parent_key="{{parent_key}}"parent_type="{{parent_type}}">'+
    '  <div class="gg-message-details">'+
    '  <div class="name">{{file_name}}</div>'+
         '<br><br>'+
        ' <i class="fa fa-cloud-download gg-attachment-action" style="margin-right:5px" action="download" parent_key="{{parent_key}}" file_name="{{file_name}}" parent_type={{parent_type}}></i>'+
         '<i  class="fa fa-eye gg-attachment-action" action="preview" parent_key="{{parent_key}}" file_name="{{file_name}}"></i>'+
    '  </div>'+
    '  <div class="gg-attachment-ellipse" >' +
        '      <div class="gg-drop-down">' +
        '       <div class="edit-btn gg-toggle-menu" position="left"><i class="fa fa-ellipsis-v"></i>' +
            '       <ul class="gg-drop-down-menu">' +
          //'             <li class="gg-attachment-action" action="download" parent_key="{{parent_key}}" file_name="{{file_name}}">Download</li>' +
            '           <li class="gg-attachment-action" action="edit" parent_key="{{parent_key}}" parent_type="{{parent_type}}">Edit</li>' +
            '           <li class="gg-attachment-action" action="deleteAttachment" parent_key="{{parent_key}}" parent_type="{{parent_type}}" file_name="{{file_name}}">Delete</li>' +
            '       </ul> '+
            '    </div>'+
        '       </div>'+
         '</div>'+
    '  </div>'+
    '  {{/each}}'+
    '  </div>';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////END:  handlebarsjs templates /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
